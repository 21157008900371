document.addEventListener("turbolinks:load", function() {
    if($("body").data("controller") == "meet_greets") {
        let selected_element_id = null

        let table_meet_greets = $('#table-meet_greets').DataTable({
            "processing": true,
            "language": {
                processing: '<i class="fa fa-spinner fa-spin fa-3x fa-fw"></i><span class="sr-only">Loading...</span> '
            },
            scrollX:        true,
            paging:         true,
            "serverSide": true,
            "ajax": {
                "url": $('#table-meet_greets').data('source'),
                "data": function(e){
                }
            },
            "pagingType": "full_numbers",
            "columns": [
                {"data": "id"},
                {"data": "name"},
                {"data": "dates", 
                "render": function(data, display, record) {
                    return record.dates.split(',').map(date => `<span class='label badge-default'>${date}</span>`).join(' ')
                }},
                {"data": "confirmed", 
                "render": function(data, display, record) {
                    if (record.canceled == "true")
                        return "<span class='label badge-danger'>canceled</span>"
                    else
                        return data == 'true' ? "<span class='label badge-info'>confirmed</span>" : "<span class='label label-warning '>pending</span>"
                }},
                {"data": "creator"},
                {"data": "created_at",
                "render": function(data, display, record) {
                    return moment(record.created_at).format("DD-MM-YYYY LT")
                }},
                {"data": "actions", 
                "render": function(data, display, record) {
                    let del = record.has_bookings == 'false' ? `<a href='javascript:' class='delete-element' data-element_id='${record.id}' title='Delete'><i class='fa-solid fa-trash-can'></i></a>` : ''

                    let actions = `<a href="/mccadmin/meet_greets/${record.id}/edit" class="edit-element" data-element_id='${record.id}' title="Edit"><i class="fa-solid fa-pen"></i></a> `

                    if (record.creator != `${$("#user_type").attr("data-first_name")}` && $("#user_type").val() != "administrator") {
                        actions = `<a href="/mccadmin/meet_greets/${record.id}/edit" class="edit-element" data-element_id='${record.id}' title="Edit"><i class="fa-solid fa-eye"></i></a> `
                    }

                    if ($("#user_type").val() == "administrator")
                        actions += `<a href='javascript:' class='delete-element' data-element_id='${record.id}' title='Delete'><i class='fa-solid fa-trash-can'></i></a>`

                    return `${actions}`
                 }
                }
            ],
            createdRow: function (row, data, index) {
                $(row).attr("data-element_id", data.id)
            },
            "fnDrawCallback": function( row, data, index ) {

                //$(newRow).attr("id", `worksite-` + val.id);

                $(`.edit-element`).on("click", function (e) {
                    method = 'edit'
                    let id = $(this).data('element_id')
               
                });

                $('#table-meet_greets').on("click", `.delete-element`, function(e) {

                    let id = $(this).data('element_id')

                        destroy(id)
              
                })
            },
            initComplete: function( settings, json ) {
               
            },
            "bPaginate": true,
            "iDisplayLength": 50,
            "bFilter": true,
            "bInfo": false,
            "bLengthChange": false,
            "searching": true,
            "destroy": true,
            "stateSave": false,
            "scrollCollapse": true,
            "oLanguage": {
                "sLengthMenu": "_MENU_ ",
                "sInfo": "Showing <b>_START_ to _END_</b> of _TOTAL_ entries"
            },
            "bAutoWidth": false,
            'order' : [[0,'desc']],
            'columnDefs': [
                {
                    'targets': 0,
                    'width': 50
                },
                {
                    'targets': 1,
                    'width': 200
                },
                {
                    'targets': 3,
                    'width': 50
                },
                {
                    'targets': 4,
                    'width': 100
                },
                {
                    'targets': 5,
                    'width': 120
                },
                {
                    'targets': 6,
                    'width': 50
                },
            ],
        });
        //$("#table-service_options_wrapper > div > .col-sm-6:nth-child(1)").addClass("content-left pt-2")

        $('#meet_greet-member_name').select2({
            placeholder: "Select a member",
            ajax: {
                url: '/mccadmin/members/get-members-name',
                dataType: 'json',
                data: function (params) {
                    $(".select2-results__options").append("<img src='/assets/loader'>")
                    var query = {
                      search: params.term,
                      type: 'public'
                    }
                    return query;
                },
                processResults: function (data, params) {
                    params.page = params.page || 1;
                    return {
                        results: data.items,
                        pagination: {
                            more: (params.page * 30) < data.total_count
                        }
                    };
                }
            },
            cache: true,
            minimumInputLength: 2,
            templateResult: function (repo) {
                if (repo.loading) {
                    return repo.name;
                }
                
                var $container = $(
                    "<div class='select2-result-repository clearfix'>" +
                        "<div class='select2-result-repository__meta'>" +
                            "<div class='select2-result-repository__title'></div>" +
                        "</div>" +
                    "</div>"
                );
                
                $container.find(".select2-result-repository__title").text(repo.name);
                
                return $container;
            },
            templateSelection: function(repo) {
                if (repo.data != undefined){
                    $("#meet_greet_member_id").val(repo.data.id)
                    $("#meet_greet_membership_number").val(repo.data.membership_number)
                }
        
                return repo.name || repo.text;
            }
        });

        let meet_greet_dates = flatpickr("#meet_greet_dates", {
            mode: "multiple",
            dateFormat: "Y-m-d",
            allowInput: false,
            allowInvalidPreload: true,
            disableMobile: true,
            onOpen: function(selectedDates, dateStr, instance) {
                //filter_selectedDates = selectedDates
            },
            onClose: function(selectedDates, dateStr, instance) {
                // if (selectedDates.length != 2) {
                //     this.setDate(filter_selectedDates, true, "Y-m-d")
                // }
            },
            onChange: function(selectedDates, dateStr, instance) {
                // if (selectedDates.length == 2) {
                //     getBookings(moment(selectedDates[0]).format('YYYY-MM-DD'), moment(selectedDates[1]).format('YYYY-MM-DD'))
                // }
            },
        })


        let time = flatpickr("#meet_greet_time", {
            enableTime: true,
            noCalendar: true,
            dateFormat: "H:i",
            disableMobile: true,
        })


        if ($("#meet_greet_greeter_phone").val() != "") {
            $("#meet_greet-confirmed").attr("disabled", false)
        }
        else 
            $("#meet_greet-confirmed").attr("disabled", true)

        $("#meet_greet_greeter_phone").on("keyup", function() {
            if ($("#meet_greet_greeter_phone").val() != "") {
                $("#meet_greet-confirmed").attr("disabled", false)
            }
            else {
                $("#meet_greet-confirmed").attr("disabled", true)
                $("#meet_greet-confirmed").prop('checked', false)
            }
        })

        $("#meet_greet-confirmed").on("click", function() {
            $("#meet_greet-canceled").prop("checked", false)
        })
       
        $("#meet_greet-canceled").on("click", function() {
            $("#meet_greet-confirmed").prop("checked", false)
        })

        var meet_greet_reminder = flatpickr("#meet_greet_reminder", {
            enableTime: true,
            dateFormat: "Y-m-d H:i",
            time_24hr: true,
            allowInput: true,
            disableMobile: true,
            onOpen: function(selectedDates, dateStr, instance) {
            },
            onClose: function(selectedDates, dateStr, instance) {
                
            },
            onChange: function(selectedDates, dateStr, instance) {
               
            },
        })

        $('#btn-instructions').click(function () {
            method = 'create'
            
            $('#modal-instructions').modal('show')
        })
        
        $("#btn-save-mg_instructions").on("click", function() {
            let formData = new FormData();

            formData.append('instructions', $("#meet_greet_instructions").val());

            fetch(`/mccadmin/meet_greets/instructions`, {
                method: 'PUT',
                mode: 'cors',
                cache: 'default',
                body: formData,
            }).then(function(response) {
                if (response.ok) {
                    response.json().then(function(data) {
                        $('#modal-instructions').modal('hide')
                    })
                }
                else {
                    response.json().then(function(data) {
                        $(".alert-danger").removeClass("hidden")
                        $(".alert-danger").text(data.error)
                    })
                }
            })
        })


        function destroy(element_id) {

            Swal.fire({
                title: 'Are you sure?',
                text: "You won't be able to revert this!",
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Yes, delete it!',
                allowOutsideClick: false,
                allowEscapeKey: false,
                showLoaderOnConfirm: true,
                preConfirm: () => {

                    if (element_id != null) {
                        return fetch(`/mccadmin/meet_greets/${element_id}`, {
                            method: 'DELETE',
                            mode: 'cors',
                            cache: 'default',
                            headers: {
                                'Accept': 'application/json, text/plain, */*',
                                'Content-Type': 'application/x-www-form-urlencoded'
                            },
                        }).then(function(response) {
                            if (response.ok) {
                               
                                table_meet_greets.ajax.reload()

                                toastr.info('Meet & Greet successfully deleted!')
                            }
                        })
                    }
                }
            }).then((result) => {
                if (result.isConfirmed) { 
                }
            })
        }

    }
})
document.addEventListener("turbolinks:load", function() {
    if($("body").data("controller") == "members") {
        document.addEventListener("turbolinks:before-cache", function() {
            if (table != null) {
                table.destroy();
            }

            $.fn.dataTable.ext.search.pop();
        })

        table = $('#table-orders').DataTable({
            "bPaginate": true,
            "bFilter": true,
            "bInfo": false,
            "bLengthChange": false,
            "pageLength": 50,
            "searching": false,
            "destroy": true,
            "stateSave": false,
            "scrollCollapse": true,
            "oLanguage": {
                "sLengthMenu": "_MENU_ ",
                "sInfo": "Showing <b>_START_ to _END_</b> of _TOTAL_ entries"
            },
            "bAutoWidth": false,
            'order' : [[0,'desc']]
        });
        $("#table-orders_wrapper > div > .col-sm-6:nth-child(1)").addClass("content-left pt-2")
        // $("#table-orders_wrapper > .row > div:first").append("<div class='table-status'><span>" +
        //     "<div class='form-check form-check-inline pr-0'>" + 
        //         "<input type='radio' name='order_status' id='order_status_all' value='all' checked>" +
        //         "<label for='order_status_all'>" + 
        //             "All " +
        //         "</label>" +
        //     "</div>" + 
        //     "<div class='form-check form-check-inline pr-0'>" + 
        //         "<input type='radio' name='order_status' id='order_status_pending' value='pending'>" +
        //         "<label for='order_status_pending'>" + 
        //             "Pending " +
        //         "</label>" +
        //     "</div>" + 
        //     "<div class='form-check form-check-inline '>" + 
        //      "<input type='radio' name='order_status' id='order_status_paid' value='paid'>" +
        //         "<label for='order_status_paid'>" + 
        //             "Paid " +
        //         "</label>" +
        //     "</div>" + 
        // "</span></div>")
    }


    $("table tbody").on("click", ".see-element", function(e) {
        JsLoadingOverlay.show({'spinnerIcon': 'ball-circus', 'containerID': 'page-container'});

        let order_id = $(this).parent().parent().parent().attr("data-element_id")

        $("#modal-order input").val("")
        $("#modal-order span").text("")
        
        getOrder(order_id)
    })

    $("table tbody").on("click", ".see-invoice", function(e) {
        JsLoadingOverlay.show({'spinnerIcon': 'ball-circus', 'containerID': 'page-container'});

        let order_id = $(this).parent().parent().parent().attr("data-element_id")

        $("#modal-order input").val("")
        $("#modal-order span").text("")
        
        getInvoice(order_id)
    })

    $("input[name='order_status']").on("change", function() {
       
        if($(this).val() == "pending") {
            $("#table-orders").dataTable.ext.search.pop()
            $("#table-orders").dataTable.ext.search.push(
                function(settings, data, dataIndex) {
                   return $(table.row(dataIndex).node()).attr('data-status') == 'pending';
                }
            );
        }
        else if($(this).val() == "paid") {
            $("#table-orders").dataTable.ext.search.pop()
            $("#table-orders").dataTable.ext.search.push(
                function(settings, data, dataIndex) {
                   return $(table.row(dataIndex).node()).attr('data-status') == 'succeeded';
                }
            );
        }
        else {
            $("#table-members").dataTable.ext.search.pop();
        }

        table.draw();
    })

    $("#create_payment_date_start").datepicker({
        format: 'dd-mm-yyyy',
        autoclose: true,
    })
    
    $("#btn-create_payment").on("click", function() {
        $("#modal-create_payment").modal('show')

    })

    $("#btn-save-payment").on("click", function() {
        $("#form-create_payment").validate()

        $('#form-create_payment').on("submit", function(e) {
            e.preventDefault()
        })

        if ($("#form-create_payment").valid()) {
      
            let formData = new FormData();
            formData.append('member_id', $("#member_id").val());
            formData.append('membership_number', $("#member_membership_number").val());
            formData.append('date_start', $("#create_payment_date_start").val());
            formData.append('membership_plan_id', $("#create_payment_plan").val());
            formData.append('payment_gateway', $("#create_payment_service").val());
            formData.append('price', $("#create_payment_price").val());

            Swal.fire({
                title: 'Payments',
                text: `Are you sure to create a payment for this member?`,
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Yes',
                allowOutsideClick: false,
                allowEscapeKey: false,
                showLoaderOnConfirm: true,
                preConfirm: () => {
                    return fetch(`/mccadmin/members/create-payment`, {
                        method: 'POST',
                        mode: 'cors',
                        cache: 'default',
                        body: formData,
                    }).then(function(response) {
                        if (response.ok) {
                            response.json().then(function(data) {
                                $("#modal-create_payment").modal('hide')

                                let newRow = table.row.add( [
                                    data.membership_plan.name,
                                    data.price,
                                    data.payment_gateway,
                                    moment.utc(data.start).format("LLL"),
                                    data.status_decorate,
                                    ''
                                ] ).draw( false ).node();

                                $(`#table-membership_plans tr td:nth-child(7)`).html("<span class='label badge-success'>active</span>")
                                $(`#table-membership_plans tr td:nth-child(4)`).text(moment.utc(data.expire).format("LLL"))

                                $(newRow).attr("data-element_id", data.id);
                                
                            })
                        }
                    })
                }
            })
        }
    })

    function getOrder(order_id) {

        fetch(`/mccadmin/members/get-order-detail/${order_id}`, {
            method: 'GET',
            mode: 'cors',
            cache: 'default',
        }).then(function(response) {
            if (response.ok) {
                $("#modal-order").modal('show')

                response.json().then(function(data) {
                    let status;
                    if (data.order.status == 'succeeded')
                        status = `<span class='label badge-success'>${data.order.status}</span>`
                    else 
                        status = `<span class='label badge-default'>${data.order.status}</span>`

                    // if (data.payment.charges.data[0].refunded == true) {
                    //     $('.refunded').removeClass('hidden')
                    //     $('.refunded').text(`Refunded at ${moment.unix(data.payment.charges.data[0].refunds.data[0].created).format("DD MMM YYYY HH:mm")}`)
                    // }

                    $("#order-username").val(data.order.member.username)
                    $("#order-amount").val(data.order.price)
                    $("#order-created").val(moment.utc(data.order.created_at).format("DD MMM YYYY HH:mm"))
                    $("#order-status").html(status)
                    $("#order-customer_name").text(`${data.order.member.first_name} ${data.order.member.last_name}`)
                    $("#order-customer_email").text(data.order.member.email)
                    $("#order-membership_plan").text(data.order.membership_plan.name)
                    $("#order-payment_service").text(data.order.payment_gateway)
                    $("#order-charge_id").text(data.order.charge_id).parent().attr("href", `${STRIPE_DASHBOARD_PAYMENT}/${data.order.charge_id}`)
  
                })
            }

            JsLoadingOverlay.hide();
        })
    }

    function getInvoice(order_id) {

        fetch(`/mccadmin/members/get-invoice-detail/${order_id}`, {
            method: 'GET',
            mode: 'cors',
            cache: 'default',
        }).then(function(response) {
            if (response.ok) {
                $("#modal-see_invoice").modal('show')
                
                response.json().then(function(data) {
                
                    $("#invoice_plan").val(data.order.membership_plan.name)
                    $("#invoice_start").val(moment.utc(data.subscription.start).format("DD-MM-YYYY HH:mm"))
                    $("#invoice_payment_date").val(moment.utc(data.order.created_at).format("DD-MM-YYYY HH:mm"))
                    $("#invoice_expire").val(moment.utc(data.subscription.expire).format("DD-MM-YYYY HH:mm"))
                    $("#invoice_note").val(data.subscription.invoice_note)
  
                })
            }

            JsLoadingOverlay.hide();
        })
    }

    $("#modal-see_invoice").on('hidden.bs.modal', function() {
        $("#invoice_plan").val('')
        $("#invoice_start").val('')
        $("#invoice_expire").val('')
        $("#invoice_payment_date").val('')
        $("#invoice_note").val('')
    });
})
document.addEventListener("turbolinks:load", function() {
    if($("body").data("controller") == "profiles" || ($("body").data("controller") == "members") && $("body").data("action") == "cc") {
     
        //$("#form-cc").submit(function(e){
        

        // $(".btn-delete_cc").on("click", function(e) {
        //     delete_cc($(e.currentTarget).data("id_cc"))
        // })

        // function delete_cc(id) {
        //     Swal.fire({
        //         title: "Would you really want to delete this credit card?",
        //         icon: "warning",
        //         showCancelButton: true,
        //         cancelButtonText: 'Cancel',
        //         confirmButtonText: 'Delete',
        //         allowOutsideClick: false,
        //             allowEscapeKey: false,
        //             showLoaderOnConfirm: true,
        //             preConfirm: () => {
        //                 return $.ajax({
        //                     url: `/members/members/cc/${id}`,
        //                     type: 'DELETE',
        //                     asynch: true,
        //                     dataType: "json",
        //                     success: function(response, status, xhr) {
        //                         $(".cc-" + id).remove()

        //                         $(".alert-cc").addClass('hidden')
        //                     },
        //                     error: function(response, status, xhr) {
        //                     }
        //                 })
        //             }
        //     }).then(result => {
        //         if (result.isConfirmed) {
                    

        //         }
        //     });
        // }
    }
})
document.addEventListener("turbolinks:load", function() {
    if($("body").data("controller") == "travel_features") {
        
        if ($('#header-city').length > 0) {
            $('#header-city').skdslider({
                slideSelector: '.slide',
                delay: 5000,
                animationSpeed:0,
                showNextPrev:false,
                showPlayButton:false,
                autoSlide:true,
                animationType:'fading'
            }); 
        }
        
        $(".btn-discover").on("click", function() {
            $('html, body').animate({
                scrollTop: $("#post-categories").offset().top
            }, 300);
        })

        $(".select-page").on("click", function() {
            if ($(this).attr('disabled') == undefined) {
                getPosts(null, $(this).data('page'))
            }
        })

        $("#btn-back-to-top").on("click", function() {
            $("html, body").animate({ scrollTop: 0 }, "slow");
            return false; 
        })

        $("#btn-submit-comment").on("click", function() {
            if ($("#comment-text").val().trim() != '') {
                $(this).attr('disabled', true)
                $("#comment-text").attr('disabled', true)
                $(".comment-loader").removeClass("hidden")
 
                createComment() 
            }
        }) 

        $(".post-category").on("click", function() {
            let category_id = $(this).attr("data-id")

            if ($(`.post-category[data-id='${category_id}']`).hasClass('main-category')) {
                $(".post-category").children().removeClass('selected')
                $(".post-category").removeClass('selected')
                
                $(this).children().first().addClass('selected')
                $(this).addClass('selected')
            }
            else {
                $(".sub-category").children().removeClass('selected')
                $(".sub-category").removeClass('selected')
                
                $(this).children().first().addClass('selected')
                $(this).addClass('selected')
            }
            

            getPosts(category_id, null)
        })

        $(".comment-text").on("keyup ", function() {
            let total = $(this).prop('maxlength') - $(this).val().length
            $(".max-characters").text(`max characters: ${total}`)
        })
        
        function getPosts(category_id, page) {
          
            let city = $("#city-name").val()

            JsLoadingOverlay.show({'posts-container': 'triangle-skew-spin'});
            $(".pagination-one").removeClass('current_page')
            $(`.select-page[data-page='${page}'] > div`).addClass('current_page')
            $(`.select-page`).attr('disabled', false)
            $(`.select-page[data-page='${page}']`).attr('disabled', true)

            let url = `/travel-features/get-posts/${city}/${category_id}`

            if (page != null) {
                if (category_id != 'all') {
                    $(".pagination-one").removeClass('current_page')
                    $(`.select-page[data-page='${page}'] > div`).addClass('current_page')
                    $(`.select-page`).attr('disabled', false)
                    $(`.select-page[data-page='${page}']`).attr('disabled', true)

                    url = `/travel-features/get-posts/${city}/${category_id}/${page}`
                }
                else {
                    $(".pagination-one").removeClass('current_page')
                    $(`.select-page[data-page='${page}'] > div`).addClass('current_page')
                    $(`.select-page`).attr('disabled', false)
                    $(`.select-page[data-page='${page}']`).attr('disabled', true)

                    url = `/travel-features/get-posts/${city}/${$(".post-category.selected").attr('data-id')}/${page}` 
                }
            }

            
            fetch(url, {
                method: 'GET',
                mode: 'cors',
                cache: 'default',
            }).then(function(response) {
                if (response.ok) {
                    let categories;
                    response.json().then(function(data) {
                        JsLoadingOverlay.hide()

                        $(".posts-container").html("")
                        $(".post-pagination ").html("")
                        $(".total-articles").text(`${data.total_posts} articles`)
                 
                        for (val of data.articles) {
        
                            post_categories = []
                            
                            if (val.post_categories != undefined){
                                for (category of val.post_categories) {
                                    post_categories.push(category.category.title)
                                }
                            }
                            else {
                                for (category of val.article_categories) {
                                    post_categories.push(category.category.title)
                                }
                            }

                            $(".posts-container").append(`
                                <div class='post-content mb-4'>
                                        <div class='post-container' title="${val.title}">
                                        <div class="img-hover-zoom img-hover-zoom--point-zoom text-center">
                                            <a href='/travel-features/${city}/services/${val.slug}'><img src='${val.image.url}' alt='image service' style='position: relative; right: 58px'></a>
                                        </div>
                                        <div class='post-category mt-2'>${post_categories[0]}</div>
                                        <a href='/travel-features/${city}/services/${val.slug}'>
                                        <div class='post-name'>
                                            ${val.title.length >= 70 ? val.title.substr(0, 70) + "..." : val.title}
                                        </div></a>
                                    </div>
                                </div>`)
                        }
                        
                        // for (val of data.articles) {
        
                        //     article_categories = []

                        //     for (category of val.article_categories) {
                        //         article_categories.push(category.category.title)
                        //     }

                        //     $(".posts-container").append(`
                        //         <div class='col-lg-3 col-md-6 col-sm-6 col-12 mt-4 '>
                        //         <div class='post-container'>
                        //             <div class="img-hover-zoom img-hover-zoom--point-zoom text-center">
                        //                 <a href='/travel-features/${city}/services/${val.slug}'><img src='${val.image.url}' alt='image service'></a>
                        //             </div>
                        //             <div class='post-category mt-2'>${article_categories.join(' | ')}</div>
                        //             <a href='/travel-features/${city}/services/${val.slug}'><div class='post-name'>${val.title}</div></a>
                        //         </div>
                        //     </div>`)
                        // }
   
                        for (let page=0 ; page<data.total_pages ; page++) {
                           
                            $(".post-pagination").append(`
                                <a href='javascript:void(0)' class='select-page page-${page+1}' ${page+1 == data.current_page ? 'disabled' : ''} data-page='${page+1}'><div class='pagination-one ${page+1 == data.current_page ? 'current_page' : ''}'>${page + 1}</div></a>
                            `)

                            $(`.page-${page+1}`).on("click", function() {
                                if ($(this).attr('disabled') == undefined) {
                                    getPosts(data.category_id, page+1)
                                }
                            })
                        }

                        if ($(`.post-category[data-id='${category_id}']`).hasClass('main-category')) {
                            $(".sub-categories").html('')
                            $("#post-categories hr").addClass('hidden')


                            if (data.sub_categories != null) {
                                $("#post-categories hr").removeClass('hidden')
                                
                                for (category of data.sub_categories) {
                                    $(".sub-categories").append(`
                                        <a href="javascript:void(0)" class='post-category sub-category' data-id='${category.id}' data-title='${category.title}'>
                                            <li class="list-group-item articles-filter-item">${category.title}</li>
                                        </a>
                                    `)

                                    $(`.post-category[data-id='${category.id}']`).on("click", function() {
                                        if ($(this).hasClass('main-category')) {
                                            $(".post-category").children().removeClass('selected')
                                            $(".post-category").removeClass('selected')
                                            
                                            $(this).children().first().addClass('selected')
                                            $(this).addClass('selected')
                                        }
                                        else {
                                            $(".sub-category").children().removeClass('selected')
                                            $(".sub-category").removeClass('selected')
                                            
                                            $(this).children().first().addClass('selected')
                                            $(this).addClass('selected')
                                        }
                                        
                                        getPosts($(this).data("id"), null)
                                    })
                                }
                            }
                        }
                       

                        $('html, body').animate({
                            scrollTop: $("#post-categories").offset().top
                        }, 300);
                    })
                }
            })
        }

        $(".one-post-container").on("mouseenter", function() {
            $(this).find('.comment-delete-container').removeClass("hidden")
        })

        $(".one-post-container").on("mouseleave", function() {
            $(this).find('.comment-delete-container').addClass("hidden")
        })

        $(".btn-delete-comment").on("click", function() {
            let id = $(this).data('id')

            removeComment(id)
        })

        function createComment() {
            let formData = new FormData();
            formData.append('post_id', $("#post-id").val());
            formData.append('comment', $("#comment-text").val());

            fetch(`/travel-features/add-comment/${$("#post-id").val()}`, {
                method: 'POST',
                mode: 'cors',
                cache: 'default',
                body: formData,
            }).then(function(response) {
                if (response.ok) {
                    response.json().then(function(data) {
                 
                        $("#btn-submit-comment").attr('disabled', false)
                        $("#comment-text").attr('disabled', false)
                        $(".comment-loader").addClass("hidden")
                        $("#comment-text").val('')

                        $(".post-comments-content").append(`
                            <div class='container one-post-container mt-3' data-id='${data.id}'>
                                <div class='row justify-content-md-center'>
                                    <div class='col-lg-8 col-md-12 col-12'>
                                        <div class="profile-img-wrapper m-t-5 inline">
                                            <img width="35" height="35" src="${data.member.avatar.url == null ? '/assets/default-user.png' : data.member.avatar.url}" alt="avatar">
                                        </div>
                                        <div class="inline m-l-10">
                                            <p class="small comment-name m-t-5 mb-0">${data.member.first_name} ${data.member.last_name}</p>
                                            <p class='small hint-text  comment-date mb-0'>${moment.utc(data.created_at).format("MMM DD, YYYY | hh:mm: a")}</p>
                                        </div>
                                        <div class='pull-right comment-delete-container hidden'>
                                            <a href='javascript:void(0)' class='btn-delete-comment' data-id='${data.id}' title='Remove your comment'><i class="fa-solid fa-trash"></i></a>
                                        </div>
                                    </div>
                                </div>
                                <div class='row justify-content-md-center mt-0'>
                                    <div class='col-lg-1 col-md-1 col-1'>
                                    </div>
                                    <div class='col-lg-8 col-md-12 col-12' style='overflow-wrap: break-word;'>
                                        ${data.comment.replaceAll("\r\n", "<br>")}
                                    </div>
                                </div>
                            </div>
                        `)

                        $(".one-post-container").on("mouseenter", function() {
                            $(this).find('.comment-delete-container').removeClass("hidden")
                        })
                
                        $(".one-post-container").on("mouseleave", function() {
                            $(this).find('.comment-delete-container').addClass("hidden")
                        })

                        $(".max-characters").text(`max characters: ${$('.comment-text').prop('maxlength')}`)

                        $(".btn-delete-comment").on("click", function() {
                            let id = $(this).data('id')
                
                            removeComment(id)
                        })
                    })
                }
            })
        }

        function removeComment(id) {
            Swal.fire({
                text: "Would you really want to remove your comment ?",
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#1f6866',
                cancelButtonColor: '#6e7881',
                confirmButtonText: 'Yes, delete it!',
                allowOutsideClick: false,
                allowEscapeKey: false,
                showLoaderOnConfirm: true,
                showClass: {
                    popup: 'animated fadeInDown faster',
                  },
                  hideClass: {
                    popup: 'animate__animated animate__fadeOutUp'
                  },
                preConfirm: () => {
                    return fetch(`/travel-features/remove-comment/${id}`, {
                        method: 'DELETE',
                        mode: 'cors',
                        cache: 'default',
                    }).then(function(response) {
                        if (response.ok) {
                            $(`.one-post-container[data-id='${id}']`).remove()
                        }
                    })
                }
            })
        }
    }
})
document.addEventListener("turbolinks:load", function() {
    if($("body").data("controller") == "members") {
        let filter_booking = ['pending', 'confirmed', 'archived', 'canceled']
        let filter_recommendation = ['pending', 'treated']
        let followup_id = null

        document.addEventListener("turbolinks:before-cache", function() {
            if (table_members != null) {
                table_members.destroy();
            }

            $.fn.dataTable.ext.search.pop();
        })

        table_members = $('#table-members').DataTable({ 
            'initComplete': function(settings){
                var api = this.api();
                
                api.cells(
                   api.rows(function(idx, data, node){
                      return ($(node).data('related')) ? true : false;
                   }).indexes(),
                   0
                ).checkboxes.disable();
            },
            "processing": true,
            "language": {
                processing: '<i class="fa fa-spinner fa-spin fa-3x fa-fw"></i><span class="sr-only">Loading...</span> '
            },
            "serverSide": true,
            "ajax": {
                "url": $('#table-members').data('source'),
                "data": function(e){
                    //"member_status": $("input[name='member_status']:checked").val(),
                    e.member_status = $("input[name='member_status']:checked").val()
                    e.filter_expired_periode_start = filter_expired_periode_start
                    e.filter_expired_periode_end = filter_expired_periode_end
                }
            },
            "pagingType": "full_numbers",
            "columns": [
                {"data": "membership_number"},
                {"data": "first_name",
                    "render": function(data, display, record) {
                        let feedback = ''
                        record.feedback == 'true' ? feedback = `<i class='fa-solid fa-flag feedback'></i>` : ''

                        return record.followup == 'true' ? `${feedback} <i class='fa-solid fa-circle-exclamation request'></i> <a href="/mccadmin/members/${record.id}/edit">${data}</a>` : `<a href="/mccadmin/members/${record.id}/edit">${feedback} ${data}</a>`
                        return `<a href="/mccadmin/members/${record.id}/edit">${data}</a>`
                    }
                },
                {"data": "last_name",
                    "render": function(data, display, record) {
                        return `<a href="/mccadmin/members/${record.id}/edit">${data}</a>`
                    }
                },
                {"data": "email",
                "render": function(data) {
                        return `<div title='${data}'>${data}</div>`
                    }
                },
                {"data": "second_email"},
                {"data": "phone",
                    "render": function(data) {
                    return `<div title='${data}'>${data}</div>`
                }},
                {"data": "plan_expired",
                "render": function(data) {
                    if (data != "")
                        return `<div title='${data}'>${moment.utc(data).format("DD-MM-YYYY HH:mm")}</div>`
                    else
                        return ''
                    }
                },
                {"data": "created_at",
                "render": function(data) {
                        return `<div title='${data}'>${moment.utc(data).format("DD-MM-YYYY HH:mm")}</div>`
                    }
                },
                {"data": "plan_status"},
                {"data": "status",
                "render": function(data) {
                    return data
                }},
                // {"data": "created_at",
                // "render": function(data) {
                //     return `<div title='${data}'>${moment.utc(data).format("DD MMM YYYY HH:mm")}</div>`
                // }},
                {"data": "actions",
                 "render": function(data, display, record) {
                    let del = data == "true" ? "<a href='javascript:' class='delete-element' title='Delete'><i class='fa-solid fa-trash-can'></i></a>" : ""
                    return `<a href="/mccadmin/members/${record.id}/edit" class="edit-element" title="Edit"><i class="fa-solid fa-pen"></i></a>
                        ${del}`
                 }
                }
            ],
            createdRow: function (row, data, index) {

                $(row).attr("data-element_id", data.id)
                $(row).attr("data-status", data.status)
                $(row).attr("data-subscription_status", data.plan_status)
                if (data.status.indexOf("approved") < 0) 
                    $(row).addClass('incompleted')
                
            },
            'select': {
                'style': 'multi',
                "selector": 'td:first-child'
            },
            "bPaginate": true,
            "bFilter": true,
            "pageLength": 50,
            "ordering": true,
            "bInfo": false,
            "bLengthChange": false,
            "searching": true,
            "destroy": true,
            "stateSave": false,
            "scrollCollapse": true,
            'order' : [[6,'desc']],
            "oLanguage": {
                "sLengthMenu": "_MENU_ ",
                "sInfo": "Showing <b>_START_ to _END_</b> of _TOTAL_ entries"
            },
            "bAutoWidth": false,
            columnDefs: [
                { "targets": 4, "visible": false, "searchable": true}
            ]
        });

        $("#table-members_wrapper > .row > div:first").removeClass('col-sm-6')
        $("#table-members_wrapper > .row > div:first").addClass('col-sm-7')
       
        $("#table-members_wrapper > .row div:last-child").addClass('col-sm-3')
        $("#table-members_wrapper > .row div:last-child").removeClass('col-sm-6')
        $("#table-members_wrapper > .row > div:first").append("" +
            "<div class='form-check form-check-inline '>" + 
                "<input type='radio' name='member_status' id='member_status_followup' value='followup'>" +
                "<label for='member_status_followup'>" + 
                    "Follow up " +
                "</label>" +
            "</div>" + 
            "<div class='form-check form-check-inline '>" + 
                "<input type='radio' name='member_status' id='member_status_blocked' value='blocked'>" +
                "<label for='member_status_blocked'>" + 
                    "Blocked " +
                "</label>" +
            "</div>" + 
            "<div class='form-check form-check-inline '>" + 
                "<input type='radio' name='member_status' id='member_status_ended' value='ended'>" +
                "<label for='member_status_ended'>" + 
                    "Ended " +
                "</label>" +
            "</div>" + 
            "<div class='form-check form-check-inline '>" + 
                "<input type='radio' name='member_status' id='member_status_incomplete' value='incomplete'>" +
                "<label for='member_status_incomplete'>" + 
                    "Incomplete " +
                "</label>" +
            "</div>" + 
            "<div class='form-check form-check-inline '>" + 
             "<input type='radio' name='member_status' id='member_status_expired' value='expired'>" +
                "<label for='member_status_expired'>" + 
                    "Expired " +
                "</label>" +
            "</div>" + 
            "<div class='form-check form-check-inline '>" + 
             "<input type='radio' name='member_status' id='member_status_approved' value='approved'>" +
                "<label for='member_status_approved'>" + 
                    "Approved " +
                "</label>" +
            "</div>" + 
            "<div class='form-check form-check-inline pr-0'>" + 
                "<input type='radio' name='member_status' id='member_status_pending' value='pending'>" +
                "<label for='member_status_pending'>" + 
                    "Pending " +
                "</label>" +
            "</div>" + 
            "<div class='form-check form-check-inline pr-0'>" + 
                "<input type='radio' name='member_status' id='member_status_all' value='all' checked>" +
                "<label for='member_status_all'>" + 
                    "All " +
                "</label>" +
            "</div>" + 
        "")
        $("#table-members_wrapper > .row:first").append(`<div class='col-4'>
            <button class="clear_button form-control" title="clear" style='width: 100px;' data-clear>Clear</button>
            <input type='text' placeholder="Expired periode" id='filter-expired_periode' class='form-control' style='display: inline-block !important; width: 200px;' >
            Expired periode: 
        </div>`)

        $(".clear_button").on("click", function(){
           
            if (filter_expired_periode_start != undefined) {
                filter_expired_periode.clear();
                filter_expired_periode_start = null
                filter_expired_periode_end = null
                table_members.ajax.reload()
            }
        })

        let filter_expired_periode_start
        let filter_expired_periode_end
        let filter_expired_periode = flatpickr("#filter-expired_periode", {
            mode: "range",
            dateFormat: "Y-m-d",
            allowInput: false,
            allowInvalidPreload: true,
            //defaultDate: [new Date(new Date().setMonth(new Date().getMonth(), 1)), new Date(new Date().setMonth(new Date().getMonth()+1, 0))],
            onOpen: function(selectedDates, dateStr, instance) {
                filter_selectedDates = selectedDates
            },
            onClose: function(selectedDates, dateStr, instance) {
                if (selectedDates.length != 2) {
                    this.setDate(filter_selectedDates, true, "Y-m-d")
                }

                if (selectedDates.length == 2) {
                    filter_expired_periode_start = moment(selectedDates[0]).format('YYYY-MM-DD')
                    filter_expired_periode_end = moment(selectedDates[1]).format('YYYY-MM-DD')
                    table_members.ajax.reload()
                }
            },
            onChange: function(selectedDates, dateStr, instance) {
               
            }
        })

        table_membership_plans = $('#table-membership_plans').DataTable({
            "bPaginate": false,
            "bFilter": true,
            "bInfo": false,
            "bLengthChange": false,
            "searching": false,
            "destroy": true,
            "stateSave": false,
            "scrollCollapse": true,
            "oLanguage": {
                "sLengthMenu": "_MENU_ ",
                "sInfo": "Showing <b>_START_ to _END_</b> of _TOTAL_ entries"
            },
            "bAutoWidth": false,
            'order' : [[0,'desc']],
             "language": {
                 "emptyTable": "<img src='/assets/loading.svg' width='32'> Wait for Membership Plans..."
            }
        });
        $("#table-membership_plans_wrapper > div > .col-sm-6:nth-child(1)").addClass("content-left pt-2")

        let table_user_bookings = $('#table-user_bookings').DataTable({
            'initComplete': function(settings){
                var api = this.api();
       
                api.cells(
                   api.rows(function(idx, data, node){
                        return ($(node).data('type') === 'old') ? true : false;
                   }).indexes(),
                   0
                ).checkboxes.disable();
                
            },
            'columnDefs': [
                {
                   'targets': 0,
                   'checkboxes': {
                        'selectRow': false,
                        'selectCallback': function(nodes, selected) {
                            refresh_selected_booking_elements()
                        }
                    }
                }
            ],
            'select': {
                'style': 'multi',
                "selector": 'td:first-child'
            },
            "bPaginate": false,
            "bFilter": true, 
            //"pageLength": 50,
            "bInfo": false,
            "bLengthChange": false,
            "searching": true,
            "destroy": true,
            "stateSave": false,
            "scrollCollapse": true,
            "oLanguage": {
                "sLengthMenu": "_MENU_ ",
                "sInfo": "Showing <b>_START_ to _END_</b> of _TOTAL_ entries"
            },
            "bAutoWidth": false,
            'order' : [[7,'desc']]
        });
        $("#table-user_bookings_wrapper > div > .col-sm-6:nth-child(1)").addClass("content-left pt-2")
        $("#table-user_bookings_wrapper > .row > div:first").append("<div><span>" +
            "<div class='form-check checkbox-circle complete form-check-inline pr-0'>" + 
                "<input type='checkbox' name='user_bookings_status' id='member_status_pending' value='pending' checked>" +
                "<label for='member_status_pending'>" + 
                    "Pending " +
                "</label>" +
            "</div>" + 
            "<div class='form-check checkbox-circle complete  form-check-inline '>" + 
             "<input type='checkbox' name='user_bookings_status' id='member_status_confirmed' value='confirmed' checked>" +
                "<label for='member_status_confirmed'>" + 
                    "Confirmed" +
                "</label>" +
            "</div>" + 
            "<div class='form-check checkbox-circle complete  form-check-inline '>" + 
             "<input type='checkbox' name='user_bookings_status' id='member_status_archived' value='archived' checked>" +
                "<label for='member_status_archived'>" + 
                    "Archived" +
                "</label>" +
            "</div>" + 
            "<div class='form-check checkbox-circle complete  form-check-inline '>" + 
            "<input type='checkbox' name='user_bookings_status' id='member_status_canceled' value='canceled' checked>" +
                "<label for='member_status_canceled'>" + 
                    "Canceled" +
                "</label>" +
            "</div>" +
        "</span></div>")

        let table_user_recommendations = $('#table-user_recommendations').DataTable({
            "bPaginate": true,
            "bFilter": true,
            "bInfo": false,
            "bLengthChange": false,
            "searching": true,
            "destroy": true,
            "stateSave": false,
            "scrollCollapse": true,
            "oLanguage": {
                "sLengthMenu": "_MENU_ ",
                "sInfo": "Showing <b>_START_ to _END_</b> of _TOTAL_ entries"
            },
            "bAutoWidth": false,
            'order' : [[0,'desc']]
        });
        $("#table-user_recommendations_wrapper > div > .col-sm-6:nth-child(1)").addClass("content-left pt-2")
        $("#table-user_recommendations_wrapper > .row > div:first").append("<div><span>" +
            "<div class='form-check checkbox-circle complete form-check-inline pr-0'>" + 
                "<input type='checkbox' name='recommendation_status' id='recommendation_status_pending' value='pending' checked>" +
                "<label for='recommendation_status_pending'>" + 
                    "Pending " +
                "</label>" +
            "</div>" + 
            "<div class='form-check checkbox-circle complete  form-check-inline '>" + 
             "<input type='checkbox' name='recommendation_status' id='recommendation_status_treated' value='treated' checked>" +
                "<label for='recommendation_status_treated'>" + 
                    "Treated" +
                "</label>" +
            "</div>" +
        "</span></div>")

        let table_user_cc = $('#table-user_cc').DataTable({
            "bPaginate": false,
            "bFilter": true,
            "bInfo": false,
            "bLengthChange": false,
            "searching": false,
            "destroy": true,
            "stateSave": false,
            "scrollCollapse": true,
            "oLanguage": {
                "sLengthMenu": "_MENU_ ",
                "sInfo": "Showing <b>_START_ to _END_</b> of _TOTAL_ entries"
            },
            "bAutoWidth": false,
            'order' : [[0,'desc']]
        });
        $("#table-user_cc_wrapper > div > .col-sm-6:nth-child(1)").addClass("content-left pt-2")

        let table_booking_cc = $('#table-booking_cc').DataTable({
            "bPaginate": false,
            "bFilter": false,
            "bInfo": false,
            "bLengthChange": false,
            "searching": false,
            "destroy": true,
            "stateSave": false,
            "scrollCollapse": true,
            "oLanguage": {
                "sLengthMenu": "_MENU_ ",
                "sInfo": "Showing <b>_START_ to _END_</b> of _TOTAL_ entries"
            },
            "bAutoWidth": false,
            'order' : [[0,'desc']]
        });


        table_followup = $('#table-followup').DataTable({ 
            "processing": true,
            "language": {
                processing: '<i class="fa fa-spinner fa-spin fa-3x fa-fw"></i><span class="sr-only">Loading...</span> '
            },
            "serverSide": true,
            "ajax": {
                "url": $('#table-followup').data('source'),
                "data": function(e){
                    e.member_id = $("#member_id").val()
                }
            },
            "pagingType": "full_numbers",
            "columns": [
                {"data": "note",
                "render": function(data) {
                    return data.replaceAll('\n', '<br>')
                    }
                },
                {"data": "active",
                "render": function(data) {
                    return data
                    }
                },
                {"data": "created_at",
                "render": function(data) {
                        return `<div title='${data}'>${moment.utc(data).format("DD-MM-YYYY HH:mm")}</div>`
                    }
                },
                {"data": "user",
                "render": function(data) {
                    return data
                }},
                {"data": "actions",
                 "render": function(data, display, record) {
                    // let del = data == "true" ? "<a href='javascript:' class='delete-element' title='Delete'><i class='fa-solid fa-trash-can'></i></a>" : ""
                    // return `<a href="/mccadmin/members/${record.id}/edit" class="edit-element" title="Edit"><i class="fa-solid fa-pen"></i></a>
                    //     ${del}`
                    return `<a href='javascript:' class='edit-followup' data-followup_id='${data}' title='Edit'><i class='fa-solid fa-pen'></i></a>`
                 }
                }
            ],
            'initComplete': function(settings){
                $('#table-followup tbody').on("click", ".edit-followup", function (e) {
                    followup_id = $(e.currentTarget).data("followup_id")

                    //$("#followup_note").val($(`#table-followup tr[data-element_id='${followup_id}'] td:nth-child(1)`).text().replaceAll("<br>", '\r\n'))

                    $('#modal-followup').modal('show')

                    JsLoadingOverlay.show({
                        "overlayBackgroundColor": "#666666",
                        "overlayOpacity": 0.6,
                        'spinnerIcon': 'ball-triangle-path',
                        "spinnerColor": "#393636",
                        "containerID": "modal-followup-content"
                    });

                    $.ajax({
                        url: `/mccadmin/members/followup/${followup_id}`,
                        type: 'GET',
                        asynch: true,
                        dataType: "json",
                        success: function(data, status, xhr) {
                            
                            $("#followup_note").val(data.note)
                            $("#followup_active").prop('checked', data.active)

                            JsLoadingOverlay.hide();
                        },
                        error: function(response, status, xhr) {
                            console.log(response)
                        }
                    })

                   
                })
            },
            createdRow: function (row, data, index) {
                $(row).attr("data-element_id", data.id)

                let today = new Date(data.created_at)
                let expire = moment([today.getFullYear(), today.getMonth(), today.getDate(), today.getHours(), today.getMinutes()]).add('7', 'days').format('YYYY-MM-DD')

                if (data.active_raw == 'true') {
                    $(row).removeClass('followup_expired') 
                }
                else {
                    $(row).addClass('followup_expired') 
                }
                // $(row).attr("data-status", data.status)
                // $(row).attr("data-subscription_status", data.plan_status)
                // if (data.status.indexOf("approved") < 0) 
                //     $(row).addClass('incompleted')
            },
            'select': {
                'style': 'multi',
                "selector": 'td:first-child'
            },
            "bPaginate": true,
            "bFilter": true,
            "pageLength": 50,
            "ordering": true,
            "bInfo": false,
            "bLengthChange": false,
            "searching": false,
            "destroy": true,
            "stateSave": false,
            "scrollCollapse": true,
            'order' : [[1,'desc']],
            "oLanguage": {
                "sLengthMenu": "_MENU_ ",
                "sInfo": "Showing <b>_START_ to _END_</b> of _TOTAL_ entries"
            },
            "bAutoWidth": false,
        });

        // *** EVENTS

        $('#btn-new_followup').click(function () {
            $('#modal-followup').modal('show')
        })

        $("#btn-save-followup").on("click", function() {
            $("#form-followup").validate()
            $('#form-followup').on("submit", function(e) {
                e.preventDefault()
            })

            if ($("#form-followup").valid()) {
                $(".loader").removeClass("hidden")
                
                $("#btn-save-followup").attr("disabled", true)

                let url = '/mccadmin/members/followup'
                let type = 'POST'

                if (followup_id != null) {
                    url = `/mccadmin/members/followup/${followup_id}`
                    type = 'PUT'
                }

                $.ajax({
                    url: url,
                    type: type,
                    asynch: true,
                    data: {
                        member_id: $("#member_id").val(),
                        note: $("#followup_note").val(),
                        active: $("#followup_active").prop('checked')
                    },
                    dataType: "json",
                    success: function(data, status, xhr) {
                        
                        $("#followup_note").val("")
                        $("#btn-save-followup").attr("disabled", false)
                        $(".loader").addClass("hidden")
    
                        table_followup.ajax.reload()

                        $('#modal-followup').modal('hide')
                    },
                    error: function(response, status, xhr) {
                        console.log(response)
                    }
                })
            }

        })

        $("#modal-followup").on('hidden.bs.modal', function() {
            followup_id = null
            $("#followup_note").val("")
            $("#followup_active").prop('checked', false)
        });

        $("input[name='user_bookings_status']").on("change", function(e) {
            let state = $(this).val()

            if ($(this).prop("checked") === true) {
                filter_booking.push(state)
            }
            else {
                filter_booking.splice(filter_booking.indexOf(state), 1)
            }

            filterBooking(table_user_bookings, filter_booking)
        })

        $("input[name='recommendation_status']").on("change", function(e) {
            let state = $(this).val()

            if ($(this).prop("checked") === true) {
                filter_recommendation.push(state)
            }
            else {
                filter_recommendation.splice(filter_recommendation.indexOf(state), 1)
            }

            filterBooking(table_user_recommendations, filter_recommendation)
        })

        $("#member-membership_plan").select2({
            placeholder: "Assign Membership Plan",
            allowClear: false,
            minimumResultsForSearch: -1
        });
        $("#membership_assign_payment_method").select2({
            placeholder: "Payment Method",
            allowClear: false,
            minimumResultsForSearch: -1
        });
        $("#invoice_payment_method").select2({
            placeholder: "Payment Method",
            allowClear: false,
            minimumResultsForSearch: -1
        });
        $("#create_payment_plan").select2({
            placeholder: "Plan",
            allowClear: false,
            minimumResultsForSearch: -1
        });
        $("#create_payment_service").select2({
            placeholder: "Plan",
            allowClear: false,
            minimumResultsForSearch: -1
        });

        $('.autonumeric').autoNumeric('init');

        $("#member-birthday, #membership_assign_start, #membership_assign_expire, #membership_assign_payment_date").datepicker({
            format: 'dd-mm-yyyy',
            autoclose: true,
            multidate: false
        })

        $("#membership_assign_start").on("changeDate", function() {
            const date = $('#membership_assign_start').datepicker('getDate');
            let nextDayDate = new Date();
            nextDayDate.setDate(date.getDate() + 1);

            $("#membership_assign_expire").datepicker("setStartDate", nextDayDate)
        })

        $("#table-members tbody").on("click", ".delete-element", function() {
            element_id = $(this).parent().parent().attr("data-element_id")
            
            destroy(element_id)
        })
        
        $("#member-countries").select2({
            placeholder: "Select your country",
            allowClear: false,
            minimumResultsForSearch: -1
        });
        
        $("#btn-save-note").on("click", function(e) {
            
            if ($("#member_note").val().trim() != "") {

                $("#btn-save-note").attr("disabled", true)
                $(".save-note-loader").removeClass("hidden")
                $(".save_note_label").addClass("d-none")

                let formData = new FormData();
                formData.append("note", $("#member_note").val());

                fetch(`/mccadmin/members/note/${$("#member_membership_number").val()}`, {
                    method: 'PUT',
                    mode: 'cors',
                    cache: 'default',
                    type: 'application/json',
                    body: formData,
                }).then(function(response) {
                    
                    if (response.ok) {
                        response.json().then(function(data) {
                            $("#btn-save-note").attr("disabled", false)
                            $(".save_note_label").removeClass("d-none")
                            $(".save-note-loader").addClass("hidden")
                        })
                    }
                    else {
                        response.json().then(function(data) {
                         
                        })
                    }
                })

            }
        })

        $('#btn-save').on("click", function(e) {
            $("#form-member").validate()
            $('#form-member').on("submit", function(e) {
                e.preventDefault()
            })

            if ($("#form-member").valid()) {
                document.getElementById("form-member").submit()
            }
        })

        // open assign new membership
        $("#btn-assign_membership").on("click", function() {
            $("#modal-assign_membership").modal("show")
            $("#membership_assign_plan").val($("#member-membership_plan option:selected").text())
            
        })
        $("#modal-assign_membership").on('hidden.bs.modal', function() {
            $("#btn-save-assign_membership").html("<i class='fa-solid fa-floppy-disk mr-2'></i> Save")
            $("#btn-save-assign_membership").attr("disabled", false)
            $(".error-assign_membership").addClass("hidden")
            $(".error-assign_membership").html("")
        });

        // delete subscription
        $("table tbody").on("click", '.subscription-delete', function() {
            id = $(this).parent().parent().data("subscription_id")

            deleteSubscription(id)
        })

        // destroy subscription
        $("table tbody").on("click", '.subscription-destroy', function() {
            id = $(this).parent().parent().data("subscription_id")

            destroySubscription(id)
        })

        $("#membership_assign_payment_method").on("change", function() {

            if ($(this).val() == 'transfer') {
                $(".membership_assign_payment_date").removeClass("hidden")
                $("#membership_assign_payment_date").attr('required', true)
                $(".membership_assign_expire").removeClass("hidden")
                $("#membership_assign_expire").attr('required', true)

                $(".membership_assign_payment_gifted_by").addClass("hidden")
                $("#membership_assign_payment_gifted_by").attr('required', false)

                $(".membership_assign_payment_invoice_note").addClass("hidden")
            }
            else if ($(this).val() == 'gift') {
                $(".membership_assign_payment_date").addClass("hidden")
                $("#membership_assign_payment_date").removeAttr('required')
                $("#membership_assign_expire").removeClass("hidden")
                $(".membership_assign_expire").removeClass("hidden")
                $("#membership_assign_expire").attr('required', true)

                $(".membership_assign_payment_gifted_by").removeClass("hidden")
                $("#membership_assign_payment_gifted_by").attr('required', true)

                $(".membership_assign_payment_invoice_note").addClass("hidden")
            }
            else if ($(this).val() == 'credit_card') {
                $(".membership_assign_payment_date").addClass("hidden")
                $("#membership_assign_payment_date").removeAttr('required')
                $(".membership_assign_expire").addClass("hidden")
                $("#membership_assign_expire").attr('required', false)

                $(".membership_assign_payment_gifted_by").addClass("hidden")
                $("#membership_assign_payment_gifted_by").attr('required', false)

                $(".membership_assign_payment_invoice_note").addClass("hidden")
            }
            else if ($(this).val() == 'stripe invoice') {
                $(".membership_assign_payment_date").removeClass("hidden")
                $("#membership_assign_payment_date").attr('required', true)
                $(".membership_assign_expire").removeClass("hidden")
                $("#membership_assign_expire").attr('required', true)

                $(".membership_assign_payment_gifted_by").addClass("hidden")
                $("#membership_assign_payment_gifted_by").attr('required', false)

                $(".membership_assign_payment_invoice_note").removeClass("hidden")
            }
        })

        $("#btn-save-assign_membership").on("click", function() {

            $("#form-assign_membership").validate()
            $('#form-assign_membership').on("submit", function(e) {
                e.preventDefault()
            })

            if ($("#form-assign_membership").valid()) {

                $(".error-assign_membership").addClass("hidden")
                
                let formData = new FormData();
                formData.append('member_id', $("#member_id").val());
                formData.append('membership_plan_id', $("#member-membership_plan").val());
                formData.append('payment_method', $("#membership_assign_payment_method option:selected").val());
                formData.append('payment_date', $("#membership_assign_payment_date").val());
                formData.append('start', $("#membership_assign_start").val());
                formData.append('expire', $("#membership_assign_expire").val());
                if ($("#membership_assign_payment_method option:selected").val() == 'gift') {
                    formData.append('gifted_by', $("#membership_assign_payment_gifted_by").val());
                }
                if ($("#membership_assign_payment_method option:selected").val() == 'stripe invoice') {
                    formData.append('invoice_note', $("#membership_assign_payment_invoice_note").val());
                }

                let until = ""
                if ($("#membership_assign_payment_method").val() != 'stripe') {
                    until = `until ${$('#membership_assign_expire').val()}`
                }
                else if ($("#membership_assign_payment_method").val() == 'stripe') {
                    until = ''
                }

                Swal.fire({
                    title: 'Membership plan',
                    text: `Are you sure to assign ${$('#membership_assign_plan').val()} from ${$('#membership_assign_start').val()} ${until} to this member?`,
                    icon: 'warning',
                    showCancelButton: true,
                    confirmButtonColor: '#3085d6',
                    cancelButtonColor: '#d33',
                    confirmButtonText: 'Yes, assign it!',
                    allowOutsideClick: false,
                    allowEscapeKey: false,
                    showLoaderOnConfirm: true,
                    preConfirm: () => {
                        return fetch(`/mccadmin/members/assign-plan`, {
                            method: 'POST',
                            mode: 'cors',
                            cache: 'default',
                            body: formData,
                        }).then(function(response) {
                            if (response.ok) {
                                response.json().then(function(data) {
 
                                    $("#modal-assign_membership").modal("hide")
                                    $("#btn-save-assign_membership").html("<i class='fa-solid fa-floppy-disk mr-2'></i> Save")
                                    $("#btn-save-assign_membership").attr("disabled", false)
                                    $("select[name='member[status]']").val('1') 
                                
                                    if (table_membership_plans.data().count() > 0) {
                                        $(`#table-membership_plans tr[data-subscription_id='${data.subscription.id}'] td:nth-child(1)`).text(data.subscription.id)
                                        $(`#table-membership_plans tr[data-subscription_id='${data.subscription.id}'] td:nth-child(2)`).text(data.subscription.membership_plan.name)
                                        $(`#table-membership_plans tr[data-subscription_id='${data.subscription.id}'] td:nth-child(3)`).text(data.subscription.amount.cents)
                                        $(`#table-membership_plans tr[data-subscription_id='${data.subscription.id}'] td:nth-child(5)`).text(moment.utc(data.subscription.start).format("LL"))
                                        $(`#table-membership_plans tr[data-subscription_id='${data.subscription.id}'] td:nth-child(6)`).text(moment.utc(data.subscription.expire).format("LL"))
                                        $(`#table-membership_plans tr[data-subscription_id='${data.subscription.id}'] td:nth-child(6)`).text(data.subscription.payment_method_decorate)
                                        $(`#table-membership_plans tr[data-subscription_id='${data.subscription.id}'] td:nth-child(7)`).html(data.subscription.status_decorate)
                                    }
                                    else {
                                        // let newRow = table_membership_plans.row.add( [
                                        //     data.subscription.membership_plan.name,
                                        //     data.subscription.price,
                                        //     moment.utc(data.subscription.start).format("LL"),
                                        //     moment.utc(data.subscription.expire).format("LL"),
                                        //     data.subscription.payment_method_decorate,
                                        //     '',
                                        //     data.subscription != null ? data.subscription.status_decorate : '',
                                        //     ''
                                        // ] ).draw( false ).node();

                                        // $(newRow).attr("data-subscription_id", data.subscription.id);
                                    }
  
                                    if (data.subscription.payment_method == 'gift') {
                                        let newRow = table_membership_plans.row.add( [
                                            data.subscription.id,
                                            data.subscription.membership_plan.name,
                                            data.subscription.price,
                                            moment.utc(data.subscription.start).format("LL"),
                                            moment.utc(data.subscription.expire).format("LL"),
                                            `${data.subscription.payment_method_decorate} (by: ${data.subscription.gifted_by})`,
                                            '',
                                            data.subscription.status_decorate,
                                            `<a href="javascript:" class="delete-element subscription-delete" title="Delete subscription"><i class="fa-solid fa-trash-can"></i></a>`
                                        ] ).draw( false ).node();

                                        $(newRow).attr("data-subscription_id", data.subscription.id);
                                    }

                                    if (data.subscription.payment_method == 'transfer') {
                                        let newRow = table_membership_plans.row.add( [
                                            data.subscription.id,
                                            data.subscription.membership_plan.name,
                                            data.subscription.price,
                                            moment.utc(data.subscription.start).format("LL"),
                                            moment.utc(data.subscription.expire).format("LL"),
                                            data.subscription.payment_method_decorate,
                                            '',
                                            data.subscription.status_decorate,
                                            `<a href="javascript:" class="delete-element subscription-delete" title="Delete subscription"><i class="fa-solid fa-trash-can"></i></a>`
                                        ] ).draw( false ).node();

                                        $(newRow).attr("data-subscription_id", data.subscription.id);
                                        
                                        // create order
                                        newRow = table.row.add( [
                                            data.subscription.id,
                                            data.subscription.membership_plan.name,
                                            data.subscription.price,
                                            data.subscription.payment_method_decorate,
                                            moment.utc(data.subscription.created_at).format("LL"),
                                            data.order.status_decorate,
                                            ''
                                        ] ).draw( false ).node();

                                        $(newRow).attr("data-element_id", data.order.id);
                                    }

                                    if (data.subscription.payment_method == 'credit_card') {

                                        let newRow = table_membership_plans.row.add( [
                                            data.subscription.id,
                                            data.subscription.membership_plan.name,
                                            data.subscription.price,
                                            moment.utc(data.subscription.start).format("LL"),
                                            moment.utc(data.subscription.expire).format("LL"),
                                            data.subscription.payment_method_decorate,
                                            '',
                                            data.subscription.status_decorate,
                                            `<a href="javascript:" class="delete-element subscription-delete" title="Cancel subscription"><i class="fa-solid fa-trash-can"></i></a>`
                                        ] ).draw( false ).node();

                                        $(newRow).attr("data-subscription_id", data.subscription.id);

                                    }

                                    if (data.subscription.payment_method == 'stripe invoice') {
                                        let newRow = table_membership_plans.row.add( [
                                            data.subscription.id,
                                            data.subscription.membership_plan.name,
                                            data.subscription.price,
                                            moment.utc(data.subscription.start).format("LL"),
                                            moment.utc(data.subscription.expire).format("LL"),
                                            data.subscription.payment_method_decorate,
                                            '',
                                            data.subscription.status_decorate,
                                            `<a href="javascript:" class="delete-element subscription-delete" title="Delete subscription"><i class="fa-solid fa-trash-can"></i></a>`
                                        ] ).draw( false ).node();

                                        $(newRow).attr("data-subscription_id", data.subscription.id);

                                        // create order
                                        newRow = table.row.add( [
                                            data.subscription.id,
                                            data.subscription.membership_plan.name,
                                            data.subscription.price,
                                            data.subscription.payment_method_decorate,
                                            moment.utc(data.subscription.created_at).format("LL"),
                                            data.order.status_decorate,
                                            `<div class="element-actions">
                                                <a href="javascript:" class="see-invoice" title="See Order"><i class="fa-solid fa-eye"></i></a>
                                            </div>`
                                        ] ).draw( false ).node();

                                        $(newRow).attr("data-element_id", data.order.id);
                                    }

                                    // $("table tbody").on("click", '.subscription-delete', function() {
                                    //     id = $(this).parent().parent().data("subscription_id")
                            
                                    //     deleteSubscription(data.subscription.id)
                                    // })
                                    
        
                                    Swal.fire(
                                        'Subscription created!',
                                        '',
                                        'success'
                                    )
                                })
                            }
                            else {
                                response.json().then(function(data) {
                                    $(".error-assign_membership").removeClass("hidden")
                                    $(".error-assign_membership").html(data.error)
                                    $("#btn-save-assign_membership").html("<i class='fa-solid fa-floppy-disk mr-2'></i> Save")
                                    $("#btn-save-assign_membership").attr("disabled", false)
                                })
                            }
                        })
                    }
                }).then((result) => {})
            }
        })

        $("#btn-change_password").on("click", function() {
            let member_id = $("#member_id").val()

            let formData = new FormData();
            formData.append('new_password', $("#member-new-password").val());
            formData.append('send_email', $('#member-new-password_email').prop('checked'));
            
            Swal.fire({
                title: 'Change password',
                text: "Are you sure?",
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Yes, change it!',
                allowOutsideClick: false,
                allowEscapeKey: false,
                showLoaderOnConfirm: true,
                preConfirm: () => {
                    return fetch(`/mccadmin/members/change-password/${member_id}`, {
                        method: 'PUT',
                        mode: 'cors',
                        cache: 'default',
                        body: formData,
                    }).then(function(response) {
                        if (response.ok) {
                            $("#member-new-password").val("")
                            Swal.fire(
                                'Password changed successfully!',
                                '',
                                'success'
                            )
                        }
                        else {
                            
                        }
                    })
                }
            }).then((result) => {})
        })

        $(".btn-see-old_booking").on("click", function(e) {
            let id = $(this).data('id')

            $("#modal-old_booking").modal("show")

            JsLoadingOverlay.show({'spinnerIcon': 'ball-circus', 'containerID': 'modal-old_booking-content'});

            fetch(`/mccadmin/bookings/old-booking/${id}`, {
                method: 'GET',
                mode: 'cors',
                cache: 'default',
            }).then(function(response) {
                if (response.ok) {
                    response.json().then(function(data) {
                        
                        $("#old_booking-email").val(data.email)
                        $("#old_booking-phone").val(data.phone)
                        $("#old_booking-confirm_number").val(data.confirm_number)
                        $("#old_booking-service").val(data.service)
                        $("#old_booking-service_option").val(data.service_option)
                        $("#old_booking-city").val(data.city)
                        $("#old_booking-date").val(data.date)
                        $("#old_booking-number_people").val(data.number_people)
                        $("#old_booking-note_customer").val(data.note_customer)
                        $("#old_booking-admin_note").val(data.admin_note)
                        $("#old_booking-cc_used").text(data.credit_card_used ? 'Yes' : 'No')
                        
                        JsLoadingOverlay.hide()
                    })
                }
                else {
                    response.json().then(function(data) {
                        console.log(data)
                    })
                }
            })
        })

        $("#modal-old_booking").on("hidden.bs.modal", function(e) {
            $("#old_booking-email").val('')
            $("#old_booking-phone").val('')
            $("#old_booking-confirm_number").val('')
            $("#old_booking-service").val('')
            $("#old_booking-service_option").val('')
            $("#old_booking-city").val('')
            $("#old_booking-date").val('')
            $("#old_booking-number_people").val('')
            $("#old_booking-note_customer").val('')
            $("#old_booking-admin_note").val('data.admin_note')
            $("#old_booking-cc_used").text('')
        })
        
        $("#btn-copy-link").on("click", function() {
            navigator.clipboard.writeText($(".member_mermbership_link").attr("href"));
            toastr.info("Link copied to clipboard!", {timeOut: 6000})
        })

        // toggle pending/approved members
        $("input[name='member_status']").on("change", function() {
            
            // if($(this).val() == "pending") {
                
            //     $.fn.dataTable.ext.search.pop()
            //     $.fn.dataTable.ext.search.push(
            //         function(settings, data, dataIndex) {
            //             console.log(table_members.row(dataIndex).node()).attr('data-status')
            //            return $(table_members.row(dataIndex).node()).attr('data-status') == 'pending';
            //         }
            //     );
            // }
            // else if($(this).val() == "approved") {
            //     $("#table-members").dataTable.ext.search.pop();
            //     $("#table-members").dataTable.ext.search.push(
            //         function(settings, data, dataIndex) {
            //            return $(table_members.row(dataIndex).node()).attr('data-status') == 'approved';
            //         }
            //     );
            // }
            // else if($(this).val() == "expired") {
            //     $("#table-members").dataTable.ext.search.pop();
            //     $("#table-members").dataTable.ext.search.push(
            //         function(settings, data, dataIndex) {
            //            return $(table_members.row(dataIndex).node()).attr('data-subscription_status') == 'expired';
            //         }
            //     );
            // }
            // else {
            //     $("#table-members").dataTable.ext.search.pop();
            // }
            
            //table_members.draw();
            table_members.ajax.reload()
        })

        $(`#export-selected-elements`).on("click", function(e) {
            window.open('/mccadmin/bookings/history/?ids=' + rows_selected.join(','), '_blank')
        })

        // FUNCTIONS
        
        function getMembershipPlans() {
            fetch(`/mccadmin/members/get-membership-plans/${$('#member_id').val()}`, {
                method: 'GET',
                mode: 'cors',
                cache: 'default',
                headers: {
                    'Accept': 'application/json, text/plain, */*',
                    'Content-Type': 'application/x-www-form-urlencoded'
                },
            }).then(function(response) {
                if (response.ok) {
                    response.json().then(function(data) {
                        table_membership_plans.rows().clear().draw();

                        if (data.subs.length > 0) {
                            for (let val of data.subs) {
                                let cancel_btn = `<a href="javascript:" class="delete-element subscription-delete" title="Cancel subscription"><i class="fa-solid fa-xmark"></i></a> `
                                let delete_btn = ` <a href="javascript:" class="delete-element subscription-destroy" title="Delete subscription"><i class="fa-solid fa-trash"></i></a>`
                                let gifted_by = ''

                                if (val[1].status == 'canceled' || val[1].status == 'expired' || val[1].status == "incomplete_expired")
                                    cancel_btn = ''
                                
                                if (val[1].status != 'incomplete')
                                    delete_btn = ''
                                if (val[1].payment_method == 'gift')
                                    gifted_by = `by (${val[1].gifted_by})`

                                let newRow = table_membership_plans.row.add( [
                                    val[1].id,
                                    val[1].membership_plan.name,
                                    val[1].price,
                                    moment.utc(val[1].start).format("LL"),
                                    moment.utc(val[1].expire).format("LL HH:mm"),
                                    `${val[1].payment_method_decorate} ${gifted_by}`,
                                    val[1].sub_id != null ? `<a href="${STRIPE_DASHBOARD_SUB}/${val[1].sub_id}" target='new'>${val[1].sub_id}</a>` : '',
                                    val[1].status_decorate,
                                    cancel_btn + delete_btn
                                ] ).draw( false ).node();
            
                                $(newRow).attr("data-subscription_id", val[1].id);

                            }
                        }
                        else {
                            $("#table-membership_plans .dataTables_empty").text("No membership plan exists yet!")
                            // $('#example').dataTable( {
                            //     "language": {
                            //       "emptyTable": "No data available in table"
                            //     }
                            // } );
                        }
                    })
                }
                else {
                    
                }
            })
        }

        function deleteSubscription(id) {

            Swal.fire({
                title: 'Cancel membership plan',
                text: "Are you sure? You won't be able to revert this!",
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Yes, canceled it!',
                allowOutsideClick: false,
                allowEscapeKey: false,
                showLoaderOnConfirm: true,
                preConfirm: () => {
                    return fetch(`/mccadmin/members/delete-assigned-plan/${id}`, {
                        method: 'DELETE',
                        mode: 'cors',
                        cache: 'default',
                        headers: {
                            'Accept': 'application/json, text/plain, */*',
                            'Content-Type': 'application/x-www-form-urlencoded'
                        },
                    }).then(function(response) {
                        if (response.ok) {

                            if ($(`#table-membership_plans tr[data-subscription_id='${id}'] td:nth-child(7)`).text() == '') {
                                // table_membership_plans.row($(`tr[data-subscription_id='${id}']`)).remove().draw();
                                // $("#table-membership_plans .dataTables_empty").text("No membership plan exists yet!")
                                $(`#table-membership_plans tr[data-subscription_id='${id}'] td:nth-child(8)`).html("<span class='label badge-danger'>canceled</span>")
                                $(`#table-membership_plans tr[data-subscription_id='${id}'] td:nth-child(9)`).html("")
                            }
                            else {
                                $(`#table-membership_plans tr[data-subscription_id='${id}'] td:nth-child(8)`).html("<span class='label badge-danger'>canceled</span>")
                                $(`#table-membership_plans tr[data-subscription_id='${id}'] td:nth-child(9)`).html("")
                            }

                            $("select[name='member[status]']").val('0')

                            Swal.fire(
                                'Subscription canceled!',
                                '',
                                'success'
                            )
                        }
                        else {
                            
                        }
                    })
                }
            }).then((result) => {})
        }

        function destroySubscription(id) {

            Swal.fire({
                title: 'Delete membership plan',
                text: "Are you sure? You won't be able to revert this!",
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Yes, delete it!',
                allowOutsideClick: false,
                allowEscapeKey: false,
                showLoaderOnConfirm: true,
                preConfirm: () => {
                    return fetch(`/mccadmin/members/destroy-assigned-plan/${id}`, {
                        method: 'DELETE',
                        mode: 'cors',
                        cache: 'default',
                        headers: {
                            'Accept': 'application/json, text/plain, */*',
                            'Content-Type': 'application/x-www-form-urlencoded'
                        },
                    }).then(function(response) {
                        if (response.ok) {
                            
                            table_membership_plans.row($(`tr[data-subscription_id='${id}']`)).remove().draw();
                            table.row($(`tr[data-subscription_id='${id}']`)).remove().draw();

                            if (table_membership_plans.data().count() == 0)
                                $("#table-membership_plans .dataTables_empty").text("No membership plan exists yet!")
                            
                            if (table.data().count() == 0)
                                $("#table-orders .dataTables_empty").text("No payments exists yet!")

                            Swal.fire(
                                'Subscription deleted!',
                                '',
                                'success'
                            )
                        }
                        else {
                            
                        }
                    })
                }
            }).then((result) => {})
        }

        function destroy(element_id, elements_id) {

            Swal.fire({
                title: 'Are you sure?',
                text: "You won't be able to revert this!",
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Yes, delete it!',
                allowOutsideClick: false,
                allowEscapeKey: false,
                showLoaderOnConfirm: true,
                preConfirm: () => {
                    return fetch(`/mccadmin/members/${element_id}`, {
                        method: 'DELETE',
                        mode: 'cors',
                        cache: 'default',
                        headers: {
                            'Accept': 'application/json, text/plain, */*',
                            'Content-Type': 'application/x-www-form-urlencoded'
                        },
                    }).then(function(response) {
                        if (response.ok) {
                            table_members.row($(`tr[data-element_id='${element_id}']`)).remove().draw();
                            
                        }
                    })
                }
            })
        }

        function refresh_selected_elements() {
            rows_selected = table.column(0).checkboxes.selected();

            if (rows_selected.length == 0) {
                $(".total-selected-content").addClass("hidden")
                $(".total-selected-content").css("marginBottom", 0)
                $(".total-selected-content").css("opacity", 0)
            }
            else {
                $(".total-selected-content").removeClass("hidden")
                $(".total-selected-content").animate({
                    marginBottom: 10,
                    opacity: 1
                }, 100, "linear",  function() {
                });
            }
          
            // $.each(rows_selected, function(index, rowId){
            // });
          
            let txt = rows_selected.length > 1 ? `${rows_selected.length} elements selected` : `${rows_selected.length} element selected`
            $(".total-selected").text(txt)
          
            return rows_selected
        }

        
        function refresh_selected_booking_elements() {
            rows_selected = table_user_bookings.column(0).checkboxes.selected();

            if (rows_selected.length == 0) {
                $(".total-selected-content").addClass("hidden")
                $(".total-selected-content").css("marginBottom", 0)
                $(".total-selected-content").css("opacity", 0)
            }
            else {
                $(".total-selected-content").removeClass("hidden")
                $(".total-selected-content").animate({
                    marginBottom: 10,
                    opacity: 1
                }, 100, "linear",  function() {
                });
            }
          
            let txt = rows_selected.length > 1 ? `${rows_selected.length} elements selected` : `${rows_selected.length} element selected`
            $(".total-selected").text(txt)
          
            return rows_selected
        }

        function filterBooking(table, filter) {
            $.fn.dataTable.ext.search.push(
                function(settings, data, dataIndex) {
                    return filter.includes($(table.row(dataIndex).node()).attr('data-state'));
                }
            );
                
            table.draw();
        }

        getMembershipPlans()


        $("#btn-save-booking_cc").on("click", function(e){
            $("#form-booking_cc").validate()

            $('#form-booking_cc').on("submit", function(e) {
                e.preventDefault()
            })

            if ($("#form-booking_cc").valid()) {

                createCreditCardBooking()
            }
        })

        function createCreditCardBooking() {
                 
            $(".alert-cc_booking-error").addClass('hidden')
            $(".alert-cc_booking-error ul").html(`<li></li>`)

            $(".alert-booking_cc").addClass('visually-hidden')
            $(".alert-booking_cc-error").addClass('visually-hidden')

            $(".cc_booking-create_loader").removeClass("hidden")
            $(".alert-booking_cc-error").addClass('hidden')

            $("#btn-save-booking_cc").attr("disabled", true)
            $("#member_booking-credit_card").attr("disabled", true)
            
            $(".cc-loader").removeClass("hidden")

            $.ajax({
                url: `/profiles/booking-credit-card`,
                type: 'POST',
                asynch: true,
                data: {
                    membership_number: $("#member-membership_number").val(),
                    credit_card_number: $("#member_booking-credit_card").val(),
                    card_date: $("#member_booking-credit_card_date").val(),
                    name_on_card: $("#member_booking-owner").val(),
                    zip_code: $("#member_booking-zip_code").val()
                },
                dataType: "json",
                success: function(data, status, xhr) {
                    
                    $("#btn-save-booking_cc").attr("disabled", false)
                    $("#member-credit_card").attr("disabled", false)
                    
                    $(".cc_booking-create_loader").addClass("hidden")
                    $(".alert-booking_cc").removeClass('hidden')

                    let newRow = table_booking_cc.row.add( [
                        `${data.credit_card_number}`,
                        data.name_on_card,
                        `${data.expire}`,
                        data.zip_code,
                        `<a href="javascript:void(0)" class="btn-delete_cc" data-id_cc='${data.id}' title='Delete credit card'><i class="fa-solid fa-trash-can"></i></a>`
                    ] ).draw( false ).node();
                    
                    $(newRow).attr('data-element_id', `${data.id}`)
                    $(newRow).addClass(`cc-${data.id}`)

                    $(".btn-delete_cc").on("click", function(e) {
                        delete_cc($(e.currentTarget).data("id_cc"))
                    })

                    $(".alert-booking_cc").removeClass('hidden')
                    
                    $("#member_booking-credit_card").val("")
                    $("#member_booking-credit_card_date").val("")
                    $("#member_booking-owner").val("")
                    $("#member_booking-zip_code").val("")

                    $("#member_booking-credit_card").attr("disabled", false)

                    $(".cc-loader").addClass("hidden")
                },
                error: function(response, status, xhr) {

                    $("#btn-save-booking_cc").attr("disabled", false)
                    $("#member_booking-credit_card").attr("disabled", false)

                    $(".cc_booking-create_loader").addClass("hidden")
                    $(".alert-cc_booking-error").removeClass('hidden')

                    $(".alert-cc_booking-error ul").html(`<li>${response.responseJSON.error}</li>`)

                    $(".cc-loader").addClass("hidden")
                }
            })
        }
        
        $(".btn-delete_cc").on("click", function(e) {
            delete_cc($(e.currentTarget).data("id_cc"))
        })

        function delete_cc(id) {
            Swal.fire({
                title: "Would you really want to delete this credit card?",
                icon: "warning",
                showCancelButton: true,
                cancelButtonText: 'Cancel',
                confirmButtonText: 'Delete',
                allowOutsideClick: false,
                    allowEscapeKey: false,
                    showLoaderOnConfirm: true,
                    preConfirm: () => {
                        return $.ajax({
                            url: `/profiles/booking-credit-card/${id}`,
                            type: 'DELETE',
                            asynch: true,
                            dataType: "json",
                            success: function(response, status, xhr) {
                                table_booking_cc.row($(`tr[data-element_id='${id}']`)).remove().draw();

                                $(".cc_booking-create_loader").addClass("hidden")
                                $(".alert-booking_cc").addClass('hidden')
                            },
                            error: function(response, status, xhr) {
                                console.log("error")
                            }
                        })
                    }
            }).then(result => {
                if (result.isConfirmed) {
                    

                }
            });
        }

        $("#btn-export").on("click", function() {
            $(this).attr("disabled", true)
            $(".loader-csv").removeClass("hidden")

            tableToCSV()
        })

        function downloadCSVFile(csv_data) {
 
            // Create CSV file object and feed
            // our csv_data into it
            CSVFile = new Blob([csv_data], {
                type: "text/csv"
            });
 
            // Create to temporary link to initiate
            // download process
            var temp_link = document.createElement('a');
 
            // Download csv file
            temp_link.download = "MCC_MEMBERS.csv";
            var url = window.URL.createObjectURL(CSVFile);
            temp_link.href = url;
 
            // This link should not be displayed
            temp_link.style.display = "none";
            document.body.appendChild(temp_link);
 
            // Automatically click the link to
            // trigger download
            temp_link.click();
            document.body.removeChild(temp_link);

            $("#btn-export").attr("disabled", false)
            $(".loader-csv").addClass("hidden")
        }

        function tableToCSV() {
            
            fetch(`/mccadmin/members/get_members_csv`, {
                method: 'GET',
                mode: 'cors',
                cache: 'default',
            }).then(function(response) {
                if (response.ok) {
                    response.json().then(function(data) {
                        let csv_data = [];
     
                        var csvrow = [];
                        csvrow.push('membership_number');
                        csvrow.push('first_name');
                        csvrow.push('last_name');
                        csvrow.push('birthday');
                        csvrow.push('email');
                        csvrow.push('phone');
                        csvrow.push('membership_plan');
                       // csvrow.push('expire');

                        csv_data.push(csvrow.join(","));

                        /*var csvrow = [];
                        csvrow.push('title2');
                        csvrow.push('content2');
                        csv_data.push(csvrow.join(","));

                        var csvrow = [];
                        csvrow.push('title3');
                        csvrow.push('content3');
                        csv_data.push(csvrow.join(","));*/

                        //csv_data = csv_data.join('\n');
                        
                        jQuery.each(data, function (i, val) {
                            //console.log(val[0])

                           
        
                            //var rows = document.getElementsByTagName('tr');
                            //for (var i = 0; i < rows.length; i++) {
                
                                // Get each column data
                               // var cols = rows[i].querySelectorAll('td,th');
                
                                // Stores each csv row data
                                // var csvrow = [];
                                // for (var j = 0; j < val.length; j++) {
                                //     csvrow.push(val[j]);
                                // }
                                
                                var csvrow = [];
                                csvrow.push(`"${val[0]}"`);
                                csvrow.push(`"${val[1]}"`);
                                csvrow.push(`"${val[2]}"`)
                                csvrow.push(`"${val[3]}"`);
                                csvrow.push(`"${val[4]}"`);
                                csvrow.push(`"${val[5]}"`)
                                csvrow.push(`"${val[6]}"`)
                                csv_data.push(csvrow.join(","));

                                // Combine each column value with comma
                                
                            //}
                
                            // Combine each row data with new line character
                            //csv_data = csv_data.join('\n');
                
                            // Call this function to download csv file 
                        
                        })
                       
                        csv_data = csv_data.join('\n');

                        downloadCSVFile(csv_data);
                    })
                }
            })
        }
    }
})
// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

import Rails from "@rails/ujs"
import Turbolinks from "turbolinks"
import * as ActiveStorage from "@rails/activestorage"
import "channels"
import 'bootstrap'
import "lightbox2"
// Global

require("jquery");
require("popper.js");
require("moment");
require("select2")
require("bootstrap");
require("sweetalert2");
require("lightbox2");
require("flatpickr")
require("toastr")
require("jquery-toast-plugin")
require("js-loading-overlay")
require("jquery-validation");
require("filepond");
require("js-loading-overlay")
require("packs/global/jquery.scrollbar.min");
require("packs/global/sidebar");
require("packs/global/selectFx")
require('packs/global/dialogFx')
require("packs/global/classie");
require("packs/global/bootstrap-datepicker");
require("packs/global/pages")
require("packs/global/jquery.dataTables.min")
require("packs/global/dataTables.checkboxes.min")
require("packs/global/dataTables.bootstrap")
require("packs/global/jquery-ui.min")
require("packs/global/jquery.dynatree.min")
require("packs/global/comboTreePlugin")
require("packs/app");
require("packs/global/bootstrap-tagsinput.min")
require("packs/global/autoNumeric")
require("packs/global/notyf.min");
require("packs/global/flickity.pkgd.min");
require("packs/global/flickity-fade");
require("dotenv")
require("packs/global/skdslider")
require("packs/global/slick.min") 
require("packs/global/jquery.autocomplete.min")
require("filepond");

//require("packs/global/jquery.autocomplete")
// require("packs/global/filepond.min")
// require("packs/global/filepond-plugin-image-preview.min")
//require("packs/global/nv.d3.min")
// require("packs/global/utils")
// require("packs/global/rickshaw.min")


// App
require("packs/app/users");
require("packs/app/membership_plans");
require("packs/app/members/members");
require("packs/app/members/orders");
require("packs/app/members/credit_cards");
require("packs/app/categories");
require("packs/app/cities");
require("packs/app/posts");
require("packs/app/services");
require("packs/app/service_options");
require("packs/app/car_rentals");
require("packs/app/meet_greets");
require("packs/app/travels");
require("packs/app/feedbacks");
require("packs/app/educations");
//require("packs/ckeditor");

require("packs/app/signup");
require("packs/app/checkout")
require("packs/app/configs/payment_settings")
require("packs/app/payment_histories")
require("packs/app/dashboard")
require("packs/app/recommendations");
require("packs/app/bookings");
require("packs/app/booking_requests");
require("packs/app/articles");
require("packs/app/lounges");
require("packs/app/email_notifs");
require("packs/app/home_sliders");
require("packs/app/front/travel_features")
require("packs/app/front/home")
require("packs/app/front/articles")
require("packs/app/front/about_us")
require("packs/app/front/signup")
require("packs/app/front/businesses")
require("packs/app/front/profiles")
require("packs/app/notifications")
require("packs/app/admin_notifications")
require("packs/app/interests")
require("packs/app/survey")
require("packs/app/contacts")

//require('packs/global/ckeditor')
//require('packs/global/ckeditor5_de')

//require("@ckeditor/ckeditor5-upload")

Rails.start()
Turbolinks.start()
ActiveStorage.start()

import JQuery from 'jquery';
window.$ = window.JQuery = JQuery;

import jQueryScrollbar from 'jquery.scrollbar';
window.jQueryScrollbar = jQueryScrollbar;

import * as FilePond from 'filepond'
window.filepond = FilePond

import Swal from 'sweetalert2';
window.Swal = Swal;

import bloodhound from 'packs/global/bloodhound.js';
window.Bloodhound = bloodhound;

import Flickity from 'packs/global/flickity-fade'
window.Flickity = Flickity;

//import ClassicEditor from "@ckeditor/ckeditor5-build-classic"
// window.ClassicEditor = ClassicEditor;

//import UploadAdapter from '@ckeditor/ckeditor5-adapter-ckfinder/src/uploadadapter'

// ClassicEditor.builtinPlugins = [
//   UploadAdapter
// ]

//import SimpleUploadAdapter from "@ckeditor/ckeditor5-upload/src/adapters/simpleuploadadapter"
// window.SimpleUploadAdapter = SimpleUploadAdapter;





global.toastr = require("toastr")
toastr.options = {
  "closeButton": false,
  "debug": false,
  "newestOnTop": false,
  "progressBar": false,
  "positionClass": "toast-bottom-right",
  "preventDuplicates": false,
  "onclick": null,
  "showDuration": "300",
  "hideDuration": "1000",
  "timeOut": "5000",
  "extendedTimeOut": "1000",
  "showEasing": "swing",
  "hideEasing": "linear",
  "showMethod": "fadeIn",
  "hideMethod": "fadeOut"
}

let table;
document.addEventListener("turbolinks:before-cache", function() {
    if (table != null) {
        table.destroy();
    }
})

$('[data-init-plugin="select2"]').each(function () {
  $(this).select2({
    minimumResultsForSearch: ($(this).attr('data-disable-search') == 'true' ? -1 : 1)
  }).on('select2:open', function () {
    $.fn.scrollbar && $('.select2-results__options').scrollbar({
      
    })
  });
});



// let map;

// function sendData(url, content) {
//   var xhr = new XMLHttpRequest();

//   xhr.onload = function () {
//     // Process our return data
//     if (xhr.status >= 200 && xhr.status < 300) {
//       // What do when the request is successful
//       console.log('success!', xhr);
//     } else {
//       // What do when the request fails
//       console.log('The request failed!');
//     }

//     // Code that should run regardless of the request status
//     console.log('This always runs...');
//   };

//   xhr.open('PUT', url);
//   xhr.setRequestHeader('X-CSRF-Token', document.querySelectorAll('meta[name="csrf-token"]')[0].content);

//   data = new FormData();
//   data.set("content", content);

//   xhr.send(data);
// }

// choose subscription plan
$(".btn-choose_plan").on('click', function(e) {
    window.location = '/orders/?plan=' + $(e.currentTarget).data("plan")
})

document.addEventListener("turbolinks:load", function() {
  $("#membership_plan-access_type, #plan-service").select2({
      placeholder: "Select access type",
      allowClear: false,
      minimumResultsForSearch: -1
  });


  $("#member_country").select2({
    placeholder: "Select a state",
    allowClear: false
  });

  const lightbox = GLightbox({
    touchNavigation: true,
    loop: false
  });

  $("#search-global").on('keypress',function(e) {
    if(e.which == 13) {
      window.location = "/mccadmin/search?find=" + $(this).val()
    }
  });
})


window.STRIPE_DASHBOARD_PAYMENT= "https://dashboard.stripe.com/payments"
window.STRIPE_DASHBOARD_SUB= "https://dashboard.stripe.com/subscriptions"


let stripeIFrameQuery = 'iframe[src^="https://js.stripe.com"]';
  document.addEventListener('turbo:before-render', function (event) {
    const stripeIFrame = document.querySelector(stripeIFrameQuery);
    const newStripeIFrame = event.detail.newBody.querySelector(stripeIFrameQuery);
    if (stripeIFrame && !newStripeIFrame){
      event.detail.newBody.appendChild(stripeIFrame)
    }
});

// accept cookies
const cookieBox = document.querySelector(".wrapper")
const buttons = document.querySelectorAll(".button")

const executeCodes = () => {
  if (document.cookie.includes("mcc-social")) return;
  
  cookieBox.classList.add("show");

  buttons.forEach(button => {
    button.addEventListener("click", () => {
      cookieBox.classList.remove("show");

      if (button.id == "acceptBtn") {
        document.cookie = "cookieBy= mcc-social; max-age=" + 60 * 60 * 24 * 30;
      }
    })
  })
}

window.addEventListener("load", executeCodes);
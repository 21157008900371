document.addEventListener("turbolinks:load", function() {
    if($("body").data("controller") == "home") {

        document.addEventListener("turbolinks:before-cache", function() {
            
            
        })


        // let flky = new Flickity( '.main-carousel', {
        //     prevNextButtons: false,
        //     imagesLoaded: true,
        //     wrapAround: true,
        //     pageDots: false,
        //     fade: true,
        //     autoPlay: false
        // })
        

        // let flkty = new Flickity( '.main-carousel', {
        //     imagesLoaded: true,
        //     fade: true,
        // });

        $(".btn-discover").on("click", function() {
            $('html, body').animate({
                scrollTop: $(".home-our-servics-container").offset().top
            }, 300);
        })

        $('#carouselExampleFade2').carousel({
            interval: 3000
        })

        $("#btn-back-to-top").on("click", function() {
            $("html, body").animate({ scrollTop: 0 }, "slow");
            return false;
        })

        $('.slide1').slick({
            slidesToShow: 5,
            slidesToScroll: 1,
            speed: 300,
            dots: false,
            variableWidth: true,
            touchThreshold: 400,
            responsive: [
                {
                breakpoint: 1024,
                    settings: {
                        slidesToShow: 4,
                        slidesToScroll: 1,
                        infinite: true,
                        dots: true
                    }
                },
                {
                    breakpoint: 600,
                    settings: {
                        slidesToShow: 2,
                        slidesToScroll: 1
                    }
                },
                {
                    breakpoint: 480,
                    settings: {
                        slidesToShow: 1,
                        slidesToScroll: 1
                    }
                }
            ]
        });
        
    }
})
document.addEventListener("turbolinks:load", function() {
    if($("body").data("controller") == "booking_requests") {

        let filter_booking_request = [0, 1]
        let filter_agent = [0, 1]

        let table_booking_requests = $('#table-booking_requests').DataTable({
            "processing": true,
            "language": {
                processing: '<i class="fa fa-spinner fa-spin fa-3x fa-fw"></i><span class="sr-only">Loading...</span> '
            },
            "serverSide": true,
            "ajax": {
                "url": $('#table-booking_requests').data('source'),
                "data": function(e){
                    e.status = filter_booking_request
                    e.user_id = $("#booking_request-filter").val()
                }
            },
            "pagingType": "full_numbers",
            "columns": [
                {"data": "membership_number"},
                {"data": "member"},
                {"data": "last_name"},
                {"data": "service"},
                {"data": "date", 
                    "render": function(data, display, record) {
                        return record.date
                    }
                },
                {"data": "user"},
                {"data": "status"},
                {"data": "created_at",
                    "render": function(data, display, record) {
                        return moment(record.created_at).format("DD-MM-YYYY LT")
                    }
                },
                {"data": "actions", 
                    "render": function(data, display, record) {
                        return `<a href="/mccadmin/booking_requests/${record.id}/edit" class="edit-element" data-element_id='${record.id}' title="See"><i class="fa-solid fa-eye"></i></a>`
                    }
                }
            ],
            createdRow: function (row, data, index) {
                $(row).attr("data-element_id", data.id)
            },
            "fnDrawCallback": function( row, data, index ) {
                $(`.edit-element`).on("click", function (e) {
                    method = 'edit'
                    let id = $(this).data('element_id')
                });
            },
            initComplete: function( settings, json ) {
               
            },
            scrollX:        true,
            paging:         true,
            "bPaginate": true,
            "iDisplayLength": 50,
            "bFilter": true,
            "bInfo": false,
            "bLengthChange": false,
            "searching": true,
            "destroy": true,
            "stateSave": false,
            "scrollCollapse": true,
            "oLanguage": {
                "sLengthMenu": "_MENU_ ",
                "sInfo": "Showing <b>_START_ to _END_</b> of _TOTAL_ entries"
            },
            "bAutoWidth": false,
            'order' : [[0,'desc']],
            'columnDefs': [
                {
                    'targets': 0,
                    'width': 150
                },
                {
                    'targets': 1,
                    'width': 200
                },
                {
                    'targets': 2,
                    'width': 10,
                    'visible': false
                },
                {
                    'targets': 3,
                    'width': 200
                },
                {
                    'targets': 4,
                    'width': 80
                },
                {
                    'targets': 5,
                    'width': 80
                },
                {
                    'targets': 6,
                    'width': 100
                },
                {
                    'targets': 7,
                    'width': 120
                }
            ],
        });
        $("#table-booking_requests_wrapper > div > .col-sm-6:nth-child(1)").addClass("content-left pt-2")

        $("#table-booking_requests_wrapper > div:nth-child(1) > .col-sm-6:nth-child(1)").removeClass("col-sm-6")
        $("#table-booking_requests_wrapper > div:nth-child(1) > div:nth-child(1)").addClass("col-sm-10")
        $("#table-booking_requests_wrapper > div:nth-child(1) > .col-sm-6:nth-child(2)").removeClass("col-sm-6")
        $("#table-booking_requests_wrapper > div:nth-child(1) > div:nth-child(2)").addClass("col-sm-2")

        // $("#table-booking_requests_wrapper > .row > div:first").append(`<div class="col-3">
        //     <select id='booking_request-filter' class="full-width" data-init-plugin="select2" multiple placeholder='Creators'>
        //     </select>
        // </div>`)
        // $("#table-booking_requests_wrapper > .row > div:first").append("<div class='mt-2'><span>" +
        //     "<div class='form-check checkbox-circle complete form-check-inline pr-0'>" + 
        //         "<input type='checkbox' name='booking_request_status' id='booking_request_status_pending' value='0' checked>" +
        //         "<label for='booking_request_status_pending'>" + 
        //             "Pending " +
        //         "</label>" +
        //     "</div>" + 
        //     "<div class='form-check checkbox-circle complete  form-check-inline '>" + 
        //      "<input type='checkbox' name='booking_request_status' id='booking_request_status_treated' value='1' checked>" +
        //         "<label for='booking_request_status_treated'>" + 
        //             "Treated" +
        //         "</label>" +
        //     "</div>" + 
        // "</span></div>")


        $("#booking_request-filter").select2({
            minimumResultsForSearch: -1,
            placeholder: "Creators"
        });

        $("#booking_request-filter").on("select2:select", function(e) {

            table_booking_requests.draw();
        })
        
        $("#booking_request-filter").on("select2:unselect", function(e) {

            table_booking_requests.draw();
        })

        $("input[name='booking_request_status']").on("change", function(e) {
            let state = $(this).val()

            if ($(this).prop("checked") === true) {
                filter_booking_request.push(parseInt(state))
            }
            else {
                filter_booking_request.splice(filter_booking_request.indexOf(parseInt(state)), 1)
            }

            table_booking_requests.ajax.reload()
        })


        $('#booking_request-user_id').select2({
            placeholder: "Select an agent",
            allowClear: false
        })
        $('#booking_request-member_name').select2({
            placeholder: "Select a member",
            ajax: {
                url: '/mccadmin/members/get-members-name',
                dataType: 'json',
                data: function (params) {
                    $(".select2-results__options").append("<img src='/assets/loader'>")
                    var query = {
                      search: params.term,
                      type: 'public'
                    }
                    return query;
                },
                processResults: function (data, params) {
                    params.page = params.page || 1;
                    return {
                        results: data.items,
                        pagination: {
                            more: (params.page * 30) < data.total_count
                        }
                    };
                }
            },
            cache: true,
            minimumInputLength: 2,
            templateResult: function (repo) {
                if (repo.loading) {
                    return repo.name;
                }
                
                var $container = $(
                    "<div class='select2-result-repository clearfix'>" +
                        "<div class='select2-result-repository__meta'>" +
                            "<div class='select2-result-repository__title'></div>" +
                        "</div>" +
                    "</div>"
                );
                
                $container.find(".select2-result-repository__title").text(repo.name);
                
                return $container;
            },
            templateSelection: function(repo) {
                if (repo.data != undefined){
                    $("#booking_request_member_id").val(repo.data.id)
                    $("#booking_request_membership_number").val(repo.data.membership_number)
                }
        
                return repo.name || repo.text;
            }
        });
    }
})
document.addEventListener("turbolinks:load", function() {
    if($("body").data("controller") == "feedbacks") {
        let selected_element_id = null

        let table_feebacks = $('#table-feedbacks').DataTable({
            "processing": true,
            "language": {
                processing: '<i class="fa fa-spinner fa-spin fa-3x fa-fw"></i><span class="sr-only">Loading...</span> '
            },
            scrollX:        true,
            paging:         true,
            "serverSide": true,
            "ajax": {
                "url": $('#table-feedbacks').data('source'),
                "data": function(e) {
                    e.treated = $("#feedback_status_treated").prop("checked")
                    e.pending = $("#feedback_status_pending").prop("checked")
                }
            },
            "pagingType": "full_numbers",
            "columns": [
                {"data": "member"},
                {"data": "category"},
                {"data": "text"},
                {"data": "treated", 
                    "render": function(data, display, record) {
                        return data == 'true' ? "<span class='label badge-info'>treated</span>" : "<span class='label label-warning '>pending</span>"
                }},
                {"data": "created_at",
                    "render": function(data, display, record) {
                    return moment(record.created_at).format("DD-MM-YYYY LT")
                }},
                {"data": "id", 
                    "render": function(data, display, record) {
                        return `<a href="/mccadmin/feedbacks/${record.id}/edit" class="edit-element" data-element_id='${record.id}' title="Edit"><i class="fa-solid fa-pen"></i></a>
                            <a href='javascript:' class='delete-element' data-element_id='${record.id}' title='Delete'><i class='fa-solid fa-trash-can'></i></a>`
                    }
                }
            ],
            createdRow: function (row, data, index) {
                $(row).attr("data-element_id", data.id)
            },
            "fnDrawCallback": function( row, data, index ) {

                //$(newRow).attr("id", `worksite-` + val.id);

                $(`.edit-element`).on("click", function (e) {
                    method = 'edit'
                    let id = $(this).data('element_id')
               
                });

                $('#table-feedbacks').on("click", `.delete-element`, function(e) {

                    let id = $(this).data('element_id')

                    destroy(id)
                })
            },
            initComplete: function( settings, json ) {
               
            },
            "bPaginate": true,
            "iDisplayLength": 50,
            "bFilter": true,
            "bInfo": false,
            "bLengthChange": false,
            "searching": false,
            "destroy": true,
            "stateSave": false,
            "scrollCollapse": true,
            "oLanguage": {
                "sLengthMenu": "_MENU_ ",
                "sInfo": "Showing <b>_START_ to _END_</b> of _TOTAL_ entries"
            },
            "bAutoWidth": false,
            'order' : [[0,'desc']],
            'columnDefs': [
                {
                    'targets': 0,
                    'width': 100
                },
                {
                    'targets': 1,
                    'width': 50
                },
                {
                    'targets': 2,
                    'width': 250
                },
                {
                    'targets': 3,
                    'width': 100
                },
                {
                    'targets': 4,
                    'width': 100
                },
                {
                    'targets': 5,
                    'width': 20
                },
            ],
        });

        $("#feedback_status_treated, #feedback_status_pending").on("click", function() {
            table_feebacks.ajax.reload()
        })

        $('#feedback-member_name').select2({
            placeholder: "Select a member",
            ajax: {
                url: '/mccadmin/members/get-members-name',
                dataType: 'json',
                data: function (params) {
                    $(".select2-results__options").append("<img src='/assets/loader'>")
                    var query = {
                      search: params.term,
                      type: 'public'
                    }
                    return query;
                },
                processResults: function (data, params) {
                    params.page = params.page || 1;
                    return {
                        results: data.items,
                        pagination: {
                            more: (params.page * 30) < data.total_count
                        }
                    };
                }
            },
            cache: true,
            minimumInputLength: 2,
            templateResult: function (repo) {
                if (repo.loading) {
                    return repo.name;
                }
                
                var $container = $(
                    "<div class='select2-result-repository clearfix'>" +
                        "<div class='select2-result-repository__meta'>" +
                            "<div class='select2-result-repository__title'></div>" +
                        "</div>" +
                    "</div>"
                );
                
                $container.find(".select2-result-repository__title").text(repo.name);
                
                return $container;
            },
            templateSelection: function(repo) {
                // if (repo.data != undefined){
                //     $("#booking_member_id").val(repo.data.id)
                //     $("#booking_membership_number").val(repo.data.membership_number)
                //     $("#booking_phone").val(repo.data.phone)
                //     $("#booking_email").val(repo.data.email)

                //     if (repo.data.nearest_booking.length != 0) {
                //         $(".nearest_booking").removeClass("hidden")
                //         $("#nearest_booking").val('- ' + repo.data.nearest_booking.service.title + ". \n- " + repo.data.nearest_booking.city + "\n- " + moment(repo.data.nearest_booking.date).format("DD MMMM YYYY"))
                //     }
                //     else {
                //         $(".nearest_booking").addClass("hidden")
                //     }

                //     if (repo.name.includes("(no subscription)") || repo.name.includes("(expired)") || repo.name.includes("(canceled)")) {
                //         $("#btn-save-booking").addClass("disabled")
                //         $("#btn-save-booking").attr("disabled", true)
                //         $(".error-member-state").html("<b>Unable to save because the member subscription is not active!</b>")
                //     }
                //     else {
                //         $("#btn-save-booking").removeClass("disabled")
                //         $("#btn-save-booking").attr("disabled", false)
                //         $(".error-member-state").html("")
                //     }

                //     table_bookmarks.ajax.reload()
                // }
        
                return repo.name || repo.text;
            }
        });


        function destroy(element_id) {

            Swal.fire({
                title: 'Are you sure?',
                text: "You won't be able to revert this!",
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Yes, delete it!',
                allowOutsideClick: false,
                allowEscapeKey: false,
                showLoaderOnConfirm: true,
                preConfirm: () => {

                    if (element_id != null) {
                        return fetch(`/mccadmin/feedbacks/${element_id}`, {
                            method: 'DELETE',
                            mode: 'cors',
                            cache: 'default',
                            headers: {
                                'Accept': 'application/json, text/plain, */*',
                                'Content-Type': 'application/x-www-form-urlencoded'
                            },
                        }).then(function(response) {
                            if (response.ok) {
                               
                                table_feebacks.ajax.reload()

                                toastr.info('Feedback successfully deleted!')
                            }
                        })
                    }
                }
            }).then((result) => {
                if (result.isConfirmed) { 
                }
            })
        }
    }
})
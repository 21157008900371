document.addEventListener("turbolinks:load", function() {
    if($("body").data("controller") == "payment_histories") {

        table = $('#table-payment_histories').DataTable({
            "processing": true,
            "language": {
                processing: '<i class="fa fa-spinner fa-spin fa-3x fa-fw"></i><span class="sr-only">Loading...</span> '
            },
            "serverSide": true,
            "ajax": {
                "url": $('#table-payment_histories').data('source'),
                "data": function(e) {
                }
            },
            "columns": [
                {"data": "id"},
                {"data": "username",
                    "render": function(data, display, record) {
                        return `<a href="/mccadmin/members/${record.member_id}/edit">${data}</a>`
                    }
                },
                {"data": "email"},
                {"data": "membership_plan"},
                {"data": "method"},
                {"data": "amount"},
                {"data": "created_at",
                    "render": function(data, display, record) {
                        return moment.utc(record.created_at).format("YYYY-MM-DD HH:mm")
                    }
                },
                {"data": "charge_id",
                    "render": function(data, display, record) {
                        return `<a href="${STRIPE_DASHBOARD_PAYMENT}/${record.charge_id}" target="new">${record.charge_id}</a>`
                    }
                },
                {"data": "status"}
            ],
            "columnDefs": [
                { className: "text-left", targets: [0]},
                { "width": "15%", "target": 0 },
                { "width": "20%", "target": 2 },
                { "width": "5%", "target": 5 },
                { "width": "11%", "target": 6 },
                { "width": "15%", "target": 7 },
                { "width": "7%", "target": 8 }
            ],
            "info": false,
            "bPaginate": true,
            "bFilter": true,
            "bInfo": false,
            "bLengthChange": false,
            "searching": true,
            "destroy": true,
            "stateSave": false,
            "scrollCollapse": true,
            "oLanguage": {
                "sLengthMenu": "_MENU_ ",
                "sInfo": "Showing <b>_START_ to _END_</b> of _TOTAL_ entries"
            },
            "bAutoWidth": true,
            "iDisplayLength": 60,
            'order' : [[6,'desc']]
        });
    }
})
document.addEventListener("turbolinks:load", function() {
    if($("body").data("controller") == "businesses") {
        $('#header-business').skdslider({
            slideSelector: '.slide',
            delay: 5000,
            animationSpeed:0,
            showNextPrev:false,
            showPlayButton:false,
            autoSlide:true,
            animationType:'fading'
        }); 

        let index = 1;

        $(".select-page-business").on("click", function(){
            let index = $(this).attr("data-index")
            
            $(".businesses-container").fadeOut(300).promise().done(function(){
            $(".pagination-businesses-one").removeClass('current_businesses_page')
            $(`.select-page-business[data-index='${index}'] div`).addClass("current_businesses_page")

            // $('html, body').animate({
            //     scrollTop: $(".businesses-content").offset().top
            // }, 300);

            $(".business-" + index).fadeIn( "linear", function() {
            });
            });

            timer.reset(6000);
        })

        if ($(".businesses-container").length > 1){
            var timer = new Timer(function() {
                index += 1;
                $(".businesses-container").fadeOut(300).promise().done(function(){
                    
                    $(".pagination-businesses-one").removeClass('current_businesses_page')
                    $(`.select-page-business[data-index='${index}'] div`).addClass("current_businesses_page")
                    $(".business-" + index).fadeIn( "linear", function() {
                    });

                    if (index >= $(".businesses-container").length) {
                        index = 0;
                    }
                });
            }, 6000);
        }

        function Timer(fn, t) {
            var timerObj = setInterval(fn, t);

            this.stop = function() {
                if (timerObj) {
                    clearInterval(timerObj);
                    timerObj = null;
                }
                return this;
            }

            // start timer using current settings (if it's not already running)
            this.start = function() {
                if (!timerObj) {
                    this.stop();
                    timerObj = setInterval(fn, t);
                }
                return this;
            }

            // start with new or original interval, stop current interval
            this.reset = function(newT = t) {
                t = newT;
                return this.stop().start();
            }
        }
    }
})


document.addEventListener("turbolinks:load", function() {

    let table_notifications = $('#table-notifications').DataTable({
        "processing": true,
        "serverSide": true,
        "ajax": {
            "url": $('#table-notifications').data('source'),
            "data": function(e) {
                //e.intervention_id = $(".intervention_id").val()
            }
        },

        "columns": [
            {"data": "category"},
            {"data": "content"},
            {"data": "agent"},
            {"data": "id",
                "render": function(data, display, record) {
                    let icon_edit = `<a href="/mccadmin/admin_notifications/${record.id}/edit" class="edit-element" title="Edit"><i class="fa-solid fa-pen"></i></a>`
                    let icon_del = `<a href='javascript:' class='delete-element' title='Delete'><i class='fa-solid fa-trash-can'></i></a>`

                    if (record.agent_id != $("#user_type").data("id")) {
                        icon_edit = `<a href="/mccadmin/admin_notifications/${record.id}" class="edit-element" title="Show"><i class="fa-solid fa-eye"></i></a>`
                        icon_del = ''
                    }

                    return `${icon_edit}
                        ${icon_del}`
                }
            }
        ],
        createdRow: function (row, data, index) {
            $(row).attr("data-element_id", data.id)
            console.log(data)
            if (data.alert == "normal") {
                $(row).addClass("notification_normal")
            }
            else if(data.alert == "critical") {
                $(row).addClass("notification_critical")
            }
            else if(data.alert == "highest")  {
                $(row).addClass("notification_highest")
            }
        },
        "pagingType": "full_numbers",
        "paging": false,
        "searching": true,
        pageLength: 25,
        order : [[0,'desc']],
        columnDefs: [
            { "width": "300px", "targets": 0, "orderable": true },
            { "width": "500px", "targets": 1 },
            { "width": "100px", "targets": 2 },
            { "width": "30px", "targets": 3 },
        ]
    });

    $("#table-notifications tbody").on("click", ".delete-element", function() {
        id = $(this).parent().parent().data("element_id")

        Swal.fire({
            title: 'Are you sure?',
            text: "You won't be able to revert this!",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes, delete it!',
            allowOutsideClick: false,
            allowEscapeKey: false,
            showLoaderOnConfirm: true,
            preConfirm: () => {
                return fetch(`/mccadmin/admin_notifications/${id}`, {
                    method: 'DELETE',
                    mode: 'cors',
                    cache: 'default',
                    headers: {
                        'Accept': 'application/json, text/plain, */*',
                        'Content-Type': 'application/x-www-form-urlencoded'
                    },
                }).then(function(response) {
                    if (response.ok) {
                        table_notifications.ajax.reload()
                    }
                    else {
                        
                    }
                })
            }
        }).then((result) => {})
    })

    $("#category").select2({
        placeholder: `Select ${$('#category').prev().text()}`
    });

})
document.addEventListener("turbolinks:load", function() {
    if(($("body").data("controller") == "mccarticles" || $("body").data("controller") == "businesses") && !window.location.href.includes('/mccadmin')) {

        $(".article-category").on("click", function() {
            let category_id = $(this).attr("data-id")

            $(".article-category").children().removeClass('selected')
            $(this).children().first().addClass('selected')

            getArticles(category_id, null)
        })

        $("#btn-back-to-top").on("click", function() {
            $("html, body").animate({ scrollTop: 0 }, "slow");
            return false;
        })

        $(".select-page").on("click", function() {
            if ($(this).attr('disabled') == undefined) {
                getArticles(null, $(this).data('page'))
            }
        })

        $("#btn-submit-comment").on("click", function() {
            if ($("#comment-text").val().trim() != '') {
                $(this).attr('disabled', true)
                $("#comment-text").attr('disabled', true)
                $(".comment-loader").removeClass("hidden")

                createComment()
            }
        })

        $(".one-post-container").on("mouseenter", function() {
            $(this).find('.comment-delete-container').removeClass("hidden")
        })

        $(".one-post-container").on("mouseleave", function() {
            $(this).find('.comment-delete-container').addClass("hidden")
        })

        $(".btn-delete-comment").on("click", function() {
            let id = $(this).data('id')

            removeComment(id)
        })

        $(".article-container").on("mouseenter", function() {
            
            $(this).find('.post-name').css('color', '#4ea5a6')
        })

        $(".article-container").on("mouseleave", function() {
            $('.post-name').css('color', '#343a40')
        })

        $(".comment-text").on("keyup ", function() {
            let total = $(this).prop('maxlength') - $(this).val().length
            $(".max-characters").text(`max characters: ${total}`)
        })
        
        $("#search-blog").on("keyup", function(e) {
            let search = $(this).val().trim()
            if (e.keyCode == 13) {
                $(this).blur()

                JsLoadingOverlay.show()

                searchBlog(search)
            }
        })

        function searchBlog(search, page) {

            if (search == "")
                search ="nil"

            let url = `/mccarticles/search-blog/${search}`

            if (page != null) {
                
                url = `/mccarticles/search-blog/${search}/${page}`
                
                // else {
                //     $(".pagination-one").removeClass('current_page')
                //     $(`.select-page[data-page='${page}'] > div`).addClass('current_page')
                //     $(`.select-page`).attr('disabled', false)
                //     $(`.select-page[data-page='${page}']`).attr('disabled', true)
    
                //     url = `/mccarticles/get-articles/all/${page}`
                // }
            }
            
            if (page == undefined) {
                page = 1
            }

            fetch(`${url}`, {
                method: 'GET',
                mode: 'cors',
                cache: 'default',
            }).then(function(response) {
                if (response.ok) {
                    let categories;
                    
                    response.json().then(function(data) {
                        JsLoadingOverlay.hide()

                        if (data.total_entries == 0) {
                            $(".posts-container").html("<h1 class='mt-5'>No results found!</h1>")
                            $(".post-pagination").addClass("hidden")
                        }
                        else {
                            $(".count_articles").text(`${data.total_entries} articles`)

                            $(".posts-container").fadeOut( "fast", function() {
                                $(".posts-container").html("")
                                
                                for (val of data.articles) {
                                    // let newObject = {};
                                    // let ccc = Object.keys(val.article_categories).forEach(function (key) {
                                    //     var value = val.article_categories[key].category;
                                    //     newObject[key] = value;
                                    // });

                                    $(".posts-container").append(`
                                        <div class='article-content mb-4'>
                                            <div class='article-container' title="${val.title}">
                                                <div class="img-hover-zoom img-hover-zoom--point-zoom text-center">
                                                    <a href='/mccarticles/${val.slug}'><img src='${val.image.url}' alt='image article' ></a>
                                                </div>
                                                <div class='post-category mt-2'>
                                                    ${val.categories}
                                                </div>
                                                <a href='/mccarticles/${val.slug}'><div class='post-name'>
                                                    ${val.title.length >= 70 ? val.title.substr(0, 70) + "..." : val.title}
                                                </div></a>
                                            </div>
                                        </div>
                                    </div>`)
                                }

                                $(".post-pagination").removeClass("hidden")
                                $('.post-pagination').html('')

                                for (let i=0 ; i< data.total_pages ; i++) {
                                    $('.post-pagination').append(`
                                        <a href='javascript:void(0)' class='select-page' data-page='${i+1}' data-search='${search}'><div class="pagination-one ${i+1 == page ? 'current_page' : ''}">${i + 1}</div></a>
                                    `)
                                }

                                $(".select-page").on("click", function() {
                                    if ($(this).attr('disabled') == undefined) {
                                        searchBlog(search, $(this).data('page'))
                                    }
                                })

                                $(".pagination-one").removeClass('current_page')
                                $(`.select-page[data-page='${page}'] > div`).addClass('current_page')
                                $(`.select-page`).attr('disabled', false)
                                $(`.select-page[data-page='${page}']`).attr('disabled', true)
                            
                            })
                        }

                        $( ".posts-container").fadeIn( "fast")

                        $('html, body').animate({
                            scrollTop: $(".total-articles").offset().top
                        }, 300);
                    })
                }
            })

        }

        function getArticles(category_id, page) {
            JsLoadingOverlay.show({
                'posts-container': 'triangle-skew-spin',
                'overlayBackgroundColor': '#fff'
            });

            let url = `/mccarticles/get-articles/${category_id}`

            if (page != null) {
                if (category_id != 'all') {
                    url = `/mccarticles/get-articles/${category_id}/${page}`
                    $(".pagination-one").removeClass('current_page')
                    $(`.select-page[data-page='${page}'] > div`).addClass('current_page')
                    $(`.select-page`).attr('disabled', false)
                    $(`.select-page[data-page='${page}']`).attr('disabled', true)
                }
                else {
                    $(".pagination-one").removeClass('current_page')
                    $(`.select-page[data-page='${page}'] > div`).addClass('current_page')
                    $(`.select-page`).attr('disabled', false)
                    $(`.select-page[data-page='${page}']`).attr('disabled', true)
    
                    url = `/mccarticles/get-articles/all/${page}`
                }
            }

            fetch(`${url}`, {
                method: 'GET',
                mode: 'cors',
                cache: 'default',
            }).then(function(response) {
                if (response.ok) {
                    let categories;
                    response.json().then(function(data) {
                        JsLoadingOverlay.hide()

                        $(".count_articles").text(`${data.total_entries} articles`)

                        $( ".posts-container" ).fadeOut( "fast", function() {
                            $(".posts-container").html("")

                            for (val of data.articles) {
                                // let newObject = {};
                                // let ccc = Object.keys(val.article_categories).forEach(function (key) {
                                //     var value = val.article_categories[key].category;
                                //     newObject[key] = value;
                                // });

                                $(".posts-container").append(`
                                    <div class='article-content mb-4'>
                                        <div class='article-container' title="${val.title}">
                                            <div class="img-hover-zoom img-hover-zoom--point-zoom text-center">
                                                <a href='/mccarticles/${val.slug}'><img src='${val.image.url}' alt='image article' ></a>
                                            </div>
                                            <div class='post-category mt-2'>
                                                ${val.categories}
                                            </div>
                                            <a href='/mccarticles/${val.slug}'><div class='post-name'>
                                                ${val.title.length >= 70 ? val.title.substr(0, 70) + "..." : val.title}
                                            </div></a>
                                        </div>
                                    </div>
                                </div>`)
                            }

                            if (page == null) {
           
                                $('.post-pagination').html('')

                                for (let i=0 ; i< data.total_pages ; i++) {
                                    $('.post-pagination').append(`
                                        <a href='javascript:void(0)' class='select-page' ${i == 0 ? 'disabled' : ''} data-page='${i+1}'><div class="pagination-one ${i == 0 ? 'current_page' : ''}">${i + 1}</div></a>
                                    `)
                                }

                                $(".select-page").on("click", function() {
                                    if ($(this).attr('disabled') == undefined) {
                                        getArticles(data.category_id, $(this).data('page'))
                                    }
                                })
                            }
                        })
                        
                        $( ".posts-container").fadeIn( "fast")

                        $('html, body').animate({
                            scrollTop: $(".total-articles").offset().top
                        }, 300);
                    })
                }
            })

        }

        function createComment() {
            let formData = new FormData();
            formData.append('article_id', $("#article-id").val());
            formData.append('comment', $("#comment-text").val());

            fetch(`/mccarticles/add-comment/${$("#article-id").val()}`, {
                method: 'POST',
                mode: 'cors',
                cache: 'default',
                body: formData,
            }).then(function(response) {
                if (response.ok) {
                    response.json().then(function(data) {

                        $("#btn-submit-comment").attr('disabled', false)
                        $("#comment-text").attr('disabled', false)
                        $(".comment-loader").addClass("hidden")
                        $("#comment-text").val('')

                        $(".post-comments-content").append(`
                            <div class='container one-post-container mt-3' data-id='${data.id}'>
                                <div class='row justify-content-md-center'>
                                    <div class='col-lg-8 col-md-12 col-12'>
                                        <div class="profile-img-wrapper m-t-5 inline">
                                            <img width="35" height="35" src="${data.member.avatar.url == null ? '/assets/default-user.png' : data.member.avatar.url}" alt="avatar">
                                        </div>
                                        <div class="inline m-l-10">
                                            <p class="small comment-name m-t-5 mb-0">${data.member.first_name} ${data.member.last_name}</p>
                                            <p class='small hint-text  comment-date mb-0'>${moment.utc(data.created_at).format("MMM DD, YYYY | hh:mm: a")}</p>
                                        </div>
                                        <div class='pull-right comment-delete-container hidden'>
                                            <a href='javascript:void(0)' class='btn-delete-comment' data-id='${data.id}' title='Remove your comment'><i class="fa-solid fa-trash"></i></a>
                                        </div>
                                    </div>
                                </div>
                                <div class='row justify-content-md-center mt-0'>
                                    <div class='col-lg-1 col-md-1 col-1'>
                                    </div>
                                    <div class='col-lg-8 col-md-12 col-12' style='overflow-wrap: break-word;'>
                                        ${data.comment.replaceAll("\r\n", "<br>")}
                                    </div>
                                </div>
                            </div>
                        `)

                        $(".one-post-container").on("mouseenter", function() {
                            $(this).find('.comment-delete-container').removeClass("hidden")
                        })
                
                        $(".one-post-container").on("mouseleave", function() {
                            $(this).find('.comment-delete-container').addClass("hidden")
                        })

                        $(".max-characters").text(`max characters: ${$('.comment-text').prop('maxlength')}`)

                        $(".btn-delete-comment").on("click", function() {
                            let id = $(this).data('id')
                
                            removeComment(id)
                        })
                    }) 
                }
            })
        }

        function removeComment(id) {
            Swal.fire({
                text: "Would you really want to remove your comment ?",
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#1f6866',
                cancelButtonColor: '#6e7881',
                confirmButtonText: 'Yes, delete it!',
                allowOutsideClick: false,
                allowEscapeKey: false,
                showLoaderOnConfirm: true,
                showClass: {
                    popup: 'animated fadeInDown faster',
                  },
                  hideClass: {
                    popup: 'animate__animated animate__fadeOutUp'
                  },
                preConfirm: () => {
                    return fetch(`/mccarticles/remove-comment/${id}`, {
                        method: 'DELETE',
                        mode: 'cors',
                        cache: 'default',
                    }).then(function(response) {
                        if (response.ok) {
                            $(`.one-post-container[data-id='${id}']`).remove()
                        }
                    })
                }
            })
        }
    }
})
document.addEventListener("turbolinks:load", function() {
    if($("body").data("controller") == "email_notifs") {

        document.addEventListener("turbolinks:before-cache", function() {
            if (table != null) {
                table.destroy();
            }
        })

        let table = $('#table-email_notifs').DataTable({
            "bPaginate": true,
            "bFilter": true,
            "bInfo": false,
            "bLengthChange": false,
            "pageLength": 50,
            "searching": true,
            "destroy": true,
            "stateSave": false,
            "scrollCollapse": true,
            "oLanguage": {
                "sLengthMenu": "_MENU_ ",
                "sInfo": "Showing <b>_START_ to _END_</b> of _TOTAL_ entries"
            },
            "bAutoWidth": false,
            'order' : [[0,'desc']]
        });
        $("#table-email_notifs_wrapper > div > .col-sm-6:nth-child(1)").addClass("content-left pt-2")

    }
})

document.addEventListener("turbolinks:load", function() {
    if($("body").data("controller") == "users") {
        let method = 'create';

        document.addEventListener("turbolinks:before-cache", function() {
            if (table != null) {
                table.destroy();
            }

            $.fn.dataTable.ext.search.pop();
        })

        table = $('#table-users').DataTable({
            'initComplete': function(settings){
                var api = this.api();
       
                api.cells(
                   api.rows(function(idx, data, node){
                      return ($(node).data('related')) ? true : false;
                   }).indexes(),
                   0
                ).checkboxes.disable();
            },
            'select': {
                'style': 'multi',
                "selector": 'td:first-child'
            },
            "bPaginate": true,
            "bFilter": true,
            "bInfo": false,
            "bLengthChange": false,
            "searching": true,
            "destroy": true,
            "stateSave": false,
            "scrollCollapse": true,
            "oLanguage": {
                "sLengthMenu": "_MENU_ ",
                "sInfo": "Showing <b>_START_ to _END_</b> of _TOTAL_ entries"
            },
            "bAutoWidth": false,
            'order' : [[0,'desc']]
        });
        $("#table-users_wrapper > div > .col-sm-6:nth-child(1)").addClass("content-left pt-2")
        $("#table-users_wrapper > .row > div:first").append("<div><span>" +
            "<div class='form-check form-check-inline pr-0'>" + 
                "<input type='radio' name='user_status' id='user_status_all' value='all' >" +
                "<label for='user_status_all'>" + 
                    "All " +
                "</label>" +
            "</div>" + 
            "<div class='form-check form-check-inline pr-0'>" + 
                "<input type='radio' name='user_status' id='user_status_active' value='active' checked>" +
                "<label for='user_status_active'>" + 
                    "Active " +
                "</label>" +
            "</div>" + 
            "<div class='form-check form-check-inline '>" + 
             "<input type='radio' name='user_status' id='user_status_inactive' value='inactive'>" +
                "<label for='user_status_inactive'>" + 
                    "Inactive " +
                "</label>" +
            "</div>" + 
        "</span></div>")

        $("#user_password").on("change", function() {
            if($(this).val() != '') {
                $("#user_confirmation_password").attr('required', true)
            }
            else {
                $("#user_confirmation_password").attr('required', false)
            }
        })

        $("#btn-save-user").on("click", function() {
            $("#form-user").validate()
            $('#form-user').on("submit", function(e) {
                e.preventDefault()
            })

            
            if ($("#form-user").valid()) {
                // if ($("#user_passord").val() != $("#user_confirmation_password").val()) {
                //     return
                // }

                if ($("#user-method").val() == 'create')
                    create()
                else if ($("#user-method").val() == 'update')
                    update()
            }
        })

        function create() {

            let formData = new FormData();
            let status = 'active'
            if ($("#user-status").prop('checked') === false)
                status = 'inactive'
            let receive_notif = true
            if ($("#user-receive_notif").prop('checked') === false)
                receive_notif = false

            formData.append('first_name', $("#user_first_name").val());
            formData.append('last_name', $("#user_last_name").val());
            formData.append('email', $("#user_email").val());
            formData.append('password', $("#user_password").val());
            formData.append('role', $("select[name='user[role]']").val())
            formData.append('status', status)
            formData.append('receive_notif', receive_notif)

            $("#form-user input, #form-user textarea, #form-user select, #form-user button").attr("disabled", true)
            $(".loading").removeClass("hidden")

            fetch(`/mccadmin/users/create-user`, {
                method: 'POST',
                mode: 'cors',
                cache: 'default',
                body: formData,
            }).then(function(response) {
                $("#form-user input, #form-user textarea, #form-user select, #form-user button").attr("disabled", true)
                $(".loading").removeClass("hidden")

                window.location = '/mccadmin/users'
            })

        }

        $(".delete-element").on("click", function() {
            id = $(this).parent().parent().data("element_id")

            Swal.fire({
                title: 'Are you sure?',
                text: "You won't be able to revert this!",
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Yes, delete it!',
                allowOutsideClick: false,
                allowEscapeKey: false,
                showLoaderOnConfirm: true,
                preConfirm: () => {
                    return fetch(`/mccadmin/users/delete-user/${id}`, {
                        method: 'DELETE',
                        mode: 'cors',
                        cache: 'default',
                        headers: {
                            'Accept': 'application/json, text/plain, */*',
                            'Content-Type': 'application/x-www-form-urlencoded'
                        },
                    }).then(function(response) {
                        if (response.ok) {
                            table.row($(`tr[data-element_id='${id}']`)).remove().draw();
                        }
                        else {
                            
                        }
                    })
                }
            }).then((result) => {})
        })

        function update() {

            let formData = new FormData();
            let status = 'active'
            if ($("#user-status").prop('checked') === false)
                status = 'inactive'
            let receive_notif = true
            if ($("#user-receive_notif").prop('checked') === false)
                receive_notif = false

            formData.append('first_name', $("#user_first_name").val());
            formData.append('last_name', $("#user_last_name").val());
            formData.append('email', $("#user_email").val());
            formData.append('password', $("#user_password").val());
            formData.append('role', $("select[name='user[role]']").val())
            formData.append('status', status)
            formData.append('receive_notif', receive_notif)

            $("#form-user input, #form-user textarea, #form-user select, #form-user button").attr("disabled", true)
            $(".loading").removeClass("hidden")

            fetch(`/mccadmin/users/update-user/${$("#user-id").val()}`, {
                method: 'PUT',
                mode: 'cors',
                cache: 'default',
                body: formData,
            }).then(function(response) {
                $("#form-user input, #form-user textarea, #form-user select, #form-user button").attr("disabled", true)
                $(".loading").removeClass("hidden")

                window.location = '/mccadmin/users'
            })

        }

        function refresh_selected_elements() {
            rows_selected = table.column(0).checkboxes.selected();

            if (rows_selected.length == 0) {
                $(".total-selected-content").addClass("hidden")
                $(".total-selected-content").css("marginBottom", 0)
                $(".total-selected-content").css("opacity", 0)
            }
            else {
                $(".total-selected-content").removeClass("hidden")
                $(".total-selected-content").animate({
                    marginBottom: 10,
                    opacity: 1
                }, 100, "linear",  function() {
                });
            }
          
            // $.each(rows_selected, function(index, rowId){
            // });
          
            let txt = rows_selected.length > 1 ? `${rows_selected.length} elements selected` : `${rows_selected.length} element selected`
            $(".total-selected").text(txt)
          
            return rows_selected
        }

        // toggle active/inactive
        $("input[name='user_status']").on("change", function() {
            filter()
        })

        function filter() {
            if($("input[name='user_status']:checked").val() != 'all'){
                $("#table-users").dataTable.ext.search.pop();
                $("#table-users").dataTable.ext.search.push(
                    function(settings, data, dataIndex) {
                       return $(table.row(dataIndex).node()).attr('data-status') == $("input[name='user_status']:checked").val();
                    }
                );
            }
            else {
                $("#table-cities").dataTable.ext.search.pop();
            }

            table.draw();
        }

        filter()

        
    }
})
document.addEventListener("turbolinks:load", function() {
    if($("body").data("controller") == "payment_services") {

        // *** EVENTS
        $('#btn-save').on("click", function(e) {
            $("#form-service").validate()
            $('#form-service').on("submit", function(e) {
                e.preventDefault()
            })

            if ($("#form-service").valid()) {
      
                document.getElementById("form-service").submit()

                $("#form-service input, #form-service button").attr("disabled", true)
                $(".loader-verify-service").removeClass("hidden")
                $(".alert-payment_service").addClass("hidden")
                
            }
        })

        $('#btn-verify').on("click", function(e) {
            $("#form-service").validate()
            $('#form-service').on("submit", function(e) {
                e.preventDefault()
            })

            if ($("#form-service").valid()) {
                $("#form-service input, #form-service button").attr("disabled", true)
                $(".loader-verify-service").removeClass("hidden")
                $(".alert-payment_service").addClass("hidden")

                verify_service_auth()
            }
        })

        // *** FUNCTIONS
        function verify_service_auth() {
            let formData = new FormData();
            formData.append('publishable_key', $("#publishable_key").val());
            formData.append('secret_key', $("#secret_key").val());

            let stripe = Stripe($("#publishable_key").val());

            setTimeout( ()=>{
                stripe.createToken('pii', {personal_id_number: 'test'})
                    .then( result =>{
                        if( result.token ) {
                            fetch(`/mccadmin/payment_services/verify-service-auth`, {
                                method: 'POST',
                                mode: 'cors',
                                cache: 'default',
                                body: formData,
                            }).then(function(response) {
                                $(".alert-payment_service").removeClass("hidden") 
                                if (response.ok) {
                                    response.json().then(function(data) {
                                        $(".alert-payment_service").removeClass("alert-danger")
                                        $(".alert-payment_service").addClass("alert-success")
                                        $(".alert-payment_service").text(data.info)
                                    })
                                }
                                else {
                                    response.json().then(function(data) {
                                        $(".alert-payment_service").removeClass("alert-success")
                                        $(".alert-payment_service").addClass("alert-danger")
                                        $(".alert-payment_service").text(data)
                                    })
                                }

                                $("#form-service input, #form-service button").attr("disabled", false)
                                $(".loader-verify-service").addClass("hidden")
                            })
                        }
                        else {
                            $(".alert-payment_service").removeClass("hidden") 
                            $(".alert-payment_service").removeClass("alert-success")
                            $(".alert-payment_service").addClass("alert-danger")
                            $(".alert-payment_service").text(result.error.message)

                            $("#form-service input, #form-service button").attr("disabled", false)
                            $(".loader-verify-service").addClass("hidden")
                        }
                    })
            }, 300 )
        }
    }
})
document.addEventListener("turbolinks:load", function() {
    if($("body").data("controller") == "membership_plans") {
        let element_id;
        let plans_id = []
        let plans_id_removed = []

        document.addEventListener("turbolinks:before-cache", function() {
            if (table != null) {
                table.destroy();
            }

            $.fn.dataTable.ext.search.pop();
        })

        table = $('#table-membership_plans').DataTable({
            "bPaginate": true,
            "bFilter": true,
            "bInfo": false,
            "bLengthChange": false,
            "searching": false,
            "destroy": true,
            "stateSave": false,
            "scrollCollapse": true,
            "oLanguage": {
                "sLengthMenu": "_MENU_ ",
                "sInfo": "Showing <b>_START_ to _END_</b> of _TOTAL_ entries"
            },
            "bAutoWidth": false,
            'order' : [[0,'desc']]
        });
        $("#table-membership_plan_wrapper > div > .col-sm-6:nth-child(1)").addClass("content-left pt-2")

        table_archived = $('#table-membership_plans_archived').DataTable({
            "bPaginate": true,
            "bFilter": true,
            "bInfo": false,
            "bLengthChange": false,
            "searching": false,
            "destroy": true,
            "stateSave": false,
            "scrollCollapse": true,
            "oLanguage": {
                "sLengthMenu": "_MENU_ ",
                "sInfo": "Showing <b>_START_ to _END_</b> of _TOTAL_ entries"
            },
            "bAutoWidth": false,
            'order' : [[0,'desc']]
        });
        $("#table-membership_plan_archived_wrapper > div > .col-sm-6:nth-child(1)").addClass("content-left pt-2")
        
        FilePond.registerPlugin(FilePondPluginImagePreview, FilePondPluginFileValidateSize, FilePondPluginFileValidateType);
        
        const pond_image = FilePond.create(document.querySelector('#membership_plan-image'), {
            acceptedFileTypes: ['image/*'],
            labelIdle: "Drag & Drop your image or <span class='filepond--label-action'> Browse </span>",
            imagePreviewMinWidth: 800,
            allowProcess: false,
            instantUpload: false,
            allowReorder: true,
            name: 'membership_plan[image]',
            storeAsFile: true,
            credits: {label: "", url: ""} ,
            maxFileSize: 800000,
        });

        const lightbox = GLightbox({
            touchNavigation: true,
            loop: false
        });


        $('.custom-tag-input').tagsinput();
        
        // table_plan_ids = $('#table-membership_plans_api_ids').DataTable({
        //     "bPaginate": false,
        //     "bFilter": true,
        //     "bInfo": false,
        //     "bLengthChange": false,
        //     "searching": false,
        //     "destroy": true,
        //     "stateSave": false,
        //     "scrollCollapse": true,
        //     "oLanguage": {
        //         "sLengthMenu": "_MENU_ ",
        //         "sInfo": "Showing <b>_START_ to _END_</b> of _TOTAL_ entries"
        //     },
        //     "bAutoWidth": false,
        //     'order' : [[0,'desc']]
        // });

        // *** EVENTS

        $('#btn-save').on("click", function(e) {

            $("#form-membership_plan").validate()
            $('#form-membership_plan').on("submit", function(e) {
                e.preventDefault()
            })

            if ($("#membership_plan-access_type").val() == "limited_time") {
                $("input[name='membership_plan[access_limited_for]']").attr("required", true)
                $("input[name='membership_plan[access_limited]']").attr("required", true)
            }
            else {
                $("input[name='membership_plan[access_limited_for]']").attr("required", false)
                $("input[name='membership_plan[access_limited]']").attr("required", false)
            }

            if ($("#membership_plan-access_type").val() == "date_range") {
                $("input[name='membership_plan[access_range_start]']").attr("required", true)
                $("input[name='membership_plan[access_range_expire]']").attr("required", true)
            }
            else {
                $("input[name='membership_plan[access_range_start]']").attr("required", false)
                $("input[name='membership_plan[access_range_expire]']").attr("required", false)
            }

            if ($("#membership_plan-access_type").val() == "recurring") {
                $("input[name='membership_plan[access_recurring_cycle]']").attr("required", true)
            }
            else {
                $("input[name='membership_plan[access_recurring_cycle]']").attr("required", false)
            }

            if ($("#form-membership_plan").valid()) {
                document.getElementById("form-membership_plan").submit()
            }
        })

        $("#membership_plan-access_limited, #membership_plan-access_recurring").select2({
            minimumResultsForSearch: -1
        });

        $('.autonumeric').autoNumeric('init');
        
        $("input[name='membership_plan[access_limited_for]'], input[name='membership_plan[access_recurring_cycle]']").on("blur", function(e) {
            if ($(this).val() < 1) {
                $(this).val(1)
            }
        })

        $("input[name='membership_plan[access_range_start]'], input[name='membership_plan[access_range_expire]']").datepicker({
            format: 'dd-mm-yyyy',
            autoclose: true,
            multidate: false
        })

        $("input[name='membership_plan[access_range_start]']").on("change", function(e) {
         
        })
        $("input[name='membership_plan[access_range_expire]']").on("change", function(e) {
            console.log($(this).val() <= $("input[name='membership_plan[access_range_start]']").val())
        })

        $("#membership_plan-access_type").on("select2:select", function(e) {
            let index = $(this)[0].selectedIndex
            $(".access_type-container").addClass("hidden")
            $(`.access_type-container:nth(${index})`).removeClass("hidden")
        })

        $(".delete-element, .archive-element").on("click", function() {
            element_id = $(this).parent().parent().parent().attr("data-element_id")
            JsLoadingOverlay.show({'table-membership_plans': 'triangle-skew-spin'});

            hasSubscriptions(element_id)
        })

        $(".unarchive-element").on("click", function() {
            element_id = $(this).parent().parent().parent().attr("data-element_id")

            unarchivePlan(element_id)            
        })

        $("#btn-add-plan_id").on("click", function() {
            $('#modal-plan_id').modal('show')
        })
        
        $("#btn-save-plan").on("click", function() {
            addPlan()
        })

        // *** FUNCTIONS

        function hasSubscriptions(element_id) {
            fetch(`/mccadmin/membership_plans/has-subscriptions/${element_id}`, {
                method: 'GET',
                mode: 'cors',
                cache: 'default',
                headers: {
                    'Accept': 'application/json, text/plain, */*',
                    'Content-Type': 'application/x-www-form-urlencoded'
                },
            }).then(function(response) {
                if (response.ok) {
                    response.json().then(function(data) {
                        JsLoadingOverlay.hide()
                        destroy(element_id, null, data.has_subscriptions)
                    })
                }
                else {}
            })
        }

        function addPlan() {
            $("#form-plan_id").validate()
            $('#form-plan_id').on("submit", function(e) {
                e.preventDefault()
            })

            if ($("#form-plan_id").valid()) {
                let find = false
                $(".plans_id_error").addClass("hidden")
                $(".plans_id_error").text("")

                $.each($(".membership_plan_service"), function(index, rowId){
                    if ($(rowId).val() == $("#plan-service option:selected").val()) {
                        find = true
                    }
                })

                if(!find) {
                    $(`.plans_id_content`).append(`
                        <div class="row plan_id_content-${$("#plan-id").val()}">
                            <div class='col-4'>
                                <div class="form-group form-group-default">
                                    <label>Service</label>
                                    <input type='text' class="form-control membership_plan_service" name='membership_plan[service][]' value='${$("#plan-service option:selected").val()}' readonly>
                                </div>
                            </div>
                            <div class='col-6'>
                                <div class="form-group form-group-default">
                                    <label>Plan ID</label>
                                    <input type='text' class="form-control" name='membership_plan[plan_id][]' value='${$("#plan-id").val()}'>
                                </div>
                            </div>
                            <div class='col-1'>
                                <a href="javascript:" class="delete-plan" title="Delete"><i class="fa-solid fa-trash-can"></i></a>
                            </div>
                        </div>
                    `)

                    attachDeleteEvent()

                    $('#modal-plan_id').modal('hide')
                    $("#plan-service").val(1).trigger("change")
                    $("#plan-id").val("")
                }
                else {
                    $(".plans_id_error").removeClass("hidden")
                    $(".plans_id_error").text("Payment Service already added!")
                }
            }
        }

        function attachDeleteEvent() {
            $(".delete-plan").on("click", function(e) {
                Swal.fire({
                    title: 'Remove this?',
                    icon: 'warning',
                    showCancelButton: true,
                    confirmButtonColor: '#3085d6',
                    cancelButtonColor: '#d33',
                    confirmButtonText: 'Yes, delete it!'
                }).then((result) => {
                    if (result.isConfirmed) {
                        $(this).parent().parent().remove()
                        plans_id_removed.push($(this).parent().parent().data("plan_id"))

                        $("#plan_id_removed").val(plans_id_removed.join(","))
                    }
                })
            })
        }

        function destroy(element_id, elements_id, has_subscriptions) {
            let title = "Delete Membership plan"
            let text = "Are you sure you want to delete this Membership plan? This can't be undone."
            let buttonText = "Delete membership plan"
            let url = `/mccadmin/membership_plans/${element_id}`
            let method = 'DELETE'
            
            const formData = new FormData();

            if (has_subscriptions) {
                title = "Archive Membership plan"
                text = "Archiving will hide this Membership plan from new subscriptions. Are you sure you want to archive this?"
                buttonText = "Archive membership plan"
                url = `/mccadmin/membership_plans/archive/${element_id}`
                method = 'PUT'

                formData.append('archive', true);
            }

            Swal.fire({
                title: title,
                text: text,
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: buttonText,
                allowOutsideClick: false,
                allowEscapeKey: false,
                showLoaderOnConfirm: true,
                preConfirm: () => {
                    return fetch(url, {
                        method: method,
                        mode: 'cors',
                        cache: 'default',
                        body: formData
                    }).then(function(response) {
                        if (response.ok) {
                            if (!has_subscriptions) {
                                if ($(".delete-element").data("redirect") == true) {
                                    window.location = "/mccadmin/cities/posts/"
                                }
                                else
                                    table.row($(`tr[data-element_id='${element_id}']`)).remove().draw();
                            }
                            else {
                                response.json().then(function(data) {
                                    table.row($(`tr[data-element_id='${element_id}']`)).remove().draw();

                                    let newRow = table_archived.row.add( [
                                        data.id,
                                        `${data.name}<span class='label badge-default'>Archived</span>`,
                                        data.access_type_decorate,
                                        data.amount,
                                        data.total_members,
                                        `<div class='element-actions'>
                                            <a href="javascript:" class="unarchive-element" title="Unarchive membership plan"><i class="fas fa-undo-alt"></i></a>
                                        </div>`
                                    ] ).draw( false ).node();
                
                                    $(newRow).attr("data-element_id", data.id);

                                    $(newRow).find(".unarchive-element").on("click", function() {
                                        unarchivePlan(data.id)
                                    })
                                })
                            }
                        }
                        else {
                            if (!has_subscriptions) {
                                response.json().then(function(data) {
                                    $(`.table tr[data-element_id='${element_id}'] td:nth-child(7) .element-actions`).removeClass("hidden");
                                    table.cells(`tr[data-element_id='${element_id}']`, 0).checkboxes.enable()
                                    table.columns().checkboxes.deselectAll()
                                    
                                    Swal.fire({
                                        icon: 'error',
                                        text: data.error,
                                    })
                                })
                            }
                        }
                    })
                }
            }).then((result) => {})
        }

        function unarchivePlan(element_id) {

            const formData = new FormData();
            formData.append('archive', false);

            Swal.fire({
                title: "Unarchive Membershi plan",
                text: "Are you sure to unarchive this Membership plan ?",
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: "Unarchive membership plan",
                allowOutsideClick: false,
                allowEscapeKey: false,
                showLoaderOnConfirm: true,
                preConfirm: () => {
                    return fetch(`/mccadmin/membership_plans/archive/${element_id}`, {
                        method: 'PUT',
                        mode: 'cors',
                        cache: 'default',
                        body: formData
                    }).then(function(response) {
                        if (response.ok) {
                            response.json().then(function(data) {
                                table_archived.row($(`tr[data-element_id='${element_id}']`)).remove().draw();

                                let newRow = table.row.add( [
                                    data.id,
                                    `<a href="/mccadmin/membership_plans/${data.id}/edit">${data.name}</a>`,
                                    data.access_type_decorate,
                                    data.amount,
                                    data.total_members,
                                    `<div class='element-actions'><a href="/mccadmin/membership_plans/${data.id}/edit" class="edit-element" title="Edit"><i class="fa-solid fa-pen"></i></a>
                                        <a href="javascript:" class="archive-element" title="Archive"><i class="fas fa-undo-alt"></i></a>
                                    </div>`
                                ] ).draw( false ).node();
            
                                $(newRow).attr("data-element_id", data.id);

                                $(newRow).find(".archive-element").on("click", function() {
                                    hasSubscriptions(data.id)
                                })
                            })
                        }
                        else {
                        }
                    })
                }
            }).then((result) => {})
                    
        }

        attachDeleteEvent()
    }
})
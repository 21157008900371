document.addEventListener("turbolinks:load", function() {
    if($("body").data("controller") == "registrations") {

        $('#btn-signup').on("click", function(e) {
            $("#member_recommended_by_member_name").val($("#member_recommended_by_member_name").val().trim())
            $("#member_recommended_by_member_phone").val($("#member_recommended_by_member_phone").val().trim())

            $("#form-signup").validate()
            $('#form-signup').on("submit", function(e) {
                e.preventDefault()
            })

            if ($("#form-signup").valid()) {
                document.getElementById("form-signup").submit()
            }
        })

        $("#member_country").select2({
            placeholder: "Select a state",
            allowClear: false
        });

        $("#member-birthday").datepicker({
            format: 'dd-mm-yyyy',
            autoclose: true,
            multidate: false
        })

    }
})
document.addEventListener("turbolinks:load", function() {
    if($("body").data("controller") == "profiles") {
        let pm_id;

        table_orders = $('#table-payment_history').DataTable({
            "bPaginate": false,
            "bFilter": true,
            "bInfo": false,
            "bLengthChange": false,
            "searching": false,
            "destroy": true,
            "stateSave": false,
            "scrollCollapse": true,
            "oLanguage": {
                "sLengthMenu": "_MENU_ ",
                "sInfo": "Showing <b>_START_ to _END_</b> of _TOTAL_ entries"
            },
            "bAutoWidth": false,
            'order' : [[0,'desc']]
        });

        let table_cc = $('#table-cc').DataTable({
            "bPaginate": false,
            "bFilter": false,
            "bInfo": false,
            "bLengthChange": false,
            "searching": false,
            "destroy": true,
            "stateSave": false,
            "scrollCollapse": true,
            "oLanguage": {
                "sLengthMenu": "_MENU_ ",
                "sInfo": "Showing <b>_START_ to _END_</b> of _TOTAL_ entries"
            },
            "bAutoWidth": false,
            'order' : [[0,'desc']]
        });
        
        $("#member-gender").select2({
            placeholder: "Select your gender",
            allowClear: false,
            minimumResultsForSearch: -1
        });

        $("#member-countries").select2({
            placeholder: "Select your country",
            allowClear: false,
            minimumResultsForSearch: -1
        });

        $("#member-birthday").datepicker({
            format: 'dd-mm-yyyy',
            autoclose: true,
            multidate: false,
            orientation: 'bottom'
        })

        $(".btn-select-profile").on("click", function() {
            let section = $(this).data('section')

            $('.section').addClass("hidden")
            $(`.${section}`).removeClass('hidden') 
            
        })

        FilePond.registerPlugin(FilePondPluginImagePreview, FilePondPluginFileValidateSize, FilePondPluginFileValidateType, FilePondPluginImageTransform, FilePondPluginImageResize, FilePondPluginFileRename, FilePondPluginImageCrop);

        var avatar = FilePond.create(document.querySelector('#avatar'), {
            acceptedFileTypes: ['image/*'],
            labelIdle: "Drag & Drop your avatar here or <span class='filepond--label-action'> Browse </span>",
            allowProcess: true,
            instantUpload: false,
            imageResizeMode: 'cover',
            allowImageResize: true,
            imageResizeTargetWidth: 128,
            imageResizeTargetHeight: 128,
            name: 'member[avatar]',
            storeAsFile: true,
            credits: {label: "", url: ""} ,
            maxFileSize: 5000000,
            allowImageCrop: true,
            imageCropAspectRatio: '1:1'
        });

        avatar.setOptions({
            fileRenameFunction: (file) => {
                return `my_avatar${file.extension}`;
            },
        });
        
        avatar.addFile($(".avatar_url").val()).then((file) => {
            // File has been added
        })
        .catch((error) => {
            console.log(error)
        });

        $("#btn-save-profile").on("click", function() {
            $(".alert-profile").addClass('hidden')

            $("#form-member").validate()
            $('#form-member').on("submit", function(e) {
                e.preventDefault()
            })

            if ($("input[name='member[password]']").val() != "") {
                if ($("input[name='member[password_confirmation]']").val() != $("input[name='member[password]']").val()) {
                    $(".alert-profile").removeClass('hidden')
                    $(".alert-profile span").text("Confirmation Password did not match!")

                    return
                }

            }

            if ($("#form-member").valid()) {
                document.getElementById("form-member").submit()
            }
        })
        
        $("#member-recommended_by_member").on("change", function() {

            $(".recommended-container").toggleClass('hidden')

            $("#member_recommended_by_member_name").attr('required', $(this).prop("checked"))
            $("#member_recommended_by_member_phone").attr('required', $(this).prop("checked"))
        })

        $("#btn-cancel-subscription").on("click", function() {
            subscribtion_id = $(this).data('subscription_id')

            Swal.fire({
                title: 'Cancel subscription',
                text: "Are you really want to cancel your subscription ?",
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Cancel subscription',
                allowOutsideClick: false,
                allowEscapeKey: false,
                showLoaderOnConfirm: true,
                preConfirm: () => {
                    return fetch(`/members/members/subscription/cancel/${subscribtion_id}`, {
                        method: 'DELETE',
                        mode: 'cors',
                        cache: 'default',
                        headers: {
                            'Accept': 'application/json, text/plain, */*',
                            'Content-Type': 'application/x-www-form-urlencoded'
                        },
                    }).then(function(response) {
                        if (response.ok) {
                            $('.expired_info').before('<span class="badge badge-danger">Canceled</span>')
                            $(".expired_info").remove()

                            $("#btn-cancel-subscription").remove()
                        }
                    })
                }
            })
        })

        $('#modal-edit_credit_card').on('hide.bs.modal', function(e) { 
            pm_id = null

            $("#edit-member-credit_card").val("");
            $("#edit-member-credit_card_date").val("");
            $("#edit-member-owner").val("");
            $("#edit-member-zip_code").val("");
            $("#edit-member-cvc").val("");

            $(".cc-edit_loader").addClass('hidden')

            $("#form-edit_credit_card input, #form-edit_credit_card button").attr("disabled", false)

            $(".alert-edit_cc-error").text("")
            $(".alert-edit_cc-error").addClass('hidden')

        })

        $("#btn-save-cc").on("click", function(e){
            $("#form-cc").validate()

            $('#form-cc').on("submit", function(e) {
                e.preventDefault()
            })

            if ($("#form-cc").valid()) {

                createCreditCard()
            }
        })

        $("#btn-edit_cc").on("click", function() {

            $(".alert-edit_cc-error").text("")
            $(".alert-edit_cc-error").addClass('hidden')

            $("#form-edit_credit_card").validate()
            $('#form-edit_credit_card').on("submit", function(e) {
                e.preventDefault()
            })

            if ($("#form-edit_credit_card").valid()) {
                $("#form-edit_credit_card input, #form-edit_credit_card button").attr("disabled", true)

                $(".cc-edit_loader").removeClass('hidden')

                updateCreditCard()
            }
        })

        $("#modal-edit_credit_card").on("shown.bs.modal", function() {
            $(".alert-cc").addClass('hidden')
        })
        
        async function getCreditCards() {
            $("#table-cc .dataTables_empty").html("<img src='/assets/loading.svg' width='38'>")

            await fetch(`/profiles/get-credit-cards`, {
                method: 'GET',
                mode: 'cors',
                cache: 'default',
                headers: {
                    'Accept': 'application/json, text/plain, */*',
                    'Content-Type': 'application/x-www-form-urlencoded'
                },
            }).then(function(data) {
              
                data.json().then(function(data) {
                    table_cc.rows().clear().draw();

                    console.log(data)
                    if (data != null) {
                        //jQuery.each(data, function (i, val) {
                            let newRow = table_cc.row.add( [
                                `${data.card.brand.charAt(0).toUpperCase() + data.card.brand.slice(1)} **** ${data.card.last4}`,
                                data.billing_details.name,
                                `${data.card.exp_month}/${data.card.exp_year}`,
                                data.billing_details.address.postal_code,
                                `<a href="javascript:" class="edit-element" data-id="${data.id}" title="Edit"><i class="fa-solid fa-pen"></i></a>`
                            ] ).draw( false ).node();

                        //})

                        $('.edit-element').on("click", function (e) {
                            pm_id = $(e.currentTarget).data('id')
                            
                            $('#modal-edit_credit_card').modal({
                                backdrop: 'static',
                                keyboard: false  
                            })

                        });
                    }
                    else {
                        $(".new_credit_card_container").removeClass("hidden")
                    }
                })
            })
        }

        function createCreditCard() {
                    
            $(".alert-cc").addClass('visually-hidden')
            $(".alert-cc-error").addClass('visually-hidden')

            $(".cc-create_loader").removeClass("hidden")
            $(".alert-cc-error").addClass('hidden')

            $("#btn-save-cc").attr("disabled", true)
            $("#member-credit_card").attr("disabled", true)
            
            $.ajax({
                url: `/members/members/cc`,
                type: 'POST',
                asynch: true,
                data: {
                    credit_card_number: $("#new-member-credit_card").val(),
                    cvc: $("#new-member-cvc").val(),
                    card_date: $("#new-member-credit_card_date").val(),
                    owner: $("#new-member-owner").val(),
                    zip_code: $("#new-member-zip_code").val()
                },
                dataType: "json",
                success: function(data, status, xhr) {
                    
                    $("#btn-save-cc").attr("disabled", false)
                    $("#member-credit_card").attr("disabled", false)
                    
                    $(".cc-create_loader").addClass("hidden")
                    $(".alert-cc").removeClass('hidden')

                    // $(".cc-content").append(`<tr class=''>` + 
                    //     `<td>${$("#member-credit_card").val()}</td>` + 
                    //     `<td>${$("#member-owner").val()}</td>` + 
                    //     `<td>${$("#member-credit_card_date").val()}</td>` + 
                    //     `<td>${$("#member-zip_code").val()}</td>` + 
                    //     `<td><a href='javascript:void(0)' class="btn-delete_cc btn-delete_cc-${response.id}" data-id_cc='${response.id}' title='Delete credit card'><i class="icon-delete fas fa-window-close"></i></a></td>` + 
                    // "</tr>")

                    let newRow = table_cc.row.add( [
                        `${data.card.brand.charAt(0).toUpperCase() + data.card.brand.slice(1)} **** ${data.card.last4}`,
                        data.billing_details.name,
                        `${data.card.exp_month}/${data.card.exp_year}`,
                        data.billing_details.address.postal_code,
                        `<a href="javascript:" class="edit-element" data-id="${data.id}" title="Edit"><i class="fa-solid fa-pen"></i></a>`
                    ] ).draw( false ).node();
                    
                    $('.edit-element').on("click", function (e) {
                        
                        pm_id = $(e.currentTarget).data('id')
                        $('#modal-edit_credit_card').modal({
                            backdrop: 'static',
                            keyboard: false  
                        })

                    });
                    
                    $(".alert-cc").removeClass('hidden')
                    
                    $("#new-member-credit_card").val("")
                    $("#new-member-credit_card_date").val("")
                    $("#new-member-owner").val("")
                    $("#new-member-zip_code").val("")

                    $(".new_credit_card_container").remove()
                    
                },
                error: function(response, status, xhr) {
                    console.log(response)
                    $("#btn-save-cc").attr("disabled", false)
                    $("#member-credit_card").attr("disabled", false)

                    $(".cc-create_loader").addClass("hidden")
                    $(".alert-cc-error").removeClass('hidden')

                    $(".alert-cc-error ul").html(`<li>${response.responseJSON.errors}</li>`)
                }
            })
        }

        function updateCreditCard(id) {
            let formData = new FormData();

            formData.append('card_number', $("#edit-member-credit_card").val());
            formData.append('expire', $("#edit-member-credit_card_date").val());
            formData.append('owner', $("#edit-member-owner").val());
            formData.append('postal_code', $("#edit-member-zip_code").val());
            formData.append('cvc', $("#edit-member-cvc").val());
            formData.append('pm_id', pm_id);
            
            $("#form-edit_credit_card input").attr("disabled", true)
            $("#btn-edit_cc").attr("disabled", true)

            $.ajax({
                url: `/profiles/update-credit-cards`,
                type: "PUT",
                data: {
                    card_number: $("#edit-member-credit_card").val(),
                    expire: $("#edit-member-credit_card_date").val(),
                    owner: $("#edit-member-owner").val(),
                    postal_code: $("#edit-member-zip_code").val(),
                    cvc: $("#edit-member-cvc").val(),
                    pm_id: pm_id
                },
                success: function(data) {
                    $('#modal-edit_credit_card').modal('hide')

                    table_cc.rows().clear().draw();

                    let newRow = table_cc.row.add( [
                        `${data.card.brand.charAt(0).toUpperCase() + data.card.brand.slice(1)} **** ${data.card.last4}`,
                        data.billing_details.name,
                        `${data.card.exp_month}/${data.card.exp_year}`,
                        data.billing_details.address.postal_code,
                        `<a href="javascript:" class="edit-element" data-id="${data.id}" title="Edit"><i class="fa-solid fa-pen"></i></a>`
                    ] ).draw( false ).node();

                    $('.edit-element').on("click", function (e) {
                        pm_id = $(e.currentTarget).data('id')
                        
                        $(".alert-edit_cc").removeClass("hidden")
                        $('#modal-edit_credit_card').modal({
                            backdrop: 'static',
                            keyboard: false  
                        })

                        $(".alert-edit_cc").removeClass('hidden')
                    });
                },
                error: function(xhr){
                    var errors = $.parseJSON(xhr.responseText).error
                    
                    $(".alert-edit_cc-error").text(errors)
                    $(".alert-edit_cc-error").removeClass('hidden')

                    $("#form-edit_credit_card input, #form-edit_credit_card button").attr("disabled", false)
                    $(".cc-edit_loader").addClass('hidden')
                }
            });

        }

        getCreditCards()
    }
})
document.addEventListener("turbolinks:load", function() {
    if($("body").data("controller") == "home" && $("body").data("action") == "survey"){

        let current_survey = parseInt($(".survey-step.active").attr("data-step"))
        let total_survey = parseInt($(".survey-step").length)
        let progress = parseInt((current_survey / total_survey) * 100)
        let membership_number;

        $(".survey_progress").css("width", `${progress}%`)

        $(".current-survey").text($(".survey-step.active").attr("data-step"))
        $(".total-survey").text(total_survey)

        $('.survey_choice').on("click", function(e) {
            let current_div = $(".survey-step.active")
            let current_step = parseInt($(current_div).attr("data-step"))

            if (current_step == 5) {
                $(`.survey-step-${current_step} .survey_choice`).removeClass("active")
                $(this).addClass("active")
                let answ = $(this).attr("data-answ")

                $(`.survey-step-${current_step} input`).attr("checked", false)
                $(`input[id='mcc_events_${answ}']`).attr("checked", true)
            }
            // else  if (current_step == 6) {

            //     if ($(this).children("div").text() == "None")  {
            //         $(`.survey-step-${current_step} .survey_choice`).removeClass("active")
            //         $(`.survey-step-${current_step} .survey_choice`).children("input").each(function(elem) {
            //             $(this).prop("checked", false)
            //         })

            //         $(this).addClass("active")
            //         $(this).children("input").prop("checked", true)
            //     }
            //     else {
                   
            //         $(`div[data-value='none']`).parent().removeClass("active")
            //         $(this).toggleClass("active")

            //         $(this).children("input").prop("checked", true)

            //         $(`div[data-value='none']`).parent().children("input").prop("checked", false)
                    
            //     }


            // }
            else {
                if ($(this).children(".survey_img").length > 0) {
                    $(this).children(".survey_img").toggleClass("active")
                }
                else 
                    $(this).toggleClass("active")

                if ($(this).children("input").length > 0 ) {
                    let active = $(this).children("input").prop("checked")
                    $(this).children("input").attr("checked", !active)
                }
            }

        })

        if ($("#survey-membership_number").val() != "") {
            check_member(true)
        }

        
        $(document).on("click", "#btn-survey_start", function() {
            $(".survey_proceed").append(`
                <div class="survey_progress"></div>
                <div class="row justify-content-center text-center m-3 mt-3">
                    <div class="col-lg-2 col-md-3 col-sm-3 p-0">
                        <button id="btn-proceed" class="btn btn-mcc-primary w-100" data-turbolinks="false" style="font-size:13px !important">PROCEED</button>
                    </div>
                </div>
            `)

            $(".survey").remove()
            $(".survey-step-1").removeClass("d-none")

            calcProgress()

            $(".survey_proceed").removeClass("d-none")
            $(".servey_question_num").removeClass("d-none")
        })
        
        $(document).on("click", "#btn-proceed", function() {
            let current_div = $(".survey-step.active")
            let current_step = parseInt($(current_div).attr("data-step"))
            let next_step = current_step + 1

  
            if ($(`.survey-step-${next_step}`).length > 0) {
                $(".survey-step.active").addClass("d-none")
                $(".survey-step.active").removeClass("active")

                $(`.survey-step-${next_step}`).addClass("active")
                $(`.survey-step-${next_step}`).removeClass("d-none")
                
                $(".current-survey").text(next_step)

                calcProgress()
            }

            if (next_step > 1) {
                if ($(".survey_back").length == 0) {
                    $(".servey_question_num").before(`<div class="m-3">
                        <a href="javascript:void(0)" class="survey_back"><i class="fa-solid fa-arrow-left"></i></a>
                    </div>`)
                }
            }


            if (next_step > parseInt($(".survey-step").length)) {
                $(".servey_question_num").remove()
                $(".thanks").removeClass("d-none")
                $(".survey_proceed").remove()
                $(".survey_back").remove()
                
                $(`.survey-step-${parseInt($(".survey-step").length)}`).addClass("d-none")

                saveSurvey()
            }
            
        })

        $(document).on("click", ".survey_back", function() {
            let current_div = $(".survey-step.active")
            let current_step = parseInt($(current_div).attr("data-step"))
            let before_step = current_step - 1

            if ($(`.survey-step-${before_step}`).length > 0) {
                $(".survey-step.active").addClass("d-none")
                $(".survey-step.active").removeClass("active")

                $(`.survey-step-${before_step}`).addClass("active")
                $(`.survey-step-${before_step}`).removeClass("d-none")

                if (before_step == 1)
                    $(this).remove()

                $(".current-survey").text(before_step)

                calcProgress()
            }
        })
        
        $(document).on("keyup", "#survey-membership_number", function(e) {
            if ($(this).val().trim() != "")
                $("#btn-valid-member").attr("disabled", false)
            else
                $("#btn-valid-member").attr("disabled", true)

            if(e.which == 13) {
                check_member(false)
            }
        })

        function calcProgress() {
            let current_survey = parseInt($(".survey-step.active").attr("data-step"))
            let total_survey = parseInt($(".survey-step").length)
            let progress = parseInt((current_survey / total_survey) * 100)

            $(".survey_progress").animate({width:`${progress}%`}, 450)

            //$(".survey_progress").css("width", `${progress}%`)
        }

        function check_member(signed_id) {
            
            membership_number = $("#survey-membership_number").val().trim()

            if (membership_number != "") {
                $("#survey-membership_number").attr("disabled", true)
                
                $(".loader").removeClass("d-none")
                $(".alert").remove()

                fetch(`/mccadmin/member_interests/check-member/${membership_number}`, {
                    method: 'GET',
                    mode: 'cors',
                    cache: 'default',
                }).then(function(response) {
                    if (response.ok) {
                        response.json().then(function(data) {

                            $("#btn-valid-member").remove()

                            $(".survey-membership_number").parent().append(`<div class='row'><div class='col'><h3 style="color:#366240">${data.member}</h3></div></div>`)
                            
                            $(".survey-membership_number").parent().after(`
                                <div class='row justify-content-center text-center m-3 mt-3'>
                                <div class="col-lg-2 col-md-3 col-sm-3 p-0">
                                    <button id="btn-survey_start" class="btn btn-mcc-primary w-100" data-turbolinks="false" style="font-size:13px !important">GET STARTED</button>
                                </div>
                                </div>
                            `)
                            
                            $(".survey-membership_number").remove()

                            if (data.survey != null) {
                                survey = data.survey
                                let services = survey.services.split(',')
                                let other_services = survey.other_services
                                let destinations = survey.destinations.split(',')
                                let other_destinations = survey.other_destinations
                                let holidays = survey.holidays.split(',')
                                let other_holidays = survey.other_holidays
                                let activities = survey.activities.split(',')
                                let other_activities = survey.other_activities
                                let events = survey.events.split(',')
                                let other_events = survey.other_events
                                let wellbeing = survey.wellbeing.split(',')
                                let other_wellbeing = survey.other_wellbeing
                                let recommendations = survey.recommendations.split(',')
                                let other_recommendations = survey.other_recommendations

                                $(".survey-step-1 .survey_choice div").each(function(elem) {
                                    if (services.includes($(this).text())) {
                                        $(this).parent().addClass("active")
                                        return
                                    }
                                })
                                $(".survey-step-1 .survey_other textarea").val(other_services)

                                $(".survey-step-2 .survey_choice p").each(function(elem) {
                                    if (destinations.includes($(this).text())) {
                                        $(this).parent().children(".survey_img").addClass("active")
                                        return
                                    }
                                })
                                $(".survey-step-2 .survey_other textarea").val(other_destinations)
                                
                                $(".survey-step-3 .survey_choice p").each(function(elem) {
                                    if (holidays.includes($(this).text())) {
                                        //$(this).parent().addClass("active")
                                        $(this).parent().children(".survey_img").addClass("active")
                                        $(this).parent().children("input").attr("checked", true)

                                        return
                                    }
                                })
                                $(".survey-step-3 .survey_other textarea").val(other_holidays)

                                $(".survey-step-4 .survey_choice p").each(function(elem) {
                                    if (activities.includes($(this).text())) {
                                        // $(this).parent().addClass("active")
                                        $(this).parent().children(".survey_img").addClass("active")
                                        $(this).parent().children("input").attr("checked", true)

                                        return
                                    }
                                })
                                $(".survey-step-4 .survey_other textarea").val(other_activities)
                                
                                $(".survey-step-5 .survey_choice div").each(function(elem) {
                                    if (events.includes($(this).text())) {
                                        $(this).parent().addClass("active")
                                        $(this).parent().children("input").attr("checked", true)
                                        
                                        $(`.survey-step-5 .survey_choice`).removeClass("active")
                                       
                                        let answ = $(this).attr("data-answ")
                                        
                                        if (events == "Yes please keep me updated") {
                                            $(".survey-step-5 .survey_choice[data-answ='yes']").addClass("active")
                                            $(`#mcc_events_yes`).attr("checked", true)
                                        }
                                        else if (events == "Not interested") {
                                            $(".survey-step-5 .survey_choice[data-answ='no']").addClass("active")
                                            $(`#mcc_events_no`).attr("checked", true)
                                        }
                                            
                                    
                                        return
                                    }
                                })
                                $(".survey-step-5 .survey_other textarea").val(other_events)

                                $(".survey-step-6 .survey_choice p").each(function(elem) {
                                    if (wellbeing.includes($(this).text())) {
                                        //$(this).parent().addClass("active")
                                        $(this).parent().children(".survey_img").addClass("active")
                                        $(this).parent().children("input").attr("checked", true)

                                        return
                                    }
                                })
                                $(".survey-step-6 .survey_other textarea").val(other_wellbeing)

                                $(".survey-step-7 .survey_choice div").each(function(elem) {
                                    if (recommendations.includes($(this).text())) {
                                        $(this).parent().addClass("active")
                                        $(this).parent().children("input").attr("checked", true)

                                        return
                                    }
                                })
                                $(".survey-step-7 .survey_other textarea").val(other_recommendations)

                                if (signed_id == true) {
                                    $(".survey_proceed").append(`
                                        <div class="survey_progress"></div>
                                        <div class="row justify-content-center text-center m-3 mt-3">
                                            <div class="col-lg-2 col-md-3 col-sm-3 p-0">
                                                <button id="btn-proceed" class="btn btn-mcc-primary w-100" data-turbolinks="false" style="font-size:13px !important">PROCEED</button>
                                            </div>
                                        </div>
                                    `)
                        
                                    $(".survey").remove()
                                    $(".survey-step-1").removeClass("d-none")
                        
                                    calcProgress()
                        
                                    $(".survey_proceed").removeClass("d-none")
                                    $(".servey_question_num").removeClass("d-none")
                                }
                            }
                            
                        })
                    }
                    else {
                        response.json().then(function(data) {
                            $("#survey-membership_number").attr("disabled", false)
                            $("#survey-membership_number").focus()
                            
                            $(".survey-membership_number").append(`<div class='alert alert-danger mt-2'>${data.error}</div>`)
                        })
                    }

                    
                    $(".loader").addClass("d-none")
                })
            }
        }


        function saveSurvey() {
            let formData = new FormData();
            let services = []
            let other_services = $(".survey-step-1 .survey_other textarea").val()
            let destinations = []
            let other_destinations = $(".survey-step-2 .survey_other textarea").val()
            let holidays = []
            let other_holidays = $(".survey-step-3 .survey_other textarea").val()
            let activities = []
            let other_activities = $(".survey-step-4 .survey_other textarea").val()
            let events = []
            let other_events = "" //$(".survey-step-5 .survey_other textarea").val()
            let wellbeing = []
            let other_wellbeing = $(".survey-step-6 .survey_other textarea").val()
            let recommendations = []
            let other_recommendations = $(".survey-step-7 .survey_other textarea").val()

            $(".survey-step-1 .survey_choice.active div").each(function(element) {
                let choice = $(this).text()
                services.push(choice)
            })

            $(".survey-step-2 .survey_choice .survey_img.active").parent().children("p").each(function(element) {
                let choice = $(this).text()
                destinations.push(choice)
            })

            $(".survey-step-3 .survey_choice .survey_img.active").parent().children("p").each(function(element) {
                let choice = $(this).text()
                holidays.push(choice)
            })

            $(".survey-step-4 .survey_choice .survey_img.active").parent().children("p").each(function(element) {
                let choice = $(this).text()
                activities.push(choice)
            })

            $(".survey-step-5 .survey_choice.active div").each(function(element) {
                let choice = $(this).text()
                events.push(choice)
            })

            $(".survey-step-6 .survey_choice .survey_img.active").parent().children("p").each(function(element) {
                let choice = $(this).text()
                wellbeing.push(choice)
            })

            $(".survey-step-7 .survey_choice.active div").each(function(element) {
                let choice = $(this).text()
                recommendations.push(choice)
            })
            
            formData.append('membership_number', membership_number);
            formData.append('services', services);
            formData.append('other_services', other_services);
            formData.append('destinations', destinations);
            formData.append('other_destinations', other_destinations);
            formData.append('holidays', holidays);
            formData.append('other_holidays', other_holidays);
            formData.append('activities', activities);
            formData.append('other_activities', other_activities);
            formData.append('events', events);
            formData.append('other_events', other_events);
            formData.append('wellbeing', wellbeing);
            formData.append('other_wellbeing', other_wellbeing);
            formData.append('recommendations', recommendations);
            formData.append('other_recommendations', other_recommendations);

            fetch(`/members/members/survey`, {
                method: 'PUT',
                mode: 'cors',
                cache: 'default',
                body: formData,
            }).then(function(response) {
                if (response.ok) {
                    response.json().then(function(data) {

                        $(".survey-step").remove()
                        $(".survey_done").removeClass("d-none")
                        $(".spinner").parent().remove()
                    })
                }
                else {
                    response.json().then(function(data) {
                        $(".alert-danger").removeClass("hidden")
                        $(".alert-danger").text(data.error)
                    })
                }
            })
        }
    }
})
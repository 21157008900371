import consumer from "./consumer"

consumer.subscriptions.create("NotificationChannel", {
    connected() {
        /*if (window.location.href.includes('/mccadmin')) {
            fetch(`/mccadmin/notifications`, {
                method: 'GET',
                mode: 'cors',
                cache: 'default',
            }).then(function(response) {
                if (response.ok) {
                    response.json().then(function(data) {
                        for (let notification of data) {
                            $(".notifications-content").prepend("<div class='notification-item unread clearfix'>" + 
                                "<div class='heading'>" + 
                                    "<div class='thumbnail-wrapper d24 circular b-white b-a b-white m-t-10 '>" +
                                    "<img width='30' height='30' data-src-retina='' data-src='' alt='' src='/assets/default-user'>" + 
                                    "</div>" +
                                    "<a href='#' class='text-complete pull-left'>" + 
                                        "<i class='pg-icon m-r-10'></i>" + 
                                        "<span class='fs-12 m-l-10'>" + notification.content + "</span>" + 
                                    "</a>" +
                                    "<span class='pull-right time'>" + notification.time_ago + "</span>" + 
                                "</div>" +
                                "<div class='option' data-toggle='tooltip' data-placement='left' title='mark as read'>" +
                                    "<a href='#' class='mark'></a>" + 
                                "</div>" +
                            "</div>")

                            if (notification.seen == false) {
                                $('.bubble').removeClass('hidden')
                            }
                        }
                    })
                }
            })
        }*/
    },

    disconnected() {
        // Called when the subscription has been terminated by the server
    },

    received(data) {

        /*
        if (data.new_notif == true) {
            $('.bubble').removeClass('hidden')
        }

        if (data.type === "new_member") {
            $(".notifications-content").prepend("<div class='notification-item unread clearfix'>" + 
            "<div class='heading'>" + 
                "<div class='thumbnail-wrapper d24 circular b-white b-a b-white m-t-10 '>" +
                "<img width='30' height='30' data-src-retina='' data-src='' alt='' src='/assets/default-user'>" + 
                "</div>" +
                "<a href='#' class='text-complete pull-left'>" + 
                    "<i class='pg-icon m-r-10'></i>" + 
                    "<span class='fs-12 m-l-10'>" + data.content.content + "</span>" + 
                "</a>" +
                "<span class='pull-right time'>2 mins ago</span>" + 
            "</div>" +
            "<div class='option' data-toggle='tooltip' data-placement='left' title='mark as read'>" +
                "<a href='#' class='mark'></a>" + 
            "</div>" +
        "</div>")
        }
*/
    }
});

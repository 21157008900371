document.addEventListener("turbolinks:load", function() {
    $("#notification-center").on("click", function() {
        if (!$('.dropdown').hasClass('show')) {
            $(".notifications-content").html("")
            $(".notifications-content").html("<div class='text-center m-2'><img src='/assets/loader.gif'></div>")

            // fetch(`/mccadmin/notifications`, {
            //     method: 'GET',
            //     mode: 'cors',
            //     cache: 'default',
            //     headers: {
            //         'Accept': 'application/json, text/plain, */*',
            //         'Content-Type': 'application/x-www-form-urlencoded'
            //     },
            // }).then(function(response) {
            //     if (response.ok) {
            //         response.json().then(function(data) {
            //             $(".notifications-content").html("")
         
            //             for (let notification of data) {
            //                 let read = notification.new ? 'unread' : ''

            //                 $(".notifications-content").prepend(`<div class='notification-item ${read} clearfix'>
            //                     <div class='heading'>
            //                         <div class='thumbnail-wrapper d24 circular b-white b-a b-white m-t-10 '>
            //                         <img width='30' height='30' data-src-retina='' data-src='' alt='' src='/assets/default-user'>
            //                         </div>
            //                         <a href='/mccadmin/members/${notification.relatable_id}/edit' class='notif_link text-complete pull-left'>
            //                             <i class='pg-icon m-r-10'></i>
            //                             <span class='fs-12 m-l-10'>${notification.content}</span>
            //                         </a>
            //                         <span class='pull-right time'>${notification.time_ago}</span>
            //                     </div>
            //                     <div class='option' data-toggle='tooltip' data-placement='left' title='mark as read'>
            //                         <a href='javascript:void(0)' class='mark' data-notification_id='${notification.id}'></a>
            //                     </div>
            //                 </div>`)

            //             }

            //             $(".mark, .notif_link").on("click", function(e) {
            //                 readNotification($(this).attr('data-notification_id'))
            //             })

            //             $('.bubble').addClass('hidden')
            //         })
            //     }
            //     else {
                    
            //     }
            // })
        }
    })

    function readNotification(id) {
        fetch(`/mccadmin/notifications/${id}/read`, {
            method: 'PUT',
            mode: 'cors',
            cache: 'default',
            headers: {
                'Accept': 'application/json, text/plain, */*',
                'Content-Type': 'application/x-www-form-urlencoded'
            },
        }).then(function(response) {
            if (response.ok) {
                response.json().then(function(data) {
                    if (data.new) {
                        $(`a[data-notification_id='${data.id}']`).parent().parent().addClass('unread')
                    }
                    else {
                        $(`a[data-notification_id='${data.id}']`).parent().parent().removeClass('unread')
                    }
                })
            }
        })
    }

})
document.addEventListener("turbolinks:load", function() {
    if($("body").data("controller") == "registrations") {

        $("#member-gender").select2({
            placeholder: "Select your gender",
            allowClear: false,
            minimumResultsForSearch: -1
        });

        $("#member-countries").select2({
            placeholder: "Select your country",
            allowClear: false,
            minimumResultsForSearch: -1
        });

        $("#btn-back-to-top").on("click", function() {
            $("html, body").animate({ scrollTop: 0 }, "slow");
            return false;
        })

        $("#btn-signup").on("click", function() {
            if ($("#form-signup").valid()) {
                $(this).attr('disabled', true)
                $(".signup-loader").removeClass("hidden")
            }
        })

        $("#member-recommended_by_member").on("change", function() {

            $(".recommended-container").toggleClass('hidden')

            $("#member_recommended_by_member_name").attr('required', $(this).prop("checked"))
            $("#member_recommended_by_member_phone").attr('required', $(this).prop("checked"))
        })
    }
})
const { get } = require("jquery");
const { updateLocale } = require("moment");

document.addEventListener("turbolinks:load", function() {
    if($("body").data("controller") == "categories") {
        let selected_element_id;
        let method;
        let categories;
        
        categories = $("input[name='category[ancestry]']").comboTree({
            isMultiple: false,
            cascadeSelect: true,
            collapse: false
        });

        table = $('#table-categories').DataTable({
            'initComplete': function(settings){
                var api = this.api();
       
                api.cells(
                   api.rows(function(idx, data, node){
                      return ($(node).data('related')) ? true : false;
                   }).indexes(),
                   0
                ).checkboxes.disable();
            },
            'columnDefs': [
                {
                   'targets': 0,
                   'checkboxes': {
                        'selectRow': false,
                        'selectCallback': function(nodes, selected) {
                            refresh_selected_elements()
                        }
                    }
                },
                {
                    "targets": 6,
                    "orderable": false,
                    "className": 'dt-body-right'
                }
            ],
            'select': {
                'style': 'multi',
                "selector": 'td:first-child'
            },
            "bPaginate": true,
            "bFilter": true,
            "bInfo": false,
            "bLengthChange": false,
            "searching": true,
            "destroy": true,
            "stateSave": false,
            "scrollCollapse": true,
            "oLanguage": {
                "sLengthMenu": "_MENU_ ",
                "sInfo": "Showing <b>_START_ to _END_</b> of _TOTAL_ entries"
            },
            "bAutoWidth": true,
            "iDisplayLength": 50
        });
        $("#table-categories_wrapper > div > .col-sm-6:nth-child(1)").addClass("content-left pt-2")
    
        // *** EVENTS

        $('#btn-new_categorie').click(function () {
            $(".current-function").text("Add new")
            method = 'create'
            $('#modal-category').modal('show')
        })
        
        $('#btn-save').on("click", function(e) {
            $("#form-category").validate()
            $('#form-category').on("submit", function(e) {
                e.preventDefault()
            })

            if ($("#form-category").valid()) {
                if (method == 'create')
                    create()
                else if (method == 'update')
                    update()
            }
        })

        $("#table-categories tbody").on("click", ".delete-element",  function() {
            element_id = $(this).parent().parent().parent().attr("data-element_id")

            destroy(element_id, null)
        })

        $("#delete-selected-elements").on("click", function() {
            elements = rows_selected
            
            destroy(null, rows_selected.join(","))
        })

        $("#table-categories tbody").on("click", ".edit-element", function() {
            $(".current-function").text("Edit")
            
            element_id = $(this).parent().parent().parent().attr("data-element_id")
            selected_element_id = element_id

            getElement(element_id)
        })

        $("#modal-category").on("shown.bs.modal", function() {
            $("input[name='category[title]'").focus()
        })

        $("#modal-category").on("hidden.bs.modal", function() {
            $("input[name='category[title]'").val("")
            categories.setSelection(['0'])
            $("textarea[name='category[description]']").val("")
            selected_element_id = null
            $(".alert-danger").addClass("hidden")
        })

        $(document).on("blur", ".category_order", function() {
            let category_id = $(this).attr("data-category_id")
            let order = $(this).val()

            update_order(category_id, order)
        })

        $(document).on("keypress", ".category_order", function(e) {
            if(e.which == 13) {
                let category_id = $(this).attr("data-category_id")
                let order = $(this).val()
                
                update_order(category_id, order)
            }
        })

        // *** FUNCTIONS

        function update_order(category_id, order) {
            let heading;

            if (window.location.pathname.includes("cities"))
                heading = "cities"
            else if (window.location.pathname.includes("articles"))
                heading = "articles"
            
            let formData = new FormData();
            formData.append('category_id', category_id);
            formData.append('order', order);

            fetch(`/mccadmin/${heading}/categories/update_order`, {
                method: 'PATCH',
                mode: 'cors',
                cache: 'default',
                body: formData,
            }).then(function(response) {

                if (response.ok) {
                  
                }
                else {
                    response.json().then(function(data) {
                        $(".alert-danger").removeClass("hidden")
                        $(".alert-danger").text(data.error)
                    })
                }
            })
        }

        function getCategories() {
            let heading;

            if (window.location.pathname.includes("cities"))
                heading = "cities"
            else if (window.location.pathname.includes("articles"))
                heading = "articles"

            fetch(`/mccadmin/${heading}/categories/getAll`, {
                method: 'GET',
                mode: 'cors',
                cache: 'default',
            }).then(function(response) {
                if (response.ok) {
                    response.json().then(function(data) {
                        categories.setSource(data.categories2);
                        categories.setSelection(['0'])
                    })
                }
                else {
                    response.json().then(function(data) {
                        console.log(data)
                    })
                }
            })

            $("strong").replaceWith(function(){
                //return $("<div />", {html: $(this).html()});
                return $("<div />" , {html: $(this).html()});
            });
        }

        function getElement(element_id) {
            JsLoadingOverlay.show({
                "overlayBackgroundColor": "#666666",
                "overlayOpacity": 0.6,
                'spinnerIcon': 'ball-triangle-path',
                "spinnerColor": "#393636",
                "containerID": "modal-category-content"
            });

            method = 'update'
            $('#modal-category').modal('show')

            fetch(`/mccadmin/cities/categories/get/${element_id}`, {
                method: 'GET',
                mode: 'cors',
                cache: 'default',
            }).then(function(response) {
                if (response.ok) {
                    response.json().then(function(data) {
                        console.log(data)
                        $("#category_active").prop("checked", data.active)

                        $("input[name='category[title]'").val(data.title)
                        ancestry = data.ancestry
                        ancestry != null ? categories.setSelection([ancestry.substr(ancestry.lastIndexOf("/")+1, ancestry.length)]) : categories.setSelection(['0'])
                        $("textarea[name='category[description]']").val(data.description)

                        if (data.icon.url != null) {
                            $("#category_icon_preview").removeClass("d-none")
                            $("#category_icon_preview").attr("src", data.icon.url)
                        }
                        else {
                            $("#category_icon_preview").addClass("d-none")
                            $("#category_icon_preview").attr("src", "")
                        }
                        
                        JsLoadingOverlay.hide();
                    })
                }
            })
        }

        function create() {
            let heading;
            let formData = new FormData();
            formData.append('title', $("input[name='category[title]']").val());
            formData.append('description', $("textarea[name='category[description]']").val());
            if($("input[name='category[ancestry]']").val() != "None")
                formData.append('ancestry', categories.getSelectedIds()[0]);
            
            if (window.location.pathname.includes("cities"))
                heading = "cities"
            else if (window.location.pathname.includes("articles"))
                heading = "articles"

            formData.append('heading', heading);

            const icon = document.querySelector("#category_icon");
            if (icon != null)
                formData.append('icon', icon.files[0]);

            $("#form-category input, #form-category textarea, #form-category select, #form-category button").attr("disabled", true)
            $(".loading").removeClass("hidden")

            fetch(`/mccadmin/${heading}/categories`, {
                method: 'POST',
                mode: 'cors',
                cache: 'default',
                body: formData,
            }).then(function(response) {
                $("#form-category input, #form-category textarea, #form-category select, #form-category button").attr("disabled", false)
                $(".loading").addClass("hidden")
                
                if (response.ok) {
                    response.json().then(function(data) {
                        let newRow = table.row.add( [
                            data.id,
                            data.id,
                            data.title,
                            data.parent != null ? data.parent.title : '',
                            moment(data.created_at).format("DD MMM HH:mm"),
                            `<div class='element-actions'><a href="javascript:" class="edit-element" title="Edit"><i class="fa-solid fa-pen"></i></a>
                                <a href="javascript:" class="delete-element" title="Delete"><i class="fa-solid fa-trash-can"></i></a>
                            </div>
                            <div class='element-deleting hidden'>Deleting...</div>`
                        ] ).draw( false ).node();
    
                        $(newRow).attr("data-element_id", data.id);

                        $(newRow).find(".delete-element").on("click", function() {
                            destroy(data.id, null)
                        })

                        $(newRow).find(".edit-element").on("click", function() {
                            $(".current-function").text("Edit")
                            selected_element_id = data.id

                            getElement(data.id)
                        })

                        var newOption = new Option(data.name, data.id, true, true);
                        $("select[name='category[parent_id]'").append(newOption).trigger('change');

                        getCategories()

                        $('#modal-category').modal('hide')
                    })
                }
                else {
                    response.json().then(function(data) {
                        $(".alert-danger").removeClass("hidden")
                        $(".alert-danger").text(data.error)
                    })
                }
            })
        }

        function update() {
            let heading;
            let formData = new FormData();

            formData.append('active', $("#category_active").prop("checked"));
            formData.append('title', $("input[name='category[title]']").val());
            formData.append('description', $("textarea[name='category[description]']").val());
            if($("input[name='category[ancestry]']").val() != "None")
                formData.append('ancestry', categories.getSelectedIds()[0]);
            else 
                formData.append('ancestry', null)
            
            if (window.location.pathname.includes("cities"))
                heading = "cities"
            else if (window.location.pathname.includes("articles"))
                heading = "articles"

            formData.append('heading', heading);
            const icon = document.querySelector("#category_icon");
            if (icon != null)
                formData.append('icon', icon.files[0]);

            $("#form-category input, #form-category textarea, #form-category select, #form-category button").attr("disabled", true)
            $(".loading").removeClass("hidden")

            fetch(`/mccadmin/${heading}/categories/${selected_element_id}`, {
                method: 'PUT',
                mode: 'cors',
                cache: 'default',
                body: formData,
            }).then(function(response) {
                $("#form-category input, #form-category textarea, #form-category select, #form-category button").attr("disabled", false)
                $(".loading").addClass("hidden")
                
                if (response.ok) {
                    response.json().then(function(data) {
                        if (data.active)
                            $(`#table-categories tr[data-element_id='${data.id}'] td:nth-child(7)`).html(`<span class="label label-success" style="color:white">Active</span>`);
                        else
                            $(`#table-categories tr[data-element_id='${data.id}'] td:nth-child(7)`).html(`<span class="label badge-danger" style="color:white">Inactive</span>`);

                        if (data.icon.url != null)
                            $(`#table-categories tr[data-element_id='${data.id}'] td:nth-child(4)`).html(`<img src='${data.icon.url}' width='64' alt='icon'>`)

                        $(`#table-categories tr[data-element_id='${data.id}'] td:nth-child(3)`).text(data.title);
                        data.parent != null ? $(`#table-categories tr[data-element_id='${data.id}'] td:nth-child(5)`).text(data.parent.title) : $(`#table-categories tr[data-element_id='${data.id}'] td:nth-child(5)`).text('');
                        
                        getCategories()

                        $('#modal-category').modal('hide')
                    })
                }
                else {
                    response.json().then(function(data) {
                        $(".alert-danger").removeClass("hidden")
                        $(".alert-danger").text(data.error)
                    })
                }
            })
        }

        function destroy(element_id, elements_id) {
            let heading;
            if (window.location.pathname.includes("cities"))
                heading = "cities"
            else if (window.location.pathname.includes("articles"))
                heading = "articles"

            Swal.fire({
                title: 'Are you sure?',
                text: "You won't be able to revert this!",
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Yes, delete it!',
                allowOutsideClick: false,
                allowEscapeKey: false,
                showLoaderOnConfirm: true,
                preConfirm: () => {
                    if (element_id != null) {
                        return fetch(`/mccadmin/${heading}/categories/${element_id}`, {
                            method: 'DELETE',
                            mode: 'cors',
                            cache: 'default',
                        }).then(function(response) {
                            if (response.ok) {
                                table.row($(`tr[data-element_id='${element_id}']`)).remove().draw();
                            }
                            else {
                                response.json().then(function(data) {
                                    
                                    $(`.table tr[data-element_id='${element_id}'] td:nth-child(6) .element-deleting`).addClass("hidden");
                                    $(`.table tr[data-element_id='${element_id}'] td:nth-child(6) .element-actions`).removeClass("hidden");
                                    table.cells(`tr[data-element_id='${element_id}']`, 0).checkboxes.enable()
                                    table.columns().checkboxes.deselectAll()
                                    
                                    Swal.fire({
                                        icon: 'error',
                                        text: data.error,
                                    })
                                })
                            }
                        }).then(function(response) {
                            $(`.table tr[data-element_id='${element_id}'] td:nth-child(6) .element-deleting`).removeClass("hidden");
                            $(`.table tr[data-element_id='${element_id}'] td:nth-child(6) .element-actions`).addClass("hidden");
                            $(`.table tr[data-element_id='${element_id}']`).addClass("tr-deleting")
                            table.cells(`tr[data-element_id='${element_id}']`, 0).checkboxes.disable()
                        })
                    }
                    else {
                        $(".total-selected-content").addClass("hidden")

                        let formData = new FormData();
                        formData.append('elements_id', elements_id);

                        fetch(`/mccadmin/cities/categories/delete_elements`, {
                            method: 'DELETE',
                            mode: 'cors',
                            cache: 'default',
                            body: formData,
                        }).then(function(response) {
                            
                            if (response.ok) {
                                response.json().then(function(data) {
         
                                    if (data.elements == 0) {
                                        Swal.fire({
                                            icon: 'error',
                                            text: data.error,
                                        })
                                    }
                                    else {
                                        elements = data.elements
                                        table.columns().checkboxes.deselectAll()
                                        // $.each(elements_id.split(','), function(index, rowId){
                                        //     table.cells(`tr[data-song_id='${rowId}']`, 0).checkboxes.deselect()
                                        // })

                                        $.each(elements, function(index, rowId){
                                            table.row($(`tr[data-element_id='${rowId}']`)).remove().draw();
                                        });

                                        

                                        $(".total-selected-content").addClass("hidden")
                                    }
                                })
                            }
                            else {
                                $(".total-selected-content").addClass("hidden")

                                let formData = new FormData();
                                formData.append('elements_id', elements_id);
        
                                fetch(`/mccadmin/cities/categories/delete_elements`, {
                                    method: 'DELETE',
                                    mode: 'cors',
                                    cache: 'default',
                                    body: formData,
                                }).then(function(response) {
                                    
                                    if (response.ok) {
                                        response.json().then(function(data) {
                 
                                            if (data.elements == 0) {
                                                Swal.fire({
                                                    icon: 'error',
                                                    text: data.error,
                                                })
                                            }
                                            else {
                                                elements = data.elements
                                                table.columns().checkboxes.deselectAll()
                                                // $.each(elements_id.split(','), function(index, rowId){
                                                //     table.cells(`tr[data-song_id='${rowId}']`, 0).checkboxes.deselect()
                                                // })
        
                                                $.each(elements, function(index, rowId){
                                                    table.row($(`tr[data-element_id='${rowId}']`)).remove().draw();
                                                });
        
                                                $(".total-selected-content").addClass("hidden")
                                            }
                                        })
                                    }
                                })
                            }
                        })
                    }
                }
            }).then((result) => {
                if (result.isConfirmed) {
                   
                    if (element_id != null) {
                       
                    }
                    else {
                       
                    }
                }
            })
        }

        function refresh_selected_elements() {
            rows_selected = table.column(0).checkboxes.selected();

            if (rows_selected.length == 0) {
                $(".total-selected-content").addClass("hidden")
                $(".total-selected-content").css("marginBottom", 0)
                $(".total-selected-content").css("opacity", 0)
            }
            else {
                $(".total-selected-content").removeClass("hidden")
                $(".total-selected-content").animate({
                    marginBottom: 10,
                    opacity: 1
                }, 100, "linear",  function() {
                });
            }
          
            $.each(rows_selected, function(index, rowId){
            });
          
            let txt = rows_selected.length > 1 ? `${rows_selected.length} elements selected` : `${rows_selected.length} element selected`
            $(".total-selected").text(txt)
          
            return rows_selected
        }

        getCategories()
    }
})
document.addEventListener("turbolinks:load", function() {

    if($("body").data("controller") == "orders") {

        $("#order-type").val($(".choose-payment-service").first().data("service_name"))
        $("#order-type").attr("data-service_id", $(".choose-payment-service").first().data("service_id"))

        async function initPayment() {
            await fetch(`/orders/getpublishablekey`, {
                method: 'GET',
                mode: 'cors',
                cache: 'default',
                headers: {
                    'Accept': 'application/json, text/plain, */*',
                    'Content-Type': 'application/x-www-form-urlencoded'
                },
            }).then(function(data) {
                data.json().then(function(data) {

                    $(".choose-payment-service").on("click", function(e) {
                        let service_name = $(e.currentTarget).data('service_name')
                        let service_id = $(e.currentTarget).data('service_id')
                        
                        $("#order-type").val(service_name)
                        $("#order-type").attr("data-service_id", service_id)
                        $(".service_choosen").text(service_name.substr(0,1).toUpperCase()+service_name.substr(1))
            
                        $(".choose-payment-service").removeClass("selected")
                        $(this).addClass("selected")
            
                        $(".paymentSelectionTab").removeClass("active")
                        $(`#tab-${service_name}`).addClass("active")
                    })
            
                    const stripe = Stripe(data.key);
            
                    const options = {
                        clientSecret: $("#client_secret").val(),
                        appearance: {
                            theme: 'stripe',
                        }
                    }
            
                    const elements = stripe.elements(options);
                    const paymentElement = elements.create('payment');
                    paymentElement.mount('#payment-element');
            
                    const form = document.getElementById('payment-form');
            
                    paymentElement.on('change', function(event) {
                        if (event.complete) {
                            submitReady = true
                            $("#submit").attr("disabled", false)
                        }
                        else {
                            submitReady = false
                            $("#submit").attr("disabled", true)
                        }
                    });
            
                    $("#submit").on("click", function(event) {
                        event.preventDefault();
                        if (submitReady) {
                                
                            stripe.confirmPayment({
                                elements,
                                confirmParams: {
                                    return_url: `${window.location.origin}/orders/finalize-order`,
                                }
                            }).then(function(result) {
                                console.log(result)

                                // if (result.error) {
            
                                //     const messageContainer = document.querySelector('#error-message');
                                //     messageContainer.textContent = error.message;
                                // }
                                // else {
                                    
                                // }
                            })
                        }
                    })
                })
            })

        }

        initPayment()
    }
})

document.addEventListener("turbolinks:load", function() {
    if($("body").data("controller") == "home") {
   
        let table_unsubmitted = $('#table-unsubmitted').DataTable({
            "bPaginate": true,
            "bFilter": true,
            "bInfo": false,
            "bLengthChange": false,
            "pageLength": 50,
            "searching": false,
            "destroy": true,
            "stateSave": false,
            "scrollCollapse": true,
            "oLanguage": {
                "sLengthMenu": "_MENU_ ",
                "sInfo": "Showing <b>_START_ to _END_</b> of _TOTAL_ entries"
            },
            "bAutoWidth": false,
            'order' : [[0,'desc']]
        });

        $("#dashboard-filter-categories").select2({
            minimumResultsForSearch: -1,
            placeholder: "Categories"
        });

        $("#dashboard-agent_perf").select2({
            minimumResultsForSearch: -1,
            placeholder: "Agents"
        });

       
        function getCategories() {
            //let heading = $("input[name='post[heading]']").data("heading");
            let url = `/mccadmin/cities/categories/getAll`;
 
            fetch(url, { 
                method: 'GET',
                mode: 'cors',
                cache: 'default',
            }).then(function(response) {
                if (response.ok) {
                    let categories;
                    response.json().then(function(data) {

                        var treeData = []

                        $.each(data.categories2, function(index, rowId){
                            treeData.push(data.categories2[index][0])
                        });

                        jQuery.each(data.categories2, function (i, val) {
                            if (val.title != "Uncategorized" && val.title != "Community") {
                                $("#dashboard-filter-categories").append(`<option value='${val.id}'>${val.title}</option>`)
                            }
                        })
                        $("#dashboard-filter-categories option:first").trigger("update")

                    })
               
                }
            })

        }

        

        
        
        if(window.location.href.includes("stats") == true) {
            getCategories()


            let booking_per_day_date = flatpickr("#filter-booking_per_day_date", {
                mode: "range",
                dateFormat: "Y-m-d",
                allowInput: false,
                allowInvalidPreload: true,
                defaultDate: [new Date(new Date().setMonth(new Date().getMonth(), 1)), new Date(new Date().setMonth(new Date().getMonth()))],
                onOpen: function(selectedDates, dateStr, instance) {
                    filter_selectedDates = selectedDates
                },
                onClose: function(selectedDates, dateStr, instance) {
                    if (selectedDates.length != 2) {
                        this.setDate(filter_selectedDates, true, "Y-m-d")
                    }
    
                    if (selectedDates.length == 2) {
    
                    }
                },
                onChange: function(selectedDates, dateStr, instance) {
                    if (selectedDates.length == 2) {
                        $("#filter-booking_per_day_date").after("<img src='/assets/loading.svg' class='booking_per_day_date' width='32'>")
                        
                        let formData = new FormData();
                        formData.append('date_start', moment(selectedDates[0]).format('YYYY-MM-DD'));
                        formData.append('date_end', moment(selectedDates[1]).format('YYYY-MM-DD'));
    
                        fetch('/mccadmin/dashboard-bookings-per-day', {
                            method: 'POST',
                            mode: 'cors',
                            cache: 'default',
                            body: formData
                        }).then(function(response) { 
                            if (response.ok) {
                                response.json().then(function(data) {
    
                                    Highcharts.chart('container-bookings_per_day', {
                                        title: {
                                            text: 'Bookings per day'
                                        },
                                        xAxis: {
                                            categories: data.bookings_per_day.dates,
                                            crosshair: true,
                                        },
                                        yAxis: {
                                            type: 'logarithmic',
                                            minorTickInterval: 0.1,
                                            accessibility: {
                                                rangeDescription: 'Range: 0.1 to 1000'
                                            }
                                        },
                                        tooltip: {
                                            headerFormat: '<b>{series.name}</b><br />',
                                            pointFormat: 'Total = {point.y}'
                                        },
                                        series: [{
                                            data: data.bookings_per_day.total,
                                            pointStart: 0,
                                            name: 'Bookings'
                                        }]
                                    });
    
                                    $(".booking_per_day_date").remove()
                                })
                            }
                        })
                    }
                },
            })
    
            $("#dashboard-filter-categories").on("change", function() {
                let date_start = $("#filter-most_requested_services_date").val().split(" to ")[0]
                let date_end = $("#filter-most_requested_services_date").val().split(" to ")[1]
                let category_id = $("#dashboard-filter-categories").val()
    
                let formData = new FormData();
                formData.append('date_start', date_start);
                formData.append('date_end', date_end);
                formData.append('category_id', category_id);
    
                fetch('/mccadmin/dashboard-requested-services', {
                    method: 'POST',
                    mode: 'cors',
                    cache: 'default',
                    body: formData
                }).then(function(response) { 
                    if (response.ok) {
                        response.json().then(function(data) {
    
                            Highcharts.chart('container-booking_most_requested_services', {
                                chart: {
                                    plotBackgroundColor: null,
                                    plotBorderWidth: null,
                                    plotShadow: false,
                                    type: 'pie'
                                },
                                title: {
                                    subtitle: 'Most requested services',
                                    text: 'Most requested services'
                                },
                                xAxis: {
                                    categories: data.booking_most_requested_services.services,
                                    crosshair: true,
                                },
                                yAxis: {
                                    min: 0,
                                },
                                plotOptions: {
                                    pie: {
                                        allowPointSelect: true,
                                        cursor: 'pointer',
                                        dataLabels: {
                                            enabled: true,
                                            format: '<b>{point.name}</b>: {point.percentage:.1f} %'
                                        }
                                    }
                                },
                                series: [{
                                    name: 'Total',
                                    data: data.booking_most_requested_services.services
                                }]
                            });
    
                            $(".most_requested_services_date").remove()
                        })
                    }
                })
            })
    
            let most_requested_services_date = flatpickr("#filter-most_requested_services_date", {
                mode: "range",
                dateFormat: "Y-m-d",
                allowInput: false,
                allowInvalidPreload: true,
                defaultDate: [new Date(new Date().setMonth(new Date().getMonth(), 1)), new Date(new Date().setMonth(new Date().getMonth()+1, 0))],
                onOpen: function(selectedDates, dateStr, instance) {
                    filter_selectedDates = selectedDates
                },
                onClose: function(selectedDates, dateStr, instance) {
                    if (selectedDates.length != 2) {
                        this.setDate(filter_selectedDates, true, "Y-m-d")
                    }
    
                    if (selectedDates.length == 2) {
    
                    }
                },
                onChange: function(selectedDates, dateStr, instance) {
                    if (selectedDates.length == 2) {
                        $("#filter-most_requested_services_date").after("<img src='/assets/loading.svg' class='most_requested_services_date' width='32'>")
    
                        let formData = new FormData();
                        formData.append('date_start', moment(selectedDates[0]).format('YYYY-MM-DD'));
                        formData.append('date_end', moment(selectedDates[1]).format('YYYY-MM-DD'));
                        formData.append('category_id', $("#dashboard-filter-categories").val());
    
                        fetch('/mccadmin/dashboard-requested-services', {
                            method: 'POST',
                            mode: 'cors',
                            cache: 'default',
                            body: formData
                        }).then(function(response) { 
                            if (response.ok) {
                                response.json().then(function(data) {
                                    Highcharts.chart('container-booking_most_requested_services', {
                                        chart: {
                                            plotBackgroundColor: null,
                                            plotBorderWidth: null,
                                            plotShadow: false,
                                            type: 'pie'
                                        },
                                        title: {
                                            subtitle: 'Most requested services',
                                            text: 'Most requested services'
                                        },
                                        xAxis: {
                                            categories: data.booking_most_requested_services.services,
                                            crosshair: true,
                                        },
                                        yAxis: {
                                            min: 0,
                                        },
                                        plotOptions: {
                                            pie: {
                                                allowPointSelect: true,
                                                cursor: 'pointer',
                                                dataLabels: {
                                                    enabled: true,
                                                    format: '<b>{point.name}</b>: {point.percentage:.1f} %'
                                                }
                                            }
                                        },
                                        series: [{
                                            name: 'Total',
                                            data: data.booking_most_requested_services.services
                                        }]
                                    });
    
                                    $(".most_requested_services_date").remove()
                                })
                            }
                        })
                    }
                }
            })
    
            let agent_perf_date = flatpickr("#filter-agent_perf_date", {
                mode: "range",
                dateFormat: "Y-m-d",
                allowInput: false,
                allowInvalidPreload: true,
                defaultDate: [new Date(new Date().setMonth(new Date().getMonth(), 1)), new Date(new Date().setMonth(new Date().getMonth()+1, 0))],
                onOpen: function(selectedDates, dateStr, instance) {
                    filter_selectedDates = selectedDates
                },
                onClose: function(selectedDates, dateStr, instance) {
                    if (selectedDates.length != 2) {
                        this.setDate(filter_selectedDates, true, "Y-m-d")
                    }
    
                    if (selectedDates.length == 2) {
    
                    }
                },
                onChange: function(selectedDates, dateStr, instance) {
                    let date_start = moment(selectedDates[0]).format('YYYY-MM-DD');
                    let date_end = moment(selectedDates[1]).format('YYYY-MM-DD');
                    let agent_id = $("#dashboard-agent_perf").val();
    
                    booking_agent(date_start, date_end, agent_id)
                }
            })
    
            $("#dashboard-agent_perf").on("change", function() {
                let date_start = $("#filter-agent_perf_date").val().split(" to ")[0]
                let date_end = $("#filter-agent_perf_date").val().split(" to ")[1]
                let agent_id = $("#dashboard-agent_perf").val()
    
                booking_agent(date_start, date_end, agent_id)
            })


            let most_requested_cities_date = flatpickr("#filter-booking_most_requested_city_date", {
                mode: "range",
                dateFormat: "Y-m-d",
                allowInput: false,
                allowInvalidPreload: true,
                defaultDate: [new Date(new Date().setMonth(new Date().getMonth(), 1)), new Date(new Date().setMonth(new Date().getMonth()))],
                onOpen: function(selectedDates, dateStr, instance) {
                    filter_selectedDates = selectedDates
                },
                onClose: function(selectedDates, dateStr, instance) {
                    if (selectedDates.length != 2) {
                        this.setDate(filter_selectedDates, true, "Y-m-d")
                    }
    
                    if (selectedDates.length == 2) {
    
                    }
                },
                onChange: function(selectedDates, dateStr, instance) {
                    if (selectedDates.length == 2) {
                        $("#filter-booking_most_requested_city_date").after("<img src='/assets/loading.svg' class='booking_most_requested_city_date' width='32'>")
                        
                        let formData = new FormData();
                        formData.append('date_start', moment(selectedDates[0]).format('YYYY-MM-DD'));
                        formData.append('date_end', moment(selectedDates[1]).format('YYYY-MM-DD'));
    
                        fetch(`/mccadmin/dashboard-most-requested-cities/${moment(selectedDates[0]).format('YYYY-MM-DD')}/${moment(selectedDates[1]).format('YYYY-MM-DD')}`, {
                            method: 'GET',
                            mode: 'cors',
                            cache: 'default'
                        }).then(function(response) { 
                            if (response.ok) {
                                response.json().then(function(data) {
    
                                    Highcharts.chart('container-booking_most_requested_city', {
                                        chart: {
                                            type: 'column'
                                        },
                                        title: {
                                            subtitle: 'Most requested cities',
                                            text: 'Most requested cities'
                                        },
                                        xAxis: {
                                            categories: data.cities,
                                            crosshair: true,
                                        },
                                        yAxis: {
                                            min: 0,
                                        },
                                        plotOptions: {
                                            column: {
                                                pointPadding: 0.2,
                                                borderWidth: 0
                                            }
                                        },
                                        series: [{
                                            name: 'Total',
                                            data: data.total
                                    
                                        }]
                                    });
    
                                    $(".booking_most_requested_city_date").remove()
                                })
                            }
                        })
                    }
                },
            })


            fetch('/mccadmin/dashboard', {
                method: 'GET',
                mode: 'cors',
                cache: 'default',
            }).then(function(response) { 
                if (response.ok) {
                    response.json().then(function(data) {

                        const chart = Highcharts.chart('container', {
                            title: {
                                subtitle: 'Overall Earnings',
                                text: 'Overall Earnings'
                            },
                            xAxis: {
                                tickInterval: 1,
                                type: 'year',
                                accessibility: {
                                    rangeDescription: 'Range: 1 to 10'
                                },
                                labels: {
                                    format: '{value} km'
                                },
                            },
                            yAxis: {
                                type: 'logarithmic',
                                minorTickInterval: 0.1,
                                accessibility: {
                                    rangeDescription: 'Range: 0.1 to 1000'
                                }
                            },
                            tooltip: {
                                headerFormat: '<b>Earnings</b><br />',
                                pointFormat: 'Year = {point.x}, Total = £ {point.y}'
                            },
                            series: [{
                                data: data.total,
                                pointStart: data.start_year,
                                name: 'Earnings'
                            }]
                        });

                        const chart_most_requested_city = Highcharts.chart('container-booking_most_requested_city', {
                            chart: {
                                type: 'column'
                            },
                            title: {
                                subtitle: 'Most requested cities',
                                text: 'Most requested cities'
                            },
                            xAxis: {
                                categories: data.booking_most_requested_city.cities,
                                crosshair: true,
                            },
                            yAxis: {
                                min: 0,
                            },
                            plotOptions: {
                                column: {
                                    pointPadding: 0.2,
                                    borderWidth: 0
                                }
                            },
                            series: [{
                                name: 'Total',
                                data: data.booking_most_requested_city.total
                        
                            }]
                        });

                        const chart_most_requested_services = Highcharts.chart('container-booking_most_requested_services', {
                            chart: {
                                plotBackgroundColor: null,
                                plotBorderWidth: null,
                                plotShadow: false,
                                type: 'pie'
                            },
                            title: {
                                subtitle: 'Most requested services',
                                text: 'Most requested services'
                            },
                            xAxis: {
                                categories: data.booking_most_requested_services.services,
                                crosshair: true,
                            },
                            yAxis: {
                                min: 0,
                            },
                            plotOptions: {
                                pie: {
                                    allowPointSelect: true,
                                    cursor: 'pointer',
                                    dataLabels: {
                                        enabled: true,
                                        format: '<b>{point.name}</b>: {point.percentage:.1f} %'
                                    }
                                }
                            },
                            series: [{
                                name: 'Total',
                                data: data.booking_most_requested_services.services
                            }]
                        });

                        Highcharts.chart('container-bookings_per_day', {
                            title: {
                                text: 'Bookings per day'
                            },
                            xAxis: {
                                categories: data.bookings_per_day.dates,
                                crosshair: true,
                            },
                            yAxis: {
                                type: 'logarithmic',
                                minorTickInterval: 0.1,
                                accessibility: {
                                    rangeDescription: 'Range: 0.1 to 1000'
                                }
                            },
                            tooltip: {
                                headerFormat: '<b>{series.name}</b><br />',
                                pointFormat: 'Total = {point.y}'
                            },
                            series: [{
                                data: data.bookings_per_day.total,
                                pointStart: 0,
                                name: 'Bookings'
                            }]
                        });

                        const chart_male_vs_female = Highcharts.chart('container-male_vs_female', {
                            chart: {
                                plotBackgroundColor: null,
                                plotBorderWidth: null,
                                plotShadow: false,
                                type: 'pie'
                            },
                            title: {
                                subtitle: 'Male vs Female',
                                text: 'Male vs Female'
                            },
                            xAxis: {
                                categories: data.male_vs_female,
                                crosshair: true,
                            },
                            yAxis: {
                                min: 0,
                            },
                            plotOptions: {
                                pie: {
                                    allowPointSelect: true,
                                    cursor: 'pointer',
                                    dataLabels: {
                                        enabled: true,
                                        format: '<b>{point.name}</b>: {point.percentage:.1f} %'
                                    }
                                }
                            },
                            series: [{
                                name: 'Total',
                                data: data.male_vs_female
                            }]
                        });


                        Highcharts.chart('container-bookings_per_agents', {
                            chart: {
                                type: 'column'
                            },
                            title: {
                                align: 'left',
                                text: 'State Booking per agent'
                            },
                            accessibility: {
                                announceNewData: {
                                    enabled: true
                                }
                            },
                            xAxis: {
                                type: 'category'
                            },
                            yAxis: {
                                title: {
                                    text: 'Total bookings'
                                }
                            },
                            legend: {
                                enabled: false
                            },
                            plotOptions: {
                                series: {
                                    borderWidth: 0,
                                    dataLabels: {
                                        enabled: true,
                                        format: '{point.y:.0f}'
                                    }
                                }
                            },
                        
                            tooltip: {
                                headerFormat: '<span style="font-size:11px">{series.name}</span><br>',
                                pointFormat: '<span style="color:{point.color}">{point.name}</span>: <b>{point.y:.0f} </b> total<br/>'
                            },

                            series: [
                                {
                                    name: 'Status',
                                    colorByPoint: true,
                                    data: data.bookings_per_agents
                                }
                            ],
                        })
                    })
                }
            })
        }


        function booking_agent(date_start, date_end, agent_id) {

            let formData = new FormData();
            formData.append('date_start', date_start);
            formData.append('date_end', date_end);
            formData.append('agent_id', agent_id);
                
            fetch(`/mccadmin/dashboard-booking-agent/${date_start}/${date_end}/${agent_id}`, {
                method: 'GET',
                mode: 'cors',
                cache: 'default',
            }).then(function(response) { 
                if (response.ok) {
                    response.json().then(function(data) {

                        Highcharts.chart('container-bookings_per_agents', {
                            chart: {
                                type: 'column'
                            },
                            title: {
                                align: 'left',
                                text: 'State Booking per agent'
                            },
                            accessibility: {
                                announceNewData: {
                                    enabled: true
                                }
                            },
                            xAxis: {
                                type: 'category'
                            },
                            yAxis: {
                                title: {
                                    text: 'Total bookings'
                                }
                            },
                            legend: {
                                enabled: false
                            },
                            plotOptions: {
                                series: {
                                    borderWidth: 0,
                                    dataLabels: {
                                        enabled: true,
                                        format: '{point.y:.0f}'
                                    }
                                }
                            },
                        
                            tooltip: {
                                headerFormat: '<span style="font-size:11px">{series.name}</span><br>',
                                pointFormat: '<span style="color:{point.color}">{point.name}</span>: <b>{point.y:.0f} </b> total<br/>'
                            },
                            
                            series: [
                                {
                                    name: 'Status',
                                    colorByPoint: true,
                                    data: data.bookings_per_agents
                                }
                            ],
                        })

                        $(".most_requested_services_date").remove()
                    })
                }
            })
        }

        let to_export_unsubmitted;

        $("input[name='not_submited_booking_month']").on("click", function() {
            let months = $(this).val()

            table_unsubmitted.rows().clear().draw();
            $("#table-unsubmitted .dataTables_empty").html("Loading data...")

            fetch(`/mccadmin/dashboard-unsubmitted-booking/${months}`, {
                method: 'GET',
                mode: 'cors',
                cache: 'default',
            }).then(function(response) { 
                if (response.ok) {
                    response.json().then(function(data) {

                        to_export_unsubmitted = data.unsubmitted_booking
 
                        $("#table-unsubmitted .dataTables_empty").html("No data available in table")
                        
                        $(".total_unsubmitted").text(data.unsubmitted_booking.length)

                        for(let member of data.unsubmitted_booking) {
                            let newRow = table_unsubmitted.row.add( [
                                `${member.first_name} ${member.last_name}`,
                                member.days,
                                `<a href="https://mcc.social/mccadmin/members/${member.id}/edit" class="edit-element" title="Edit"><i class="fa-solid fa-pen"></i></a>`
                            ] ).draw( false ).node();
    
                            //$(newRow).attr("data-cc_element_id", cc.id);
                        }
                    })
                }
            })
        })

        $("#btn-export-unsubmitted").on("click", function() {
            $(this).attr("disabled", true)
            $(".loader-csv").removeClass("hidden")

            tableToCSV()
        })

        function tableToCSV() {

            let csv_data = [];

            var csvrow = [];
            csvrow.push('membership_number');
            csvrow.push('name');
            csvrow.push("Didn't submit more than");

            // csvrow.push('expire');

            csv_data.push(csvrow.join(","));

            /*var csvrow = [];
            csvrow.push('title2');
            csvrow.push('content2');
            csv_data.push(csvrow.join(","));

            var csvrow = [];
            csvrow.push('title3');
            csvrow.push('content3');
            csv_data.push(csvrow.join(","));*/

            //csv_data = csv_data.join('\n');
            
            jQuery.each(to_export_unsubmitted, function (i, val) {
                //console.log(val[0])


                //var rows = document.getElementsByTagName('tr');
                //for (var i = 0; i < rows.length; i++) {
    
                    // Get each column data
                    // var cols = rows[i].querySelectorAll('td,th');
    
                    // Stores each csv row data
                    // var csvrow = [];
                    // for (var j = 0; j < val.length; j++) {
                    //     csvrow.push(val[j]);
                    // }
                    
                    var csvrow = [];
                    csvrow.push(`"${val.membership_number}"`);
                    csvrow.push(`"${val.first_name} ${val.last_name}"`);
                    csvrow.push(`"${val.days}"`)
                    //csvrow.push(`"${val[6]}"`)
                    csv_data.push(csvrow.join(","));

                    // Combine each column value with comma
                    
                //}
    
                // Combine each row data with new line character
                //csv_data = csv_data.join('\n');
    
                // Call this function to download csv file 
            
            })
            
            csv_data = csv_data.join('\n');

            downloadCSVFile(csv_data);
        }

        function downloadCSVFile(csv_data) {
 
            // Create CSV file object and feed
            // our csv_data into it
            CSVFile = new Blob([csv_data], {
                type: "text/csv"
            });
 
            // Create to temporary link to initiate
            // download process
            var temp_link = document.createElement('a');
 
            // Download csv file
            temp_link.download = "MCC_MEMBERS_NOT_SUBMIT_BOOKINGS.csv";
            var url = window.URL.createObjectURL(CSVFile);
            temp_link.href = url;
 
            // This link should not be displayed
            temp_link.style.display = "none";
            document.body.appendChild(temp_link);
 
            // Automatically click the link to
            // trigger download
            temp_link.click();
            document.body.removeChild(temp_link);

            $("#btn-export-unsubmitted").attr("disabled", false)
            $(".loader-csv").addClass("hidden")
        }
    }
})
document.addEventListener("turbolinks:load", function() {
    if($("body").data("controller") == "bookings") {

        let service_selected_id = null;
        let service_selected_name= null;

        let table_services = $('#table-services').DataTable({
            "processing": true,
            "language": {
                processing: '<i class="fa fa-spinner fa-spin fa-3x fa-fw"></i><span class="sr-only">Loading...</span> '
            },
            "scrollY": '300px',
            "scrollCollapse": true,
            scrollX:        true,
            paging:         true,
            "serverSide": true,
            "ajax": {
                "url": $('#table-services').data('source'),
                "data": function(e){
                    e.city_id = $("#service-filter-cities").val()
                    e.categories = $("#service-filter-categories").val()
                }
            },
            "pagingType": "full_numbers",
            "columns": [
                {"data": "id"},
                {"data": "title",
                    // "render": function(data, display, record) {
                    //     return `<a href="/mccadmin/cities/posts/${record.id}/edit">${data}</a>`
                    // }
                },
                {"data": "city"},
            ],
            createdRow: function (row, data, index) {
                $(row).attr("data-element_id", data.id)
                $(row).addClass("service-to-choose")
            },
            initComplete: function( settings, json ) {
                $('.dataTables_scrollHead').css('width', '100%');
                $('.dataTables_scrollHeadInner').css('width', '100%');
                $('.table').css('width', '100%');
                
                //$('#table-services').DataTable().columns.adjust().draw();

                $("#table-services").on("click", ".service-to-choose", function(e) {
                    let service_id = $(e.currentTarget).data('element_id')
                    service_selected_id = service_id
                    service_selected_name = $(`#table-services tr[data-element_id='${service_id}'] td:nth-child(2)`).text() + " - " + $(`#table-services tr[data-element_id='${service_id}'] td:nth-child(3)`).text()

                    $('.service-to-choose').removeClass("service-selected")
                    $(this).addClass("service-selected")
                    
                    $(".service_selected_name").next('span').remove();
                    $(".service_selected_name").text($(`#table-services tr[data-element_id='${service_id}'] td:nth-child(2)`).text())
                    $(".service_selected_name").after("<span> is selected</span>")
                    $("#btn-select-service").attr("disabled", false)
                })
            },
            "bPaginate": true,
            "iDisplayLength": 50,
            "bFilter": true,
            "bInfo": false,
            "bLengthChange": false,
            "searching": true,
            "destroy": true,
            "stateSave": false,
            "scrollCollapse": true,
            "oLanguage": {
                "sLengthMenu": "_MENU_ ",
                "sInfo": "Showing <b>_START_ to _END_</b> of _TOTAL_ entries"
            },
            "columnDefs": [
                { "width": 50, "targets": 0 },
                { "width": 100, "targets": 2 },
            ],
            "bAutoWidth": false,
            'order' : [[0,'desc']]
        });
        $("#table-services_wrapper > div > .col-sm-6:nth-child(1)").addClass("content-left pt-2")

        $("#service-filter-categories, #service-filter-cities").on("select2:select", function(e) {
            table_services.ajax.reload()
        })

        $("#service-filter-categories, #service-filter-cities").on("select2:unselect", function(e) {
            table_services.ajax.reload()
        })

        $("#modal-find_service").on("hidden.bs.modal", function() {
            service_selected_id = null
            service_selected_name = null
            $('.service-to-choose').removeClass("service-selected")

            $(".service_selected_name").next('span').remove();
            $(".service_selected_name").text("")
            $("#btn-select-service").attr("disabled", true)
        })
        
        $("#modal-find_service").on("shown.bs.modal", function() {
            $("#service-filter-cities").select2({
                minimumResultsForSearch: -1,
                placeholder: "Cities"
            });

            $("#service-filter-categories").select2({
                minimumResultsForSearch: -1,
                placeholder: "Categories"
            });
        })

        $("#btn-select-service").on("click", function() {
            $('#booking-service').val(null).trigger('change')
            let select = $("#booking-service");
            let items = {id: service_selected_id, text: service_selected_name}; 
            let data = select.val() || [];

            $(items).each(function () {
                if(!select.find("option[value='" + this.id + "']").length) {
                    select.append(new Option(this.text, this.id, true, true));
                }
                data.push(this.id);
            });

            $('#booking-service').val(data).trigger('change')
            $("#booking_post_id").val(service_selected_id)
            //$("#booking-service").select2('data', { id:service_selected_id, title: service_selected_name});

            $("#modal-find_service").modal('hide')
        })

        function getCategories() {
            let url = `/mccadmin/cities/categories/getAll`;
            
            fetch(url, { 
                method: 'GET',
                mode: 'cors',
                cache: 'default',
            }).then(function(response) {
                if (response.ok) {
                    response.json().then(function(data) {
                        jQuery.each(data.all, function (i, val) {
                            $("#service-filter-categories").append(`<option value='${val.id}'>${val.title}</option>`)
                        })
                        $("#service-filter-categories option:first").trigger("update")
                    })
                }
            })
        }

        function getCities() {
            if ($("#service-filter-cities").length > 0) {

                fetch(`/mccadmin/cities/cities/get-cities`, {
                    method: 'GET',
                    mode: 'cors',
                    cache: 'default',
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json;charset=utf-8'
                    },
                }).then(function(response) {
                    if (response.ok) {
                        response.json().then(function(data) {
                           
                            jQuery.each(data, function (i, val) {
                                $("#service-filter-cities").append(`<option value='${val.id}'>${val.name}</option>`)
                            })

                            $("#service-filter-cities option:first").trigger("update")
                        })
                    }
                })
            }
        }

        getCategories()
        getCities()
    }
})
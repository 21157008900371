document.addEventListener("turbolinks:load", function() {
    if($("body").data("controller") == "recommendations") {
        let filter_recommendations = ['pending', 'treated']
        let filter_selectedDates;

        document.addEventListener("turbolinks:before-cache", function() {
            if (table != null) {
                table.destroy();
            }

            $.fn.dataTable.ext.search.pop();
        })

        let table = $('#table-recommendations').DataTable({
            "bPaginate": true,
            "iDisplayLength": 50,
            "bFilter": true,
            "bInfo": false,
            "bLengthChange": false,
            "searching": true,
            "destroy": true,
            "stateSave": false,
            "scrollCollapse": true,
            "oLanguage": {
                "sLengthMenu": "_MENU_ ",
                "sInfo": "Showing <b>_START_ to _END_</b> of _TOTAL_ entries"
            },
            "bAutoWidth": false,
            'order' : [[0,'desc']]
        });
        $("#table-recommendations_wrapper > div > .col-sm-6:nth-child(1)").addClass("content-left pt-2")

        $("#table-recommendations_wrapper > div:nth-child(1) > .col-sm-6:nth-child(1)").removeClass("col-sm-6")
        $("#table-recommendations_wrapper > div:nth-child(1) > div:nth-child(1)").addClass("col-sm-10")
        $("#table-recommendations_wrapper > div:nth-child(1) > .col-sm-6:nth-child(2)").removeClass("col-sm-6")
        $("#table-recommendations_wrapper > div:nth-child(1) > div:nth-child(2)").addClass("col-sm-2")

        $("#table-recommendations_wrapper > .row > div:first").append(`<div class="col-3">
            <select id='recommendation-filter' class="full-width" data-init-plugin="select2" multiple placeholder='Creators'>
            </select>
        </div>`)
        $("#table-recommendations_wrapper > .row > div:first").append("<div><span>" +
            "<div class='form-check checkbox-circle complete form-check-inline pr-0'>" + 
                "<input type='checkbox' name='recommendation_status' id='recommendation_status_pending' value='pending' checked>" +
                "<label for='recommendation_status_pending'>" + 
                    "Pending " +
                "</label>" +
            "</div>" + 
            "<div class='form-check checkbox-circle complete  form-check-inline '>" + 
             "<input type='checkbox' name='recommendation_status' id='recommendation_status_treated' value='treated' checked>" +
                "<label for='recommendation_status_treated'>" + 
                    "Treated" +
                "</label>" +
            "</div>" + 
            `<input type='text' id='filter-recommendation_dates' class='form-control' style='display: inline-block !important; width: 180px;' >` +
        "</span></div>")

        let filter_recommendation_date = flatpickr("#filter-recommendation_dates", {
            mode: "range",
            dateFormat: "Y-m-d",
            allowInput: false,
            allowInvalidPreload: true,
            defaultDate: [new Date(new Date().setMonth(new Date().getMonth()-2)), new Date()],
            onOpen: function(selectedDates, dateStr, instance) {
                filter_selectedDates = selectedDates
            },
            onClose: function(selectedDates, dateStr, instance) {
                if (selectedDates.length != 2) {
                    this.setDate(filter_selectedDates, true, "Y-m-d")
                }
            },
            onChange: function(selectedDates, dateStr, instance) {
                if (selectedDates.length == 2) {
                    getRecommendation(moment(selectedDates[0]).format('YYYY-MM-DD'), moment(selectedDates[1]).format('YYYY-MM-DD'))
                }
            },
        })

        $("#recommendation-filter").select2({
            minimumResultsForSearch: -1,
            placeholder: "Creators"
        });

        $("#recommendation-filter").attr('disabled', true)

        // toggle user
        $("#recommendation-filter").on("select2:select", function(e) {
            let users_id = $(this).val()

            $.fn.dataTable.ext.search.pop();
            $.fn.dataTable.ext.search.push(
                function(settings, data, dataIndex) {
                    let users = $(table.row(dataIndex).node()).attr('data-user').split(',')

                    for (user of users) {
                        if (users_id.includes(user) && filter_recommendations.includes($(table.row(dataIndex).node()).attr('data-state')))
                            return true
                        else
                            false
                    }
                }
            );

            table.draw();
        })
        
        $("#recommendation-filter").on("select2:unselect", function(e) {
           
            let users_id = $(this).val()
            if (users_id.length == 0) {
                    table.draw();
            }
            else {
                $.fn.dataTable.ext.search.push(
                    function(settings, data, dataIndex) {
                        let users = $(table.row(dataIndex).node()).attr('data-user').split(',')

                        for (user of users) {
                            if (users_id.includes(user))
                                return true
                            else
                                false
                        }
                    }
                );
            
                table.draw();
            }
        })

        $("#recommendation-filter").on("select2:closing", function(e) {

            if ($("#recommendation-filter").val().length == 0) {
                filterRecommendation()
            }
        })

        $("input[name='recommendation_status']").on("change", function(e) {
            let state = $(this).val()

            if ($(this).prop("checked") === true) {
                filter_recommendations.push(state)
            }
            else {
                filter_recommendations.splice(filter_recommendations.indexOf(state), 1)
            }

            filterRecommendation()
        })

        function filterRecommendation() {
            $.fn.dataTable.ext.search.pop();
            $.fn.dataTable.ext.search.push(
                function(settings, data, dataIndex) {
                    return filter_recommendations.includes($(table.row(dataIndex).node()).attr('data-state'));
                }
            );
            
            table.draw();
        }
        
        $('#recommendation-member_name').select2({
            ajax: {
                url: '/mccadmin/members/get-members-name',
                dataType: 'json',
                data: function (params) {
                    var query = {
                      search: params.term,
                      type: 'public'
                    }
                    return query;
                },
                processResults: function (data, params) {
                    params.page = params.page || 1;
                    return {
                        results: data.items,
                        pagination: {
                            more: (params.page * 30) < data.total_count
                        }
                    };
                   
                }
            },
            cache: true,
            minimumInputLength: 2,
            templateResult: formatRepo,
            templateSelection: function(repo) {
                if (repo.data != undefined){
                    $("#recommendation_member_id").val(repo.data.id)
                }

                return repo.name || repo.text;
            }
        });

        var recommendation_date = flatpickr("#recommendation_date", {
            enableTime: true,
            dateFormat: "Y-m-d H:i",
            time_24hr: true,
            allowInput: false,
        })

        $("#table-recommendations tbody").on("click", ".delete-element", function() {
            id = $(this).parent().parent().data("element_id")

            Swal.fire({
                title: 'Are you sure?',
                text: "You won't be able to revert this!",
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Yes, delete it!',
                allowOutsideClick: false,
                allowEscapeKey: false,
                showLoaderOnConfirm: true,
                preConfirm: () => {
                    return fetch(`/mccadmin/recommendations/${id}`, {
                        method: 'DELETE',
                        mode: 'cors',
                        cache: 'default',
                        headers: {
                            'Accept': 'application/json, text/plain, */*',
                            'Content-Type': 'application/x-www-form-urlencoded'
                        },
                    }).then(function(response) {
                        if (response.ok) {
                            table.row($(`tr[data-element_id='${id}']`)).remove().draw();
                        }
                        else {
                            
                        }
                    })
                }
            }).then((result) => {})
        })
        
        $('.btn-state').on('click', function(e) {
            $("#recommendation-state").val($(e.currentTarget).data('state'))
            $('.recommendation-state_label').text($(e.currentTarget).children().eq(1).text())
            $('.recommendation-state_label').siblings('span').attr('class', '').toggleClass($(e.currentTarget).children().eq(0).attr('class'))
            
            $("#recommendation_state").val($(e.currentTarget).data('state'))
        })
        
        FilePond.registerPlugin(FilePondPluginImagePreview, FilePondPluginFileValidateSize, FilePondPluginFileValidateType);

        const pond_image = FilePond.create(document.querySelector('#recommendation-pdf'), {
            acceptedFileTypes: ['application/pdf'],
            labelIdle: "Drag & Drop your file or <span class='filepond--label-action'> Browse </span>",
            allowProcess: false,
            instantUpload: false,
            allowReorder: true,
            name: 'recommendation[pdf]',
            storeAsFile: true,
            credits: {label: "", url: ""} ,
            maxFileSize: 25000000,
        });

        function getRecommendation(start, end) {
            $("#table-recommendations .dataTables_empty").html("<img src='/assets/loader.gif'>")

            fetch(`/mccadmin/recommendations/get-recommendations?start=${start}&end=${end}`, {
                method: 'GET',
                mode: 'cors',
                cache: 'default',
            }).then(function(response) {
                if (response.ok) {
                    response.json().then(function(data) {
                        table.rows().clear().draw();

                        for(val of data) {
                            let state;

                            if (val.state == 'pending')
                                state = 'warning'
                            else if (val.state == 'treated')
                                state = 'info'

                            let newRow = table.row.add( [
                                val.id,
                                `<a href='/mccadmin/recommendations/${val.id}/edit'>${val.member.first_name} ${val.member.last_name}</a>`,
                                moment.utc(val.date).format("DD MMM HH:mm"),
                                `<span class='label badge-${state} '>${val.state}</span>`,
                                `${val.user.first_name} ${val.user.last_name}`,
                                moment.utc(val.created_at).format("YYYY-MM-DD HH:mm"),
                                `<a href="/mccadmin/recommendations/${val.id}/edit" class="edit-element" title="Edit"><i class="fa-solid fa-pen"></i></a>
                                <a href="javascript:" class="delete-element" title="Delete"><i class="fa-solid fa-trash-can"></i></a>`
                            ] ).draw( false ).node();
        
                            $(newRow).attr("data-element_id", val.id);
                            $(newRow).attr("class", `recommendation_${val.state}`);
                            $(newRow).attr("data-state", val.state);
                            $(newRow).attr("data-user", val.user.id);
                        }

                        filterRecommendation()
                    })
                }
                else {
                    response.json().then(function(data) {
                     
                    })
                }
            })
        }

        function formatRepo (repo) {
            if (repo.loading) {
              return repo.name;
            }
          
            var $container = $(
                "<div class='select2-result-repository clearfix'>" +
                    "<div class='select2-result-repository__meta'>" +
                        "<div class='select2-result-repository__title'></div>" +
                    "</div>" +
                "</div>"
            );
          
            $container.find(".select2-result-repository__title").text(repo.name);
            
            return $container;
        }

        function getCreators() {
            fetch(`/mccadmin/bookings/get-users`, {
                method: 'GET',
                mode: 'cors',
                cache: 'default',
            }).then(function(response) {
                
                if (response.ok) {
                    response.json().then(function(data) {

                        jQuery.each(data, function (i, val) {
                            $("#recommendation-filter").append(`<option value='${val.id}'>${val.first_name} ${val.last_name}</option>`)
                            $("#recommendation-filter option:first").trigger("update")
                        })
                        
                        $("#recommendation-filter").attr('disabled', false)
                    })
                }
            })
        }

        getCreators()

    }
})
document.addEventListener("turbolinks:load", function() {
    if($("body").data("controller") == "home_sliders") {
        let sliders_removed_ids = []

        document.addEventListener("turbolinks:before-cache", function() {
            if (table != null) {
                table.destroy();
            }
        })

        let table = $('#table-home_sliders').DataTable({
            "bPaginate": true,
            "bFilter": true,
            "bInfo": false,
            "bLengthChange": false,
            "pageLength": 50,
            "searching": true,
            "destroy": true,
            "stateSave": false,
            "scrollCollapse": true,
            "oLanguage": {
                "sLengthMenu": "_MENU_ ",
                "sInfo": "Showing <b>_START_ to _END_</b> of _TOTAL_ entries"
            },
            "bAutoWidth": false,
            'order' : [[0,'desc']]
        });
        $("#table-articles_wrapper > div > .col-sm-6:nth-child(1)").addClass("content-left pt-2")

        FilePond.registerPlugin(FilePondPluginImagePreview, FilePondPluginFileValidateSize, FilePondPluginFileValidateType);

        const pond_sliders = FilePond.create(document.querySelector('#home_slider-image'), {
            acceptedFileTypes: ['image/*'],
            labelIdle: "Drag & Drop your image or <span class='filepond--label-action'> Browse </span>",
            imagePreviewMinWidth: 800,
            allowProcess: false,
            instantUpload: false,
            allowReorder: true,
            name: 'home_slider[image]',
            storeAsFile: true,
            credits: {label: "", url: ""} ,
            maxFileSize: 3000000,
        });

        $(".btn-slider-delete").on("click", function(e){
            Swal.fire({
                title: 'Are you sure?',
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Yes, delete it!'
            }).then((result) => {
                if (result.isConfirmed) {
                    sliders_removed_ids.push($(e.currentTarget).data('slider_id'))
                    $("input[name='home_page[slider_remove]']").val(sliders_removed_ids.join(','))
                    $(this).parent().remove()
                }
            })
        })

        $('#btn-save-home_slider').on('click', function(e) {
            $("#form-home_slider").validate()
            $('#form-home_slider').on("submit", function(e) {
                e.preventDefault()
            })

            if ($("#form-home_slider").valid()) {
                document.getElementById("form-home_slider").submit()
            }
        })

       
        $(".delete-element").on("click", function() {
            id = $(this).parent().parent().data("element_id")

            Swal.fire({
                title: 'Are you sure?',
                text: "You won't be able to revert this!",
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Yes, delete it!',
                allowOutsideClick: false,
                allowEscapeKey: false,
                showLoaderOnConfirm: true,
                preConfirm: () => {
                    return fetch(`/mccadmin/home_sliders/${id}`, {
                        method: 'DELETE',
                        mode: 'cors',
                        cache: 'default',
                        headers: {
                            'Accept': 'application/json, text/plain, */*',
                            'Content-Type': 'application/x-www-form-urlencoded'
                        },
                    }).then(function(response) {
                        if (response.ok) {
                            table.row($(`tr[data-element_id='${id}']`)).remove().draw();
                        }
                        else {
                            
                        }
                    })
                }
            }).then((result) => {})
        })

    }
})
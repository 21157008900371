document.addEventListener("turbolinks:load", function() {
    if(($("body").data("controller") == "interests" || $("body").data("controller") == "member_interests") && ($("body").data("controller") == "home" || $("body").data("controller") == "member_interests")) {

        let table_interests
        let table_member_interests
        let method = "create"
        let selected_element_id
        let interests = []
        let membership_number;

        document.addEventListener("turbolinks:before-cache", function() {
            if (table_interests != null) {
                table_interests.destroy();
            }

            $.fn.dataTable.ext.search.pop();
        })

        table_interests = $('#table-interests').DataTable({
            "processing": true,
            "language": {
                processing: '<i class="fa fa-spinner fa-spin fa-3x fa-fw"></i><span class="sr-only">Loading...</span> '
            },
            scrollX:        true,
            paging:         true,
            "serverSide": true,
            "ajax": {
                "url": $('#table-interests').data('source'),
                "data": function(e){
                    //e.status = filter_travels
                }
            },
            "pagingType": "full_numbers",
            "columns": [
                {"data": "id"},
                {"data": "name"},
                {"data": "active"},
                {"data": "id", 
                    "render": function(data, display, record) {
                        let edit = `<a href="javascript:" class="edit-element" data-element_id='${record.id}' title="Edit"><i class="fa-solid fa-pen"></i></a>`
                        let supp = `<a href='javascript:' class='delete-element' data-element_id='${record.id}' title='Delete'><i class='fa-solid fa-trash-can'></i></a>`

                        // if (record.creator != `${$("#user_type").attr("data-first_name")}` && $("#user_type").val() != "administrator") {
                        //     edit = `<a href="/mccadmin/interests/${record.id}/edit" class="edit-element" data-element_id='${record.id}' title="Edit"><i class="fa-solid fa-eye"></i></a>`
                        //     supp = ''
                        // }


                        return `${edit} ${supp}`
                    }
                }
            ],
            createdRow: function (row, data, index) {
                $(row).attr("data-element_id", data.id)
            },
            "fnDrawCallback": function( row, data, index ) {
                $(`.edit-element`).on("click", function (e) {
                    method = 'edit'
                    let id = $(this).data('element_id')
               
                    $('#modal-interests').modal({
                        backdrop: 'static',
                        keyboard: false  
                    })

                    getElement(id)
                });

                $('#table-interests').on("click", `.delete-element`, function(e) {
                    let id = $(this).data('element_id')

                        destroy(id)
              
                })
            },
            initComplete: function( settings, json ) {
               
            },
            "bPaginate": true,
            "iDisplayLength": 50,
            "bFilter": true,
            "bInfo": false,
            "bLengthChange": false,
            "searching": true,
            "destroy": true,
            "stateSave": false,
            "scrollCollapse": true,
            "oLanguage": {
                "sLengthMenu": "_MENU_ ",
                "sInfo": "Showing <b>_START_ to _END_</b> of _TOTAL_ entries"
            },
            "bAutoWidth": false,
            'order' : [[0,'desc']],
            'columnDefs': [
                { 'targets': 0, 'width': "50px"},
                { 'targets': 1, 'width': "800px"},
                { 'targets': 2, 'width': "100px"},
                { 'targets': 3, 'width': "10px"},
            ],
        });

        table_member_interests = $('#table-member_interests').DataTable({
            "processing": true,
            "language": {
                processing: '<i class="fa fa-spinner fa-spin fa-3x fa-fw"></i><span class="sr-only">Loading...</span> '
            },
            scrollX:        true,
            paging:         true,
            "serverSide": true,
            "ajax": {
                "url": $('#table-member_interests').data('source'),
                "data": function(e){
                    //e.status = filter_travels
                }
            },
            "pagingType": "full_numbers",
            "columns": [
                {"data": "member_name"},
                {"data": "membership_number",
                    "render": function(data, display, record) {
                        return `<div style="white-space: wrap;">${record.member_name}<br><span style="color:#8d8d8d">${record.membership_number}</span></div>`
                    }
                },
                {"data": "services",
                    "render": function(data, display, record) {
                        let services = record.services.split(',')
                        services = services.map((service) => `<span class='label badge-info'>${service}</span>`).join(',').replaceAll(',', ' ')
                        other_services = record.other_services != "" ? `<b>Other: </b> ${record.other_services}<br>` : '';
                        if (record.services != "")
                            services = `<h5>Services</h5>${services}<br>`
                        else 
                            services = ""

                        let destinations = record.destinations.split(',')
                        destinations = destinations.map((destination) => `<span class='label badge-info'>${destination}</span>`).join(',').replaceAll(',', ' ')
                        other_destinations = record.other_destinations != "" ? `<b>Other: </b> ${record.other_destinations}<br>` : '';
                        if (record.destinations != "")
                            destinations = `<hr class='m-0 mt-2 mb-0'><h5>Destinations</h5>${destinations}<br>`
                        else 
                            destinations = ""

                        let holidays = record.holidays.split(',')
                        holidays = holidays.map((holiday) => `<span class='label badge-info'>${holiday}</span>`).join(',').replaceAll(',', ' ')
                        other_holidays = record.other_holidays != "" ? `<b>Other: </b> ${record.other_holidays}<br>` : '';
                        if (record.holidays != "")
                            holidays = `<hr class='m-0 mt-2 mb-0'><h5>Type of travel and holidays</h5>${holidays}<br>`
                        else 
                            holidays = ""
                            
                        let activities = record.activities.split(',')
                        activities = activities.map((activity) => `<span class='label badge-info'>${activity}</span>`).join(',').replaceAll(',', ' ')
                        other_activities = record.other_activities != "" ? `<b>Other: </b> ${record.other_activities}<br>` : '';
                        if (record.holidays != "")
                            activities = `<hr class='m-0 mt-2 mb-0'><h5>Activities</h5>${activities}<br>`
                        else 
                            activities = ""

                        let events = record.events.split(',')
                        events = events.map((event) => `<span class='label badge-info'>${event}</span>`).join(',').replaceAll(',', ' ')
                        other_events = record.other_events != "" ? `<b>Other: </b> ${record.other_events}<br>` : '';
                        if (record.events != "")
                            events = `<hr class='m-0 mt-2 mb-0'><h5>MCC events, offers and collaborations</h5>${events}<br>`
                        else 
                            events = ""

                        let wellbeings = record.wellbeings.split(',')
                        wellbeings = wellbeings.map((wellbeing) => `<span class='label badge-info'>${wellbeing}</span>`).join(',').replaceAll(',', ' ')
                        other_wellbeings = record.other_wellbeings != "" ? `<b>Other: </b> ${record.other_wellbeings}<br>` : '';
                        if (record.wellbeings != "")
                            wellbeings = `<hr class='m-0 mt-2 mb-0'><h5>MCC Education services</h5>${wellbeings}<br>`
                        else 
                            wellbeings = ""

                        let recommendations = record.recommendations.split(',')
                        recommendations = recommendations.map((recommendation) => `<span class='label badge-info'>${recommendation}</span>`).join(',').replaceAll(',', ' ')
                        other_recommendations = record.other_recommendations != "" ? `<b>Other: </b> ${record.recommendations}<br>` : '';
                        if (record.recommendations != "")
                            recommendations = `<hr class='m-0 mt-2 mb-0'><h5>Directory recommendations</h5>${recommendations}<br>`
                        else 
                            recommendations = ""

                        return `<a class="btn btn-success" style="color:white" data-toggle="collapse" href="#collapseExample${record.id}" role="button" aria-expanded="false" aria-controls="collapseExample">See info</a>
                            <div class="collapse" id="collapseExample${record.id}">
                            ${services}
                            ${other_services}
                            ${destinations}
                            ${other_destinations}
                            ${holidays}
                            ${other_holidays}
                            ${activities}
                            ${other_activities}
                            ${events}
                            ${other_events}
                            ${wellbeings}
                            ${other_wellbeings}
                            </div>
                            `
                    }
                },
                {"data": "created_at"}
                // {"data": "id", 
                //     "render": function(data, display, record) {
                //         let edit = `<a href="javascript:" class="edit-element" data-element_id='${record.id}' title="Edit"><i class="fa-solid fa-pen"></i></a>`
                //         let supp = `<a href='javascript:' class='delete-element' data-element_id='${record.id}' title='Delete'><i class='fa-solid fa-trash-can'></i></a>`

                //         // if (record.creator != `${$("#user_type").attr("data-first_name")}` && $("#user_type").val() != "administrator") {
                //         //     edit = `<a href="/mccadmin/interests/${record.id}/edit" class="edit-element" data-element_id='${record.id}' title="Edit"><i class="fa-solid fa-eye"></i></a>`
                //         //     supp = ''
                //         // }


                //         return `${edit} ${supp}`
                //     }
                // }
            ],
            createdRow: function (row, data, index) {
                $(row).attr("data-element_id", data.id)
            },
            "fnDrawCallback": function( row, data, index ) {
            },
            initComplete: function( settings, json ) {
               
            },
            "bPaginate": true,
            "iDisplayLength": 50,
            "bFilter": true,
            "bInfo": false,
            "bLengthChange": false,
            "searching": true,
            "destroy": true,
            "stateSave": false,
            "scrollCollapse": true,
            "oLanguage": {
                "sLengthMenu": "_MENU_ ",
                "sInfo": "Showing <b>_START_ to _END_</b> of _TOTAL_ entries"
            },
            "bAutoWidth": false,
            'order' : [[0,'desc']],
            'columnDefs': [
                { 'targets': 0, 'width': "5px", visible: false},
                { 'targets': 1, 'width': "200px"},
                { 'targets': 2, 'width': "750px"},
            ],
        });

        $('#btn-new_interest').click(function () {
            $(".current-function").text("Add new")
            method = 'create'
            $('#modal-interest').modal('show')
        })
        
        $("#modal-interest").on("hidden.bs.modal", function() {
            $("input[name='interest[name]'").val("")
            
            selected_element_id = null
            $(".alert-danger").addClass("hidden")
        })
        
        $(document).on("click", ".interest", function() {
            $(this).children("li").toggleClass("selected-interest")
            $(this).children("li").toggleClass("list-group-item")

            if ($(this).children("li").hasClass("selected-interest")) {
                $(this).children("li").prepend(`<i class="fa fa-check-square" style="color: #17A497" aria-hidden="true"></i>`)

                interests.push($(this).attr("data-interest"))
            }
            else {
                $(this).children("li").children("i").remove()
                let val = $(this).attr("data-interest")
                interests.splice(interests.indexOf(val), 1)
            }

            if (interests.length > 0) {
                $("#btn-submit-interests").attr("disabled", false)
            }
            else {
                $("#btn-submit-interests").attr("disabled", true)
            }

            console.log(interests)
        })

        $("#btn-valid-member").on("click", function(e) {
            check_member()
        })

        $("#interest-membership_number").on("keyup", function(e) {
            if ($(this).val().trim() != "")
                $("#btn-valid-member").attr("disabled", false)
            else
                $("#btn-valid-member").attr("disabled", true)

            if(e.which == 13) {
                check_member()
            }
        })

        $("#btn-submit-interests").on("click", function() {
            let formData = new FormData();
            formData.append('membership_number', membership_number);
            formData.append('interests', interests);
           
            $("#btn-submit-interests").attr("disabled", true)
            $("#form-interest input").attr("disabled", true)
            $(".loading").removeClass("hidden")
            $(".success-alert").remove()
            
            $("#filter-articles").parent().after(`
                <div class='loader-submit row text-center justify-content-center mt-2'><div class='col-5'>
                    <div class="spinner-border loader text-success text-center mt-3" role="status">
                        <span class="visually-hidden"></span>
                    </div>
                </div></div>
            `)

            fetch(`/mccadmin/member_interests`, {
                method: 'POST',
                mode: 'cors',
                cache: 'default',
                body: formData,
            }).then(function(response) {
                $("#form-interest input").attr("disabled", false)
                $(".loading").addClass("hidden")
                $("#btn-submit-interests").attr("disabled", false)
                
                if (response.ok) {
                    $(".loader-submit").remove()

                    $("#filter-articles").parent().after(`<div class='success-alert row justify-content-center mt-2'><div class='col-lg-6 col-lg-3'><div class="alert alert-success">Successfully submitted!</div></div></div>`)
                }
                else {
                    response.json().then(function(data) {
                        $(".alert-danger").removeClass("hidden")
                        $(".alert-danger").text(data.error)
                    })
                }
            })
        })

        function getElement(element_id) {
            JsLoadingOverlay.show({
                "overlayBackgroundColor": "#666666",
                "overlayOpacity": 0.6,
                'spinnerIcon': 'ball-triangle-path',
                "spinnerColor": "#393636",
                "containerID": "modal-category-content"
            });

            fetch(`/mccadmin/interests/${element_id}`, {
                method: 'GET',
                mode: 'cors',
                cache: 'default',
            }).then(function(response) {
                if (response.ok) {
                    response.json().then(function(data) {
                        method = 'update'
                        $('#modal-interest').modal('show')

                        selected_element_id = element_id
                         
                        $("input[name='interest[name]'").val(data.name)
                        $("input[name='interest[active]'").prop("checked", data.active)

                        JsLoadingOverlay.hide();
                    })
                }
            })
        }

        $('#btn-save').on("click", function(e) {
            $("#form-interest").validate()
            $('#form-interest').on("submit", function(e) {
                e.preventDefault()
            })

            if ($("#form-interest").valid()) {
                if (method == 'create')
                    create()
                else if (method == 'update')
                    update()
            }
        })

        function create() {

            let formData = new FormData();
            formData.append('name', $("input[name='interest[name]']").val());
            formData.append('active', $("#interest_active").prop("checked"));
           
            $("#form-interest input").attr("disabled", true)
            $(".loading").removeClass("hidden")

            fetch(`/mccadmin/interests`, {
                method: 'POST',
                mode: 'cors',
                cache: 'default',
                body: formData,
            }).then(function(response) {
                $("#form-interest input").attr("disabled", false)
                $(".loading").addClass("hidden")
                
                if (response.ok) {
                    response.json().then(function(data) {
                        table_interests.ajax.reload()

                        $('#modal-interest').modal('hide')
                    })
                }
                else {
                    response.json().then(function(data) {
                        $(".alert-danger").removeClass("hidden")
                        $(".alert-danger").text(data.error)
                    })
                }
            })
        }

        function update() {
            let formData = new FormData();

            formData.append('name', $("input[name='interest[name]']").val());
            formData.append('active', $("#interest_active").prop("checked"));
            
            $("#form-interest input").attr("disabled", true)
            $(".loading").removeClass("hidden")

            fetch(`/mccadmin/interests/${selected_element_id}`, {
                method: 'PUT',
                mode: 'cors',
                cache: 'default',
                body: formData,
            }).then(function(response) {
                $("#form-interest input").attr("disabled", false)
                $(".loading").addClass("hidden")
                
                if (response.ok) {
                    response.json().then(function(data) {
                        table_interests.ajax.reload()

                        $('#modal-interest').modal('hide')
                    })
                }
                else {
                    response.json().then(function(data) {
                        $(".alert-danger").removeClass("hidden")
                        $(".alert-danger").text(data.error)
                    })
                }
            })
        }

        function destroy(element_id) {

            Swal.fire({
                title: 'Are you sure?',
                text: "You won't be able to revert this!",
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Yes, delete it!',
                allowOutsideClick: false,
                allowEscapeKey: false,
                showLoaderOnConfirm: true,
                preConfirm: () => {

                    if (element_id != null) {
                        return fetch(`/mccadmin/interests/${element_id}`, {
                            method: 'DELETE',
                            mode: 'cors',
                            cache: 'default',
                            headers: {
                                'Accept': 'application/json, text/plain, */*',
                                'Content-Type': 'application/x-www-form-urlencoded'
                            },
                        }).then(function(response) {
                            if (response.ok) {
                               
                                table_interests.ajax.reload()

                                toastr.info('Interest successfully deleted!')
                            }
                        })
                    }
                }
            }).then((result) => {
                if (result.isConfirmed) { 
                }
            })
        }

        function check_member() {
            membership_number = $("#interest-membership_number").val().trim()

            if (membership_number != "") {
                $("#interest-membership_number").attr("disabled", true)
                
                $(".loader").removeClass("d-none")
                $(".alert").remove()

                fetch(`/mccadmin/member_interests/check-member/${membership_number}`, {
                    method: 'GET',
                    mode: 'cors',
                    cache: 'default',
                }).then(function(response) {
                    if (response.ok) {
                        response.json().then(function(data) {
                            console.log(data)
                            
                            $("#btn-valid-member").remove()

                            $(".interest-membership_number").append(`<h3 style="color:#366240">${data.member}</h3>`)
                            $(".list-interests").removeClass("d-none")

                            data.interests.forEach(function(interest) {
                                if (data.member_interests.includes(interest.name)) {
                                    
                                    
                                    $(".interests").append(`<a href="javascript:void(0)" class='article-category interest' data-interest='${interest.name}' >
                                        <li class="selected-interest articles-filter-item">
                                        <i class="fa fa-check-square" style="color: #17A497" aria-hidden="true"></i>
                                            ${interest.name}
                                        </li>
                                    </a>`)

                                    interests.push(interest.name)

                                    $("#btn-submit-interests").attr("disabled", false)
                                }
                                else {
                                    $(".interests").append(`<a href="javascript:void(0)" class='article-category interest' data-interest='${interest.name}' data-id='${interest.id}' >
                                        <li class="list-group-item articles-filter-item">
                                            ${interest.name}
                                        </li>
                                    </a>`)
                                }
                            })

                            console.log(interests)
                        })
                    }
                    else {
                        response.json().then(function(data) {
                            $("#interest-membership_number").attr("disabled", false)
                            $("#interest-membership_number").focus()
                            
                            $(".interest-membership_number").append(`<div class='alert alert-danger mt-2'>${data.error}</div>`)
                        })
                    }

                    
                    $(".loader").addClass("d-none")
                })
            }
        }
    }
})
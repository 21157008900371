document.addEventListener("turbolinks:load", function() {
    if($("body").data("controller") == "home") {
        if ($('#header-about_us')) {
            $('#header-about_us').skdslider({
                slideSelector: '.slide',
                delay:0,
                animationSpeed:0,
                showNextPrev:false,
                showPlayButton:false,
                autoSlide: false,
                animationType:'fading'
            });
        }

        $(".collapse").on('shown.bs.collapse', function () {
            let id = $(this).data("id")

            if ($(`.faq_open_close${id}`).attr("src") == "/assets/faq_plus.png") {
                $(`.faq_open_close${id}`).attr("src", "/assets/faq_min.png")
            }
            else {
                $(`.faq_open_close${id}`).attr("src", "/assets/faq_plus.png")
            }

        })

        $(".collapse").on('hidden.bs.collapse', function () {
            let id = $(this).data("id")

            if ($(`.faq_open_close${id}`).attr("src") == "/assets/faq_plus.png") {
                $(`.faq_open_close${id}`).attr("src", "/assets/faq_min.png")
            }
            else {
                $(`.faq_open_close${id}`).attr("src", "/assets/faq_plus.png")
            }
         })

        
    }
})
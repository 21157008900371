document.addEventListener("turbolinks:load", function() {
    if($("body").data("controller") == "educations") {

        let table_educations = $('#table-educations').DataTable({
            "processing": true,
            "language": {
                processing: '<i class="fa fa-spinner fa-spin fa-3x fa-fw"></i><span class="sr-only">Loading...</span> '
            },
            scrollX:        true,
            paging:         true,
            "serverSide": true,
            "ajax": {
                "url": $('#table-educations').data('source'),
                "data": function(e) {
                    //e.treated = $("#feedback_status_treated").prop("checked")
                }
            },
            "pagingType": "full_numbers",
            "columns": [
                {"data": "full_name"},
                {"data": "email"},
                {"data": "message"},
                {"data": "created_at", 
                    "render": function(data, display, record) {
                        return moment(record.created_at).format("DD-MM-YYYY LT")
                    }
                }
            ],
            createdRow: function (row, data, index) {
                $(row).attr("data-element_id", data.id)
            },
            "fnDrawCallback": function( row, data, index ) {

                //$(newRow).attr("id", `worksite-` + val.id);

                $(`.edit-element`).on("click", function (e) {
                    method = 'edit'
                    let id = $(this).data('element_id')
               
                });

                $('#table-feedbacks').on("click", `.delete-element`, function(e) {

                    let id = $(this).data('element_id')

                    destroy(id)
                })
            },
            initComplete: function( settings, json ) {
               
            },
            "bPaginate": true,
            "iDisplayLength": 50,
            "bFilter": true,
            "bInfo": false,
            "bLengthChange": false,
            "searching": false,
            "destroy": true,
            "stateSave": false,
            "scrollCollapse": true,
            "oLanguage": {
                "sLengthMenu": "_MENU_ ",
                "sInfo": "Showing <b>_START_ to _END_</b> of _TOTAL_ entries"
            },
            "bAutoWidth": false,
            'order' : [[0,'desc']],
            'columnDefs': [
                {
                    'targets': 0,
                    'width': 100
                },
                {
                    'targets': 1,
                    'width': 100
                },
                {
                    'targets': 2,
                    'width': 250
                },
                {
                    'targets': 3,
                    'width': 100
                }
            ],
        });

        $("#btn-back-to-top").on("click", function() {
            $("html, body").animate({ scrollTop: 0 }, "slow");
            return false;
        })

        $("#btn-export").on("click", function() {
            $(this).attr("disabled", true)
            $(".loader-csv").removeClass("hidden")

            tableToCSV()
        })

        
        function downloadCSVFile(csv_data) {
 
            // Create CSV file object and feed
            // our csv_data into it
            CSVFile = new Blob([csv_data], {
                type: "text/csv"
            });
 
            // Create to temporary link to initiate
            // download process
            var temp_link = document.createElement('a');
 
            // Download csv file
            temp_link.download = "EDUCATION.csv";
            var url = window.URL.createObjectURL(CSVFile);
            temp_link.href = url;
 
            // This link should not be displayed
            temp_link.style.display = "none";
            document.body.appendChild(temp_link);
 
            // Automatically click the link to
            // trigger download
            temp_link.click();
            document.body.removeChild(temp_link);

            $("#btn-export").attr("disabled", false)
            $(".loader-csv").addClass("hidden")
        }

        function tableToCSV() {
            
            fetch(`/mccadmin/educations/get_educations_csv`, {
                method: 'GET',
                mode: 'cors',
                cache: 'default',
            }).then(function(response) {
                if (response.ok) {
                    response.json().then(function(data) {
                        console.log(data)
                        let csv_data = [];
     
                        var csvrow = [];
                        csvrow.push('full name');
                        csvrow.push('email');
                        csvrow.push('message');
                        csvrow.push('sent at');

                        csv_data.push(csvrow.join(","));

                        /*var csvrow = [];
                        csvrow.push('title2');
                        csvrow.push('content2');
                        csv_data.push(csvrow.join(","));

                        var csvrow = [];
                        csvrow.push('title3');
                        csvrow.push('content3');
                        csv_data.push(csvrow.join(","));*/

                        //csv_data = csv_data.join('\n');
                        
                        jQuery.each(data, function (i, val) {
                            //console.log(val[0])

                            //var rows = document.getElementsByTagName('tr');
                            //for (var i = 0; i < rows.length; i++) {
                
                                // Get each column data
                               // var cols = rows[i].querySelectorAll('td,th');
                
                                // Stores each csv row data
                                // var csvrow = [];
                                // for (var j = 0; j < val.length; j++) {
                                //     csvrow.push(val[j]);
                                // }
                                
                                var csvrow = [];
                                csvrow.push(`"${val.full_name}"`);
                                csvrow.push(`"${val.email}"`);
                                csvrow.push(`"${val.message}"`)
                                csvrow.push(`"${val.created_at}"`);

                                csv_data.push(csvrow.join(","));

                                // Combine each column value with comma
                                
                            //}
                
                            // Combine each row data with new line character
                            //csv_data = csv_data.join('\n');
                
                            // Call this function to download csv file 
                        
                        })
                       
                        csv_data = csv_data.join('\n');

                        downloadCSVFile(csv_data);
                    })
                }
            })
        }
    }
})
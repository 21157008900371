const { param } = require("jquery");

document.addEventListener("turbolinks:load", function() {
    if($("body").data("controller") == "bookings") {
        let filter_booking = [0, 1]
        let filter_booking_history = ['pending', 'confirmed', 'archived', 'canceled']
        let filter_selectedDates;
        let filter_selectedDates_history;
        let booking_id;
        let state;

        document.addEventListener("turbolinks:before-cache", function() {
            if (table != null) {
                table.destroy();
            }
            if (table_membership_plans != null) {
                table_membership_plans.destroy();
            }

            $.fn.dataTable.ext.search.pop();
        })

        let table = $('#table-bookings').DataTable({
            "processing": true,
            "language": {
                processing: '<i class="fa fa-spinner fa-spin fa-3x fa-fw"></i><span class="sr-only">Loading...</span> '
            },
            "serverSide": true,
            "ajax": {
                "url": $('#table-bookings').data('source'),
                "data": function(e) {
                    e.booking_status = filter_booking
                    e.date = $("#filter-booking_dates").val()
                    e.creator = $("#booking-filter").val()
                    e.categories = $("#booking-filter-categories").val()
                }
            },
            "columns": [
                {"data": "id"},
                {"data": "member",
                    "render": function(data, display, record) {
                        return record.short_notice == "true" ? `<i class="fa-solid fa-clock"></i><a href="/mccadmin/bookings/${record.id}/edit" title="${data}">${data}</a>` : `<a href="/mccadmin/bookings/${record.id}/edit" title="${data}">${data}</a>`
                    }
                },
                {"data": "phone"},
                {"data": "service",
                "render": function(data, display, record) {
                    return record.request != '' ? `<i class='fa-solid fa-circle-exclamation request'></i> ${data}` : data
                }},
                {"data": "internal_notes",
                    "render": function(data, display, record) {
                        return record.internal_notes.replaceAll("\n", "<br>")
                    }
                },
                {"data": "category",
                "render": function(data, display, record) {
                    return `<span title='${data}'>${data}</span`
                }},
                {"data": "city"},
                {"data": "date",
                "render": function(data) {
                        return `<div title='${data}'>${moment.utc(new Date(data)).format("DD-MM-YYYY HH:mm")}</div>`
                    }
                },
                {"data": "state",
                "render": function(data, display, record) {
                    return `${record.state_decorate}`
                }},
                {"data": "creator"},
                {"data": "created_at",
                "render": function(data) {
                        return `<div title='${data}'>${moment.utc(new Date(data)).format("DD-MM-YYYY HH:mm")}</div>`
                    },
                },
                {"data": "actions",
                 "render": function(data, display, record) {
                    let edit = `<a href="/mccadmin/bookings/${record.id}/edit" class="edit-element" title="Edit"><i class="fa-solid fa-pen"></i></a>`
                    let supp = `<a href='javascript:' class='delete-element' title='Delete'><i class='fa-solid fa-trash-can'></i></a>`

                    if (record.creator != `${$("#user_type").attr("data-first_name")} ${$("#user_type").attr("data-last_name")}` && $("#user_type").val() != "administrator") {
                        //edit = `<a href="/mccadmin/bookings/${record.id}/edit" class="edit-element" title="Edit"><i class="fa-solid fa-eye"></i></a>`
                        supp = ''
                    }

                    return `${edit} ${supp}`
                 }
                }
            ],
            createdRow: function (row, data, index) {
                $(row).attr("data-element_id", data.id)
                $(row).attr("data-state", data.state)

                let state_class;

                if (data.state == 'pending')
                    state_class = 'booking_pending'
                else if (data.state == 'confirmed')
                    state_class = 'booking_confirmed'
                else if (data.state == 'fully_booked')
                    state_class = 'archived'
                else if (data.state == 'canceled')
                    state_class = 'booking_canceled'
                
                $(row).addClass(state_class)
            },
            "fnDrawCallback": function( row, data, index ) {

                // $('#table-bookings').on("click", `.delete-element`, function(e) {

                //     let id = $(this).data('element_id')

                //         destroy(id)
              
                // })
            },
            "pagingType": "full_numbers",
            "bPaginate": true,
            "ordering": true,
            "bFilter": true,
            "bInfo": false,
            "bLengthChange": false,
            "pageLength": 50,
            "searching": true,
            "bAutoWidth": false,
            "destroy": true,
            "stateSave": false,
            "scrollCollapse": true,
            "oLanguage": {
                "sLengthMenu": "_MENU_ ",
                "sInfo": "Showing <b>_START_ to _END_</b> of _TOTAL_ entries"
            },
            "columnDefs": [
                { target: 2,
                    visible: false,
                    searchable: true
                },
                { target: 4,
                    visible: false,
                    searchable: false
                },
                { target: 8,
                    "className": "dt-center"
                }
            ],
            'order' : [[6,'asc']]
        });
        $("#table-bookings_wrapper > div > .col-sm-6:nth-child(1)").addClass("content-left pt-2")
        
        $("#table-bookings_wrapper > div:nth-child(1) > .col-sm-6:nth-child(1)").removeClass("col-sm-6")
        $("#table-bookings_wrapper > div:nth-child(1) > div:nth-child(1)").addClass("col-sm-10")
        $("#table-bookings_wrapper > div:nth-child(1) > .col-sm-6:nth-child(2)").removeClass("col-sm-6")
        $("#table-bookings_wrapper > div:nth-child(1) > div:nth-child(2)").addClass("col-sm-2")

        // $("#table-bookings_wrapper > .row > div:first").append(`<div class="col-3">
           
        // </div>`)
        $("#table-bookings_wrapper > .row > div:first").append("<div><span>" +
            "<div class='form-check checkbox-circle complete form-check-inline'>" + 
                "<input type='checkbox' name='booking_status' id='member_status_pending' value='0' checked>" +
                "<label for='member_status_pending'>" + 
                    "Pending " +
                "</label>" +
            "</div>" + 
            "<div class='form-check checkbox-circle complete form-check-inline'>" + 
                "<input type='checkbox' name='booking_status' id='member_status_confirmed' value='1' checked>" +
                "<label for='member_status_confirmed'>" + 
                    "Confirmed" +
                "</label>" +
            "</div>" + 
            "<div class='form-check checkbox-circle complete form-check-inline'>" + 
                "<input type='checkbox' name='booking_status' id='member_status_archived' value='2'>" +
                "<label for='member_status_archived'>" + 
                    "Fully booked" +
                "</label>" +
            "</div>" + 
            "<div class='form-check checkbox-circle complete form-check-inline'>" + 
                "<input type='checkbox' name='booking_status' id='member_status_canceled' value='3'>" +
                "<label for='member_status_canceled'>" + 
                    "Canceled" +
                "</label>" +
            "</div>" +
            "<div class='form-check checkbox-circle complete form-check-inline'>" + 
                "<input type='checkbox' name='booking_status' id='member_status_misc' value='4'>" +
                "<label for='member_status_misc'>" + 
                    "Misc" +
                "</label>" +
            "</div>" +
            "<div class='form-check checkbox-circle complete form-check-inline'>" + 
                "<input type='checkbox' name='booking_status' id='member_status_shortnotice' value='5'>" +
                "<label for='member_status_shortnotice'>" + 
                    "Short notice" +
                "</label>" +
            "</div>" +
            `<input type='text' id='filter-booking_dates' class='form-control' style='display: inline-block !important; width: 200px;' >` +
        "</span></div>")
        

        $("#booking_show_notes").on("click", function(e) {


            let columnIdx = 4;
            let column = table.column(columnIdx);

            if ($(this).prop("checked")) {
                column.visible(true);
                
                table.column(5).visible(false)

                table.column(8).visible(false)
                table.column(9).visible(false)
                table.column(10).visible(false)
            }
            else {  
                column.visible(false);

                table.column(5).visible(true)
                
                table.column(8).visible(true)
                table.column(9).visible(true)
                table.column(10).visible(true)
            }
        })

        let table_booking_history = $('#table-booking_history').DataTable({
            'initComplete': function(settings){
                var api = this.api();
       
                api.cells(
                   api.rows(function(idx, data, node){
                      //return ($(node).data('related')) ? true : false;
                      return ($(node).data('type') === 'old') ? true : false;
                   }).indexes(),
                   0
                ).checkboxes.disable();
                
            },
            'columnDefs': [
                {
                   'targets': 0,
                   'checkboxes': {
                        'selectRow': false,
                        'selectCallback': function(nodes, selected) {
                            refresh_selected_elements()
                        }
                    },
                    'createdCell':  function (td, cellData, rowData, row, col){
                        // var api = this.api();
       
                        // api.cells(
                        //    api.rows(function(idx, data, node){
                        //       //return ($(node).data('related')) ? true : false;
                        //       return ($(node).data('type') === 'old') ? false : true;
                        //    }).indexes(),
                        //    0
                        // ).checkboxes.disable();
                     
                     },           
                }
            ],
            // createdRow: function (row, data, index) {
            //     var api = this.api();
       
            //     api.cells(
            //         api.rows(function(idx, data, node){
            //             //return ($(node).data('related')) ? true : false;
            //             //console.log($(row).attr('data-type'))

            //             return ($(row).data('type') === 'old') ? true : false;
            //         }).indexes(),
            //         0
            //     ).checkboxes.disable();

            //     //$('input', row).prop('disabled', true);
            // },
            'select': {
                'style': 'multi',
                "selector": 'td:first-child'
            },
            "bPaginate": false,
            "bFilter": true, 
            //"pageLength": 50,
            "bInfo": false,
            "bLengthChange": false,
            "searching": true,
            "destroy": true,
            "stateSave": false,
            "scrollCollapse": true,
            "oLanguage": {
                "sLengthMenu": "_MENU_ ",
                "sInfo": "Showing <b>_START_ to _END_</b> of _TOTAL_ entries"
            },
            "bAutoWidth": false,
            'order' : [[6,'desc']]
        });
        $("#table-booking_history_wrapper > div > .col-sm-6:nth-child(1)").addClass("content-left pt-2")
        $("#table-booking_history_wrapper > .row > div:first").append(`<div class="col-3">
            <select id='booking_history-filter' class="full-width" data-init-plugin="select2" multiple placeholder='Creators'>
            </select>
        </div>`)
        $("#table-booking_history_wrapper > .row > div:first").append("<div><span>" +
            "<div class='form-check checkbox-circle complete form-check-inline pr-0'>" + 
                "<input type='checkbox' name='booking_history_status' id='member_status_pending' value='pending' checked>" +
                "<label for='member_status_pending'>" + 
                    "Pending " +
                "</label>" +
            "</div>" + 
            "<div class='form-check checkbox-circle complete  form-check-inline '>" + 
             "<input type='checkbox' name='booking_history_status' id='member_status_confirmed' value='confirmed' checked>" +
                "<label for='member_status_confirmed'>" + 
                    "Confirmed" +
                "</label>" +
            "</div>" + 
            "<div class='form-check checkbox-circle complete  form-check-inline '>" + 
             "<input type='checkbox' name='booking_history_status' id='member_status_archived' value='archived' checked>" +
                "<label for='member_status_archived'>" + 
                    "Fully booked" +
                "</label>" +
            "</div>" + 
            "<div class='form-check checkbox-circle complete  form-check-inline '>" + 
            "<input type='checkbox' name='booking_history_status' id='member_status_canceled' value='canceled' checked>" +
                "<label for='member_status_canceled'>" + 
                    "Canceled" +
                "</label>" +
            "</div>" +
            `<input type='text' id='filter-booking_history_dates' class='form-control' style='display: inline-block !important; width: 180px;' >` +
        "</span></div>")

        let table_bookmarks = $('#table-bookmarks').DataTable({
            "processing": true,
            "language": {
                processing: '<i class="fa fa-spinner fa-spin fa-3x fa-fw"></i><span class="sr-only">Loading...</span> '
            },
            "serverSide": true,
            "ajax": {
                "url": $('#table-bookmarks').data('source'),
                "data": function(e) {
                    e.member_id = $("#booking_member_id").val()
                }
            },
            "columns": [
                {"data": "service",
                    "render": function(data, display, record) {
                        return `<a href="/mccadmin/cities/posts/${record.post_id}/edit" target='new'>${data}</a>`
                    }
                },
                {"data": "city"},
            ],
            createdRow: function (row, data, index) {
                // $(row).attr("data-element_id", data.id)
                // $(row).attr("data-state", data.state)

                // let state_class;

                // if (data.state == 'pending')
                //     state_class = 'booking_pending'
                // else if (data.state == 'confirmed')
                //     state_class = 'booking_confirmed'
                // else if (data.state == 'archived')
                //     state_class = 'archived'
                // else if (data.state == 'canceled')
                //     state_class = 'booking_canceled'
                
                // $(row).addClass(state_class)
            },
            "fnDrawCallback": function( row, data, index ) {
                // $('#table-bookings').on("click", `.delete-element`, function(e) {

                //     let id = $(this).data('element_id')

                //         destroy(id)
              
                // })
            },
            "pagingType": "full_numbers",
            "bPaginate": true,
            "ordering": true,
            "bFilter": true,
            "bInfo": false,
            "bLengthChange": false,
            "pageLength": 50,
            "searching": false,
            "bAutoWidth": false,
            "destroy": true,
            "stateSave": false,
            "scrollCollapse": true,
            "oLanguage": {
                "sLengthMenu": "_MENU_ ",
                "sInfo": "Showing <b>_START_ to _END_</b> of _TOTAL_ entries"
            },
            "columnDefs": [
                //{ className: "text-center", targets: [5]},
            ],
            'order' : [[0,'desc']]
        });

        let filter_booking_date = flatpickr("#filter-booking_dates", {
            mode: "range",
            dateFormat: "Y-m-d",
            allowInput: false,
            disableMobile: true,
            allowInvalidPreload: true,
            defaultDate: [new Date(new Date().setMonth(new Date().getMonth())), new Date(new Date().setMonth(new Date().getMonth()+2, 0))],
            onOpen: function(selectedDates, dateStr, instance) {
                filter_selectedDates = selectedDates
            },
            onClose: function(selectedDates, dateStr, instance) {
                if (selectedDates.length != 2) {
                    this.setDate(filter_selectedDates, true, "Y-m-d")
                }
            },
            onChange: function(selectedDates, dateStr, instance) {
                if (selectedDates.length == 2) {
                    //getBookings(moment(selectedDates[0]).format('YYYY-MM-DD'), moment(selectedDates[1]).format('YYYY-MM-DD'))
                    table.ajax.reload()
                }
            },
        })

        $("#table-bookings tbody").on("click", ".change_booking_state", function() {
            state = $(this).parent().parent().attr('data-state')
            booking_id = $(this).parent().parent().attr('data-element_id')

            $(".booking_state").html(`<span class="booking-state_${state}"></span><span class='ml-3 booking-state_label text-left'>${state.substring(0,1).toUpperCase()+state.substring(1)}</span>`)
            $("#bookign_state_change").val(state)

            $('#modal-change_state').modal('show')
        })

        $("#btn-save-booking_state").on("click", function() {
            
            Swal.fire({
                title: 'Are you sure to change the state?',
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Yes, change it!',
                allowOutsideClick: false,
                allowEscapeKey: false,
                showLoaderOnConfirm: true,
                preConfirm: () => {
                    return fetch(`/mccadmin/bookings/change-state/${booking_id}/${$("#bookign_state_change").val()}`, {
                        method: 'PUT',
                        mode: 'cors',
                        cache: 'default',
                        headers: {
                            'Accept': 'application/json, text/plain, */*',
                            'Content-Type': 'application/x-www-form-urlencoded'
                        },
                    }).then(function(response) {
                        if (response.ok) {
                            $('#modal-change_state').modal('hide')

                            response.json().then(function(data) {
                                let new_state;
                                if (data.state == 'pending')
                                    new_state = 'warning'
                                else if (data.state == 'confirmed')
                                    new_state = 'info'
                                else if (data.state == 'fully_booked')
                                    new_state = 'success'
                                else if (data.state == 'canceled')
                                    new_state = 'danger'
                            
                                $(`#table-bookings tr[data-element_id='${data.id}'] td:nth-child(6)`).html(`<span class='label badge-${new_state} '>${data.state}</span>`);
                                $(`#table-bookings tr[data-element_id='${data.id}']`).attr('data-state', data.state);
                                $(`#table-bookings tr[data-element_id='${data.id}']`).removeClass('booking_' + state)
                                $(`#table-bookings tr[data-element_id='${data.id}']`).addClass('booking_' + data.state)
                            })
                        }
                        else {
                            
                        }
                    })
                }
            }).then((result) => {
                if (result.isConfirmed) {
                }
            })
        })
        
        $("#booking-filter").select2({
            minimumResultsForSearch: -1,
            placeholder: "Creators"
        });

        $("#booking_history-filter").select2({
            minimumResultsForSearch: -1,
            placeholder: "Creators"
        });

        $("#booking-filter").attr('disabled', true)
        $("#booking_history-filter").attr('disabled', true)

        // toggle user
        $("#booking-filter").on("select2:select", function(e) {
            let users_id = $(this).val()

            $.fn.dataTable.ext.search.pop();
            $.fn.dataTable.ext.search.push(
                function(settings, data, dataIndex) {
                    let users = $(table.row(dataIndex).node()).attr('data-user').split(',')

                    for (user of users) {
                        if (users_id.includes(user) && filter_booking.includes($(table.row(dataIndex).node()).attr('data-state')))
                            return true
                        else
                            false
                    }
                }
            );

            table.draw();
        })
        $("#booking_history-filter").on("select2:select", function(e) {
            if (table_booking_history.data().count() > 0) {
                let users_id = $(this).val()

                $.fn.dataTable.ext.search.pop();
                $.fn.dataTable.ext.search.push(
                    function(settings, data, dataIndex) {
                        if ($(table_booking_history.row(dataIndex).node()).attr('data-user') != undefined) {
                            let users = $(table_booking_history.row(dataIndex).node()).attr('data-user').split(',')

                            for (user of users) {
                                if (users_id.includes(user) && filter_booking.includes($(table_booking_history.row(dataIndex).node()).attr('data-state')))
                                    return true
                                else
                                    false
                            }
                        }
                    }
                );

                table_booking_history.draw();
            }
        })

        $("#booking-filter").on("select2:unselect", function(e) {
            let users_id = $(this).val()
            if (users_id.length == 0) {
                    table.draw();
            }
            else {
                $.fn.dataTable.ext.search.push(
                    function(settings, data, dataIndex) {
                        let users = $(table.row(dataIndex).node()).attr('data-user').split(',')

                        for (user of users) {
                            if (users_id.includes(user))
                                return true
                            else
                                false
                        }
                    }
                );
            
                table.draw();
            }
        })
        
        $("#booking_history-filter").on("select2:unselect", function(e) {
            let users_id = $(this).val()
            if (users_id.length == 0) {
                table_booking_history.draw();
            }
            else {
                $.fn.dataTable.ext.search.push(
                    function(settings, data, dataIndex) {
                        let users = $(table_booking_history.row(dataIndex).node()).attr('data-user').split(',')

                        for (user of users) {
                            if (users_id.includes(user))
                                return true
                            else
                                false
                        }
                    }
                );
            
                table_booking_history.draw();
            }
        })
        
        $("#booking-filter").on("select2:closing", function(e) {
            if ($("#booking-filter").val().length == 0) {
                filterBooking(table, filter_booking, $("#booking-filter").val())
            }
        })
        $("#booking_history-filter").on("select2:closing", function(e) {
            if ($("#booking_history-filter").val().length == 0) {
                filterBooking(table_booking_history, filter_booking_history, $("#booking_history-filter").val())
            }
        })

        $("#booking-filter-categories").select2({
            minimumResultsForSearch: -1,
            placeholder: "Filter Categories"
        });

        $("#booking-filter-categories").on("select2:unselect", function(e) {
            table.ajax.reload()
        })

        $("#booking-filter-categories").on("select2:select", function(e) {
            table.ajax.reload()
        })

        function getCategories() {
            //let heading = $("input[name='post[heading]']").data("heading");
            let url = `/mccadmin/cities/categories/getAll`;
 
            fetch(url, { 
                method: 'GET',
                mode: 'cors',
                cache: 'default',
            }).then(function(response) {
                if (response.ok) {
                    let categories;
                    response.json().then(function(data) {
                        var treeData = []

                        $.each(data.categories1, function(index, rowId){
                            treeData.push(data.categories1[index][0])
                        });

                        jQuery.each(data.all, function (i, val) {
                            $("#booking-filter-categories").append(`<option value='${val.id}'>${val.title}</option>`)
                        })
                        $("#booking-filter-categories option:first").trigger("update")

                    })
               
                }
            })

        }

        getCategories()

        let table_cc = $('#table-cc').DataTable({
            "bPaginate": false,
            "bFilter": true,
            "bInfo": false,
            "bLengthChange": false,
            "searching": false,
            "destroy": true,
            "stateSave": false,
            "scrollCollapse": true,
            "oLanguage": {
                "sLengthMenu": "_MENU_ ",
                "sInfo": "Showing <b>_START_ to _END_</b> of _TOTAL_ entries"
            },
            "bAutoWidth": false,
            'order' : [[0,'desc']]
        });


        let table_membership_plans = $('#table-membership_plans').DataTable({
            "bPaginate": false,
            "bFilter": true,
            "bInfo": false,
            "bLengthChange": false,
            "searching": false,
            "destroy": true,
            "stateSave": false,
            "scrollCollapse": true,
            "oLanguage": {
                "sLengthMenu": "_MENU_ ",
                "sInfo": "Showing <b>_START_ to _END_</b> of _TOTAL_ entries"
            },
            "bAutoWidth": false,
            'order' : [[0,'desc']]
        });
        $("#table-membership_plans_wrapper > div > .col-sm-6:nth-child(1)").addClass("content-left pt-2")

        let filter_booking_history_date = flatpickr("#filter-booking_history_dates", {
            mode: "range",
            dateFormat: "Y-m-d",
            allowInput: false,
            allowInvalidPreload: true,
            defaultDate: [new Date(new Date().setMonth(new Date().getMonth()-2)), new Date()],
            onOpen: function(selectedDates, dateStr, instance) {
                filter_selectedDates_history = dateStr.split(' to ')
            },
            onClose: function(selectedDates, dateStr, instance) {
                if (selectedDates.length != 2) {
                    this.setDate(filter_selectedDates_history, true, "Y-m-d")
                }
            },
            onChange: function(selectedDates, dateStr, instance) {
                if (selectedDates.length == 2) {
                    let selectedDate = dateStr.split(' to ')
                    getBookingsHistory(moment(selectedDate[0]).format('YYYY-MM-DD'), moment(selectedDate[1]).format('YYYY-MM-DD'))
                }
            },
        })

        $("input[name='booking_status']").on("change", function(e) {
            let state = parseInt($(this).val())

            if ($(this).prop("checked") === true) {
                filter_booking.push(state)
            }
            else {
                filter_booking.splice(filter_booking.indexOf(state), 1)
            }
            //filterBooking(table, filter_booking, $("#booking-filter").val())

            table.ajax.reload()
        })
        
        $("input[name='booking_history_status']").on("change", function(e) {
            let state = $(this).val()
            
            if ($(this).prop("checked") === true) {
                filter_booking_history.push(state)
            }
            else {
                filter_booking_history.splice(filter_booking_history.indexOf(state), 1)
            }

            
            //filterBooking(table_booking_history, filter_booking_history, $("#booking_history-filter").val())
        })
        
        function filterBooking(table, filter, users_id) {

            //let users_id = $("#booking-filter").val()

            // if (users_id.length == 0) {
            //         table.draw();
            // }
            // else {
            //     $.fn.dataTable.ext.search.push(
            //         function(settings, data, dataIndex) {
            //             let users = $(table.row(dataIndex).node()).attr('data-user').split(',')

            //             for (user of users) {
            //                 if (users_id.includes(user))
            //                     return true
            //                 else
            //                     false
            //             }
            //         }
            //     );
            
            //     table.draw();
            // }

            if(table.data().count() > 0) {
                $.fn.dataTable.ext.search.pop();
                $.fn.dataTable.ext.search.push(
                    function(settings, data, dataIndex) {
                        if ($(table.row(dataIndex).node()).attr('data-user') != undefined) {
                            let users = $(table.row(dataIndex).node()).attr('data-user').split(',')

                            if (users_id.length > 0) {
                                for (user of users) {
                                    if (users_id.includes(user) && filter.includes($(table.row(dataIndex).node()).attr('data-state')))
                                        return true
                                    else
                                        false
                                }
                            }
                            else {
                                return filter.includes($(table.row(dataIndex).node()).attr('data-state'));
                            }
                        }
                    }
                );
                    
                table.draw();
            }
        }

        $("#booking-city, #booking-service_option, #booking_creator").select2({
            minimumResultsForSearch: ($(this).attr('data-disable-search') == 'true' ? -1 : 1)
        });

        $("#booking-city").select2({
            placeholder: "Select a city"
        });

        // $("#booking-service").select2({
        //     placeholder: "Select a service"
        // })

        FilePond.registerPlugin(FilePondPluginImagePreview, FilePondPluginFileValidateSize, FilePondPluginFileValidateType);

        const pond_image = FilePond.create(document.querySelector('#booking-image'), {
            acceptedFileTypes: ['image/*'],
            labelIdle: "Drag & Drop your image or <span class='filepond--label-action'> Browse </span>",
            imagePreviewMinWidth: 800,

            allowProcess: false,
            instantUpload: false,
            allowReorder: true,
            name: 'booking[image]',
            storeAsFile: true,
            credits: {label: "", url: ""} ,
            maxFileSize: 250000,
        });

        var booking_date = flatpickr("#booking_date", {
            enableTime: true,
            dateFormat: "Y-m-d H:i",
            time_24hr: true,
            disableMobile: true,
            allowInput: false,
            onClose: function(selectedDate, dateStr, instance) {

                if ($("#booking_post_id").val() != "" && document.querySelector('#booking_date')._flatpickr.selectedDates[0] != undefined) {
                    generateCalendarUrl()
                }
            }
        })

        var booking_checkout_date = flatpickr("#booking_checkout_date", {
            enableTime: true,
            dateFormat: "Y-m-d H:i",
            time_24hr: false,
            allowInput: false,
            onClose: function(selectedDate, dateStr, instance) {

                /*if ($("#booking_post_id").val() != "" && document.querySelector('#booking_checkout_date')._flatpickr.selectedDates[0] != undefined) {
                    generateCalendarUrl()
                }*/
            }
        })

        $('#booking-service').on("select2:select", function(e) {
            $("#booking_post_id").val($(this).val())

            if (document.querySelector('#booking_date')._flatpickr.selectedDates[0] != undefined && $("#booking_post_id").val() != "") {
                generateCalendarUrl()
            }
            

            /* 
            fetch(`/mccadmin/cities/posts/get-cities/${$(this).val()}`, {
                method: 'GET',
                mode: 'cors',
                cache: 'default',
            }).then(function(response) {
                if (response.ok) {
                    response.json().then(function(data) {
                        $('#booking-city').empty().trigger('change');
                        for(val of data) {
                            $("#booking-city").prepend(new Option(val.city.name, val.city.id, false, false)).trigger('change');
                        }
                    })
                }
                else {
                    
                }
            })
            */
        })

        function generateCalendarUrl() {
            $(".booking-calendar-loader").removeClass("hidden")

            let formData = new FormData();
            //console.log(moment(document.querySelector('#booking_date')._flatpickr.selectedDates[0]).format("YYYY-MM-DD HH:mm"))
            
            formData.append('date', moment(document.querySelector('#booking_date')._flatpickr.selectedDates[0]).format("YYYY-MM-DD HH:mm"));
            formData.append('post_id', $("#booking_post_id").val());
            
            fetch(`/mccadmin/bookings/generate-calendar-url`, {
                method: 'POST',
                mode: 'cors',
                cache: 'default',
                body: formData,
            }).then(function(response) {
                if (response.ok) {
                    response.json().then(function(data) {
                        $(".calendar_urls").removeClass("hidden")
                        $(".google_calendar_url").html(`<a href='${data.url_google}' target='new'>${data.url_google}</a><br>`)
                        $(".qrcode_url").html(`<img src='/assets/loading.svg' class='booking-calendar-loader hidden' width='32'>`)
                        $(".qrcode_url").html(`<img src='${data.url_qrcode}'>`)

                        $("input[name='booking[calendar]']").val(data.url_google)
                        $("input[name='booking[qrcode]']").val(data.url_qrcode)

                        $(".booking-calendar-loader").addClass("hidden")
                    })
                }
                else {

                }
            })
        }

        var booking_reminder = flatpickr("#booking_reminder", {
            enableTime: true,
            dateFormat: "Y-m-d H:i",
            time_24hr: true,
            allowInput: true,
            onOpen: function(selectedDates, dateStr, instance) {
                //booking_reminder.set("minDate", new Date());
            },
            onClose: function(selectedDates, dateStr, instance) {
                
            },
            onChange: function(selectedDates, dateStr, instance) {
               
            },
        })

        $('.btn-state').on('click', function(e) {
            $("#booking-state").val($(e.currentTarget).data('state'))
            $('.booking-state_label').text($(e.currentTarget).children().eq(1).text())
            $('.booking-state_label').siblings('span').attr('class', '').toggleClass($(e.currentTarget).children().eq(0).attr('class'))
            
            $("#booking_state").val($(e.currentTarget).data('state'))

            if ($(e.currentTarget).data('state') == 'confirmed') {
                $("#booking_cancelation_policy").attr("required", true)
            }
            else {
                $("#booking_cancelation_policy").attr("required", false)
            }
        })

        $('.btn-change_state').on('click', function(e) {
            $("#bookign_state_change").val($(e.currentTarget).data('state'))
            $('.booking-state_label').text($(e.currentTarget).children().eq(1).text())
            $('.booking-state_label').siblings('span').attr('class', '').toggleClass($(e.currentTarget).children().eq(0).attr('class'))
            
            $("#bookign_state_change").val($(e.currentTarget).data('state'))
        })

        // get membership names
        // $('#booking_member_name').autocomplete({
        //     serviceUrl: '/mccadmin/members/get-members-name',
        //     dataType: 'json',
        //     minChars: 2,
        //     width: 450,
        //     onSelect: function (suggestion) {
        //         $("#booking_member_id").val(suggestion.id)
        //         $("#booking_membership_number").val(suggestion.data.membership_number)
        //         $("#booking_phone").val(suggestion.data.phone)
        //         $("#booking_email").val(suggestion.data.email)

        //         $(".member_mermbership").text(suggestion.data.membership_number)
        //         $("#member_name").val(suggestion.value)
        //         $("#member_phone").val(suggestion.data.phone)
        //         $("#member_email").val(suggestion.data.email)
        //         $("#member_address").val(suggestion.data.address)
        //     }
        // });

        

        $(".btn-image-delete").on("click", function(){
            let id = $("#booking_id").val()
            
            Swal.fire({
                title: 'Are you sure?',
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Yes, delete it!',
                allowOutsideClick: false,
                allowEscapeKey: false,
                showLoaderOnConfirm: true,
                preConfirm: () => {
                    return fetch(`/mccadmin/bookings/delete-photo/${id}`, {
                        method: 'DELETE',
                        mode: 'cors',
                        cache: 'default',
                        headers: {
                            'Accept': 'application/json, text/plain, */*',
                            'Content-Type': 'application/x-www-form-urlencoded'
                        },
                    }).then(function(response) {
                        if (response.ok) {
                            $(".featured-image").remove()
                        }
                        else {
                            
                        }
                    })
                }
            }).then((result) => {
                if (result.isConfirmed) {
                }
            })
        })
        
        $('#booking-member_name').select2({
            placeholder: "Select a member",
            ajax: {
                url: '/mccadmin/members/get-members-name',
                dataType: 'json',
                data: function (params) {
                    $(".select2-results__options").append("<img src='/assets/loader'>")
                    var query = {
                      search: params.term,
                      type: 'public'
                    }
                    return query;
                },
                processResults: function (data, params) {
                    params.page = params.page || 1;
                    return {
                        results: data.items,
                        pagination: {
                            more: (params.page * 30) < data.total_count
                        }
                    };
                }
            },
            cache: true,
            minimumInputLength: 2,
            templateResult: function (repo) {
                if (repo.loading) {
                    return repo.name;
                }
                
                var $container = $(
                    "<div class='select2-result-repository clearfix'>" +
                        "<div class='select2-result-repository__meta'>" +
                            "<div class='select2-result-repository__title'></div>" +
                        "</div>" +
                    "</div>"
                );
                
                let feedback = ""

               
                
                $container.find(".select2-result-repository__title").text(repo.name);
                
                return $container;
            },
            templateSelection: function(repo) {
                if (repo.data != undefined){
                   // console.log(repo.data)
                    $("#booking_member_id").val(repo.data.id)
                    $("#booking_membership_number").val(repo.data.membership_number)
                    $("#booking_phone").val(repo.data.phone)
                    $("#booking_email").val(repo.data.email)

                    if (repo.data.nearest_booking.length != 0) {
                        $(".nearest_booking").removeClass("hidden")
                        $("#nearest_booking").val('- ' + repo.data.nearest_booking.service.title + ". \n- " + repo.data.nearest_booking.city + "\n- " + moment(repo.data.nearest_booking.date).format("DD MMMM YYYY"))
                    }
                    else {
                        $(".nearest_booking").addClass("hidden")
                    }

                    if (repo.name.includes("(no subscription)") || repo.name.includes("(expired)") || repo.name.includes("(canceled)")) {
                        $("#btn-save-booking").addClass("disabled")
                        $("#btn-save-booking").attr("disabled", true)
                        $(".error-member-state").html("<b>Unable to save because the member subscription is not active!</b>")
                    }
                    else {
                        $("#btn-save-booking").removeClass("disabled")
                        $("#btn-save-booking").attr("disabled", false)
                        $(".error-member-state").html("")
                    }

                    table_bookmarks.ajax.reload()
                }
        
                let feedback = ""

                if (repo.feedback) {
                    feedback = "🚨"
                    $(".feedback_exists").removeClass("hidden")
                }
                else {
                    $(".feedback_exists").addClass("hidden")
                }

                return repo.name || repo.text + feedback;
            }
        });
        
        $(".btn-see-old_booking").on("click", function(e) {
            let id = $(this).data('id')

            ShowOldBooking(id)
        })

        $('#booking-member_name').on("select2:select", function(e) {
            $("#member_status_pending").prop('checked', true)
            $("#member_status_confirmed").prop('checked', true)
            $("#member_status_archived").prop('checked', true)
            $("#member_status_canceled").prop('checked', true)
            filter_booking_history = ['pending', 'confirmed', 'archived', 'canceled']
            $('#booking_history-filter').val(null).trigger('change');
            $.fn.dataTable.ext.search.pop();
            table_booking_history.draw();

            $("#booking_member_id").val($(this).val())
            let member_id = $(this).val()

            table_cc.rows().clear().draw();
            table_booking_history.rows().clear().draw();
            table_membership_plans.rows().clear().draw();

            fetch(`/mccadmin/members/${member_id}`, {
                method: 'GET',
                mode: 'cors',
                cache: 'default',
                type: 'application/json',
            }).then(function(response) {
                if (response.ok) {
                    response.json().then(function(data) {
                        $(".member_mermbership").text(data.member.membership_number)
                        $("#member_gender").html(`${data.member.decorate_gender}`)
                        $("#member_birthday").html(`<b>${moment(data.member.birthday).format("DD MMMM YYYY")}</b>`)
                        $("#member_name").val(`${data.member.decorate_full_name}`)
                        $("#member_phone").val(data.member.phone)
                        $("#member_info_country").val(data.member.country_full_name)
                        $("#member_phone2").val(data.member.second_phone)
                        $("#member_email").val(data.member.email)
                        $("#member_email2").val(data.member.second_email)
                        $("#member_address").val(data.member.address)
                        $("#member_diet").val(data.member.diet)
                        $("#member_current_location").val(data.member.current_location)
                        $(".member_mermbership_link").attr("href", "/members/members/cc/" + data.member.membership_number)
                        $(".member_mermbership_link").removeClass('hidden')

                        // credit cards
                        for(let cc of data.member.booking_credit_cards) {
                            let newRow = table_cc.row.add( [
                                cc.credit_card_number,
                                cc.name_on_card,
                                cc.expire,
                                cc.zip_code
                            ] ).draw( false ).node();
    
                            $(newRow).attr("data-cc_element_id", cc.id);
                        }

                        // bookings
                        for(let booking of data.bookings) {
                            if (booking.id != $("#booking_id").val()) {
                                let newRow = table_booking_history.row.add( [
                                    booking.id,
                                    booking.id,
                                    booking.post.title,
                                    booking.city,
                                    moment.utc(new Date(booking.date)).format("DD MMM HH:mm"),
                                    `${booking.user.first_name} ${booking.user.last_name}`,
                                    moment.utc(new Date(booking.created_at)).format("YYYY-MM-DD HH:mm"),
                                    booking.decorate_state,
                                    `<a href="/mccadmin/bookings/${booking.id}/edit" target='new' class="edit-element" title="See"><i class="fa-solid fa-eye"></i></a>`
                                ] ).draw( false ).node();
        
                                $(newRow).attr("data-booking_element_id", booking.id);
                                $(newRow).attr("class", `booking_${booking.state}`);
                                $(newRow).attr("data-state", booking.state);
                                $(newRow).data("state", booking.state);
                                $(newRow).attr("data-user", booking.user.id);
                                $(newRow).data("user", booking.user.id);
                            }
                        }
                        
                        // booking histories
                        
                        for(let booking_history of data.booking_histories) {
                            let newRow = table_booking_history.row.add( [
                                booking_history.id,
                                booking_history.id,
                                booking_history.service,
                                booking_history.city,
                                moment.utc(new Date(booking_history.date)).format("DD MMM HH:mm"),
                                '',
                                moment.utc(new Date(booking_history.created_at)).format("YYYY-MM-DD HH:mm"),
                                booking_history.state,
                                `<a href="javascript:void(0)" data-id='${booking_history.id}' class="btn-see-old_booking" title="See Booking"><i class="fa-solid fa-eye"></i></a>`,
                            ] ).draw( false ).node();
    
                            $(newRow).attr("data-booking_element_id", booking_history.id);
                            $(newRow).attr("class", `booking_${booking_history.state} old`);
                            $(newRow).css("background-color", `#e9e9e9`);
                            $(newRow).attr("data-type", `old`);
                            $(newRow).data("type", `old`);
                            $(newRow).data("user", "1");
                           // $(newRow).find('.dt-checkboxes').attr('disabled', true)

                            $(`.btn-see-old_booking[data-id='${booking_history.id}']`).on("click", function(e) {
                                ShowOldBooking(booking_history.id)
                            })

                            var api = $.fn.dataTable.Api(table_booking_history)
                            
                            api.cells(
                                api.rows(function(idx, data, node){
                                    //console.log($(node).attr('data-type'))
            
                                    return ($(node).data('type') === 'old') ? true : false;
                                }).indexes(),
                                0
                            ).checkboxes.disable();

                            refresh_selected_elements()
                        }

                        // memberships plans
                        for(let subscribtion of data.subscriptions) {
                            let newRow = table_membership_plans.row.add( [
                                subscribtion.membership_plan.name,
                                subscribtion.membership_plan.price_cents,
                                moment.utc(new Date(subscribtion.start)).format("DD MMM YYYY HH:mm"), 
                                moment.utc(new Date(subscribtion.expire)).format("DD MMM YYYY HH:mm"),
                                subscribtion.payment_method_decorate,
                                subscribtion.status_decorate
                            ] ).draw( false ).node();
                        }
                    })
                }
                else {
                }
            })
        })

        $("#btn-copy-link").on("click", function() {
            navigator.clipboard.writeText($(".member_mermbership_link").attr("href"));
            toastr.success("Link copied to clipboard!", {timeOut: 6000})
        })


        let booking_service = $('#booking-service').select2({
            ajax: {
                url: '/mccadmin/cities/posts/get-posts-name',
                dataType: 'json',
                data: function (params) {
                    $(".select2-results__options").append("<img src='/assets/loader'>")
                    var query = {
                      search: params.term,
                      type: 'public'
                    } 
                    return query;
                },
                processResults: function (data, params) {

                    params.page = params.page || 1;
                    return {
                        results: data.items,
                        pagination: {
                          more: (params.page * 30) < data.total_count
                        }
                    };
                }
            },
            cache: true,
            minimumInputLength: 2,
            templateResult: function (repo) {
                
                if (repo.loading) {
                  return repo.name;
                }
              
                var $container = $(
                    "<div class='select2-result-repository clearfix'>" +
                        "<div class='select2-result-repository__meta'>" +
                            "<div class='select2-result-repository__title'></div>" +
                        "</div>" +
                    "</div>"
                );
              
                $container.find(".select2-result-repository__title").text(repo.name);
                
                return $container;
            },
            templateSelection: function(repo) {
                
                if (repo.is_hotel != undefined) {
                    if (repo.is_hotel) {
                        $(".booking_checkout_date_container").removeClass("hidden")
                        $("#booking_checkout_date").val("")
                    }
                    else {
                        $(".booking_checkout_date_container").addClass("hidden")
                    }
                }

                if (repo.data != null) { 
                    $(".post_instructions").text("")
                    if (repo.data.instructions != null) {
                        $(".post_instructions").text(repo.data.instructions) 
                    }
                }

                return repo.name || repo.text;
            },
            formatResult: function (item) {
             
                return '<div class="sonata-autocomplete-dropdown-item">'+item.label+'</div>';// format of one dropdown item
            },
            formatSelection: function (item) {
               
                return item.label;// format selected item '<b>'+item.label+'</b>';
              },
              
        });
 
        // $('#booking_post_name').autocomplete({
        //     serviceUrl: '/mccadmin/cities/posts/get-posts-name',
        //     dataType: 'json',
        //     minChars: 1,
        //     width: 500,
        //     onSelect: function (suggestion) {
        //         $("#booking_post_id").val(suggestion.id)
        //         $("#booking_post_name").val(suggestion.value)
        //         $("#booking_city").val(suggestion.data.city)
        //     }
        // });

        // save booking
        $('#btn-save-booking').on('click', function(e) {
            $("#form-booking").validate()
            $('#form-booking').on("submit", function(e) {
                e.preventDefault()
            })

            if ($("#form-booking").valid()) {
                document.getElementById("form-booking").submit()
            }
        })

        // open create new city
        $("#btn-add-city").on("click", function() {
            $('#modal-new_city').modal('show')
            $("#city-name").focus()
        })

        // save new city
        $("#btn-save-new_city").on('click', function() {
            $("#form-new_city").validate()
            $('#form-new_city').on("submit", function(e) {
                e.preventDefault()
            })

            if ($("#form-new_city").valid()) {
                createCity()
            }
        })

        // hidden new city
        $("#modal-new_city").on("hidden.bs.modal", function() {
            $("#city-name").val("")
            $(".alert-danger").addClass("hidden")
            $(".alert-danger").text("")
        })

        // open create new service option
        $("#btn-find-service").on("click", function() {
            $('#modal-find_service').modal('show')
            //$("#service_option-name").focus()
        })

        // open create new service option
        $("#btn-add-service_option").on("click", function() {
            $('#modal-new_service_option').modal('show')
            $("#service_option-name").focus()
        })

        // save new service option
        $("#btn-save-new_service_option").on('click', function() {
            $("#form-new_service_option").validate()
            $('#form-new_service_option').on("submit", function(e) {
                e.preventDefault()
            })

            if ($("#form-new_service_option").valid()) {
              
                createServiceOption()
            }
        })

        // hidden new service option
        $("#modal-new_service_option").on("hidden.bs.modal", function() {
            $("#service_option-name").val("")
            $(".alert-danger").addClass("hidden")
            $(".alert-danger").text("")
        })

        $("#table-bookings tbody").on("click", ".delete-element", function() {
            id = $(this).parent().parent().data("element_id")

            Swal.fire({
                title: 'Are you sure?',
                text: "You won't be able to revert this!",
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Yes, delete it!',
                allowOutsideClick: false,
                allowEscapeKey: false,
                showLoaderOnConfirm: true,
                preConfirm: () => {
                    return fetch(`/mccadmin/bookings/${id}`, {
                        method: 'DELETE',
                        mode: 'cors',
                        cache: 'default',
                        headers: {
                            'Accept': 'application/json, text/plain, */*',
                            'Content-Type': 'application/x-www-form-urlencoded'
                        },
                    }).then(function(response) {
                        if (response.ok) {
                            table.row($(`tr[data-element_id='${id}']`)).remove().draw();

                            toastr.success("Booking deleted!", {timeOut: 6000})
                        }
                        else {
                            
                        }
                    })
                }
            }).then((result) => {})
        })

        $("#modal-old_booking").on("hidden.bs.modal", function(e) {
            $("#old_booking-email").val('')
            $("#old_booking-phone").val('')
            $("#old_booking-confirm_number").val('')
            $("#old_booking-service").val('')
            $("#old_booking-service_option").val('')
            $("#old_booking-city").val('')
            $("#old_booking-date").val('')
            $("#old_booking-number_people").val('')
            $("#old_booking-note_customer").val('')
            $("#old_booking-admin_note").val('data.admin_note')
            $("#old_booking-cc_used").text('')
        })

        $('#btn-change_booking_creator').on("click", function() {
            $('#modal-booking_creator').modal('show')
        })

        $("#btn-save-creator_booking").on("click", function() {
            let formData = new FormData();

            formData.append('booking_id', $("#booking_id").val());
            formData.append('user_id', $("#booking_creator").val());

            fetch(`/mccadmin/bookings/creator`, {
                method: 'PUT',
                mode: 'cors',
                cache: 'default',
                body: formData,
            }).then(function(response) {
                if (response.ok) {
                    response.json().then(function(data) {
                        $(".booking_user_creator").text(data.user)
                        $('#modal-booking_creator').modal('hide')
                    })
                }
                else {
                    response.json().then(function(data) {
                        $(".alert-danger").removeClass("hidden")
                        $(".alert-danger").text(data.error)
                    })
                }
            })
        })

        // FUNCTIONS

        function getBookings(start, end) {
            $("#table-bookings .dataTables_empty").html("<img src='/assets/loader.gif'>")

            fetch(`/mccadmin/bookings/get-bookings?start=${start}&end=${end}`, {
                method: 'GET',
                mode: 'cors',
                cache: 'default',
            }).then(function(response) {
                
                if (response.ok) {
                    response.json().then(function(data) {
                        table.rows().clear().draw();
                        for(val of data) {
                            let state;

                            if (val.state == 'pending')
                                state = 'warning'
                            else if (val.state == 'confirmed')
                                state = 'info'
                            else if (val.state == 'full_booked')
                                state = 'success'
                            else if (val.state == 'canceled')
                                state = 'danger'

                            let newRow = table.row.add( [
                                val.id,
                                `<a href='/mccadmin/bookings/${val.id}/edit' title='${val.member.first_name} ${val.member.last_name}'>${val.member.first_name} ${val.member.last_name}</a>`,
                                `<span title='${val.post.title}'>${val.post.title}</span>`,
                                val.city,
                                moment.utc(new Date(val.date)).format("DD MMM HH:mm"),
                                `<span class='label badge-${state} '>${val.state}</span>`,
                                `${val.user.first_name} ${val.user.last_name}`,
                                `<span title='${moment.utc(new Date(val.created_at)).format("YYYY-MM-DD HH:mm")}'>${moment.utc(new Date(val.created_at)).format("YYYY-MM-DD HH:mm")}`,
                                `<a href="/mccadmin/bookings/${val.id}/edit" class="edit-element" title="Edit"><i class="fa-solid fa-pen"></i></a>
                                <a href="javascript:" class="delete-element" title="Delete"><i class="fa-solid fa-trash-can"></i></a>`
                            ] ).draw( false ).node();
        
                            $(newRow).attr("data-element_id", val.id);
                            $(newRow).attr("class", `booking_${val.state}`);
                            $(newRow).attr("data-state", val.state);
                            $(newRow).attr("data-user", val.user.id);
                        }

                        filterBooking(table, filter_booking, $("#booking-filter").val())
                    })
                }
                else {
                    response.json().then(function(data) {
                     
                    })
                }
            })
        }

        function getCreators() {
            fetch(`/mccadmin/bookings/get-users`, {
                method: 'GET',
                mode: 'cors',
                cache: 'default',
            }).then(function(response) {
                
                if (response.ok) {
                    response.json().then(function(data) {

                        jQuery.each(data, function (i, val) {
                            $("#booking-filter").append(`<option value='${val.id}'>${val.first_name} ${val.last_name}</option>`)
                            $("#booking-filter option:first").trigger("update")
                        })
                        
                        $("#booking-filter").attr('disabled', false)

                        jQuery.each(data, function (i, val) {
                            $("#booking_history-filter").append(`<option value='${val.id}'>${val.first_name} ${val.last_name}</option>`)
                            $("#booking_history-filter option:first").trigger("update")
                        })
                        
                        $("#booking_history-filter").attr('disabled', false)
                    })
                }
            })
        }
       
        function getBookingsHistory(start, end) {
            if ($("#booking_member_id").val() == "") {
                return
            }

            $("#table-booking_history .dataTables_empty").html("<img src='/assets/loader.gif'>")
            
            fetch(`/mccadmin/bookings/get-bookings?start=${start}&end=${end}&member_id=${$("#booking_member_id").val()}`, {
                method: 'GET',
                mode: 'cors',
                cache: 'default',
            }).then(function(response) {
                
                if (response.ok) {
                    response.json().then(function(data) {
                     
                        table_booking_history.rows().clear().draw();
                        for(val of data) {
                            if (val.id != $("#booking_id").val()) {
                                let newRow = table_booking_history.row.add( [
                                    val.id,
                                    val.id,
                                    val.post.title,
                                    val.city,
                                    moment.utc(new Date(val.date)).format("YYYY-MM-DD HH:mm"),
                                    `${val.user.first_name} ${val.user.last_name}`,
                                    moment.utc(new Date(val.created_at)).format("YYYY-MM-DD HH:mm"),
                                    val.decorate_state,
                                    `<a href="/mccadmin/bookings/${val.id}/edit" target='new' class="edit-element" title="Edit"><i class="fa-solid fa-eye"></i></a>`
                                ] ).draw( false ).node();
                                
                                $(newRow).attr("data-element_id", val.id);
                                $(newRow).attr("class", `booking_${val.state}`);
                                $(newRow).attr("data-state", val.state);
                                $(newRow).attr("data-user", val.user.id);
                            }
                        }

                        //filterBooking(table_booking_history, filter_booking_history, $("#booking-filter").val())
                    })
                }
                else {
                    response.json().then(function(data) {
                     
                    })
                }
            })
        }

        function createCity() {
            let formData = new FormData();
            formData.append("city[name]", $("#city-name").val());
            formData.append("city[content]", '');
            //formData.append("city[post_id]", $("#booking_post_id").val());

            $("#form-new_city input, #form-new_city button").attr("disabled", true)
            $(".loading").removeClass("hidden")

            fetch(`/mccadmin/bookings/city`, {
                method: 'POST',
                mode: 'cors',
                cache: 'default',
                type: 'application/json',
                body: formData,
            }).then(function(response) {
                $("#form-new_city input, #form-new_city button").attr("disabled", false)
                $(".loading").addClass("hidden")
                
                if (response.ok) {
                    response.json().then(function(data) {
                        $("#booking-city").prepend(new Option(data.name, data.id, false, false)).trigger('change');
                        $("#booking-city option:eq(0)").prop('selected', true);

                        $('#modal-new_city').modal('hide')
                    })
                }
                else {
                    response.json().then(function(data) {
                        $(".alert-danger").removeClass("hidden")
                        $(".alert-danger").text(data)
                    })
                }
            })
        }

        function createServiceOption() {
            let formData = new FormData();
            formData.append('service_option[name]', $("#service_option-name").val());

            $("#form-new_service_option input, #form-new_service_option button").attr("disabled", true)
            $(".loading").removeClass("hidden")

            fetch(`/mccadmin/bookings/service-option`, {
                method: 'POST',
                mode: 'cors',
                cache: 'default',
                body: formData,
            }).then(function(response) {
                $("#form-new_service_option input, #form-new_service_option button").attr("disabled", false)
                $(".loading").addClass("hidden")
                
                if (response.ok) {
                    response.json().then(function(data) {
                        $("#booking-service_option").prepend(new Option(data.name, data.id, false, false)).trigger('change');
                        $("#booking-service_option option:eq(0)").prop('selected', true);

                        $('#modal-new_service_option').modal('hide')
                    })
                }
                else {
                    response.json().then(function(data) {
                        $(".alert-danger").removeClass("hidden")
                        $(".alert-danger").text(data)
                    })
                }
            })
        }

        $(`#export-selected-elements`).on("click", function(e) {
            window.open('/mccadmin/bookings/history/?ids=' + rows_selected.join(','), '_blank')
        })

        $("#booking_request").on('keyup',function(e) {
            if($(this).val().length > 0) {
                $(this).parent().addClass('request_added')
            }
            else {
                $(this).parent().removeClass('request_added')
            }
        })

        function refresh_selected_elements() {
            rows_selected = table_booking_history.column(0).checkboxes.selected();

            if (rows_selected.length == 0) {
                $(".total-selected-content").addClass("hidden")
                $(".total-selected-content").css("marginBottom", 0)
                $(".total-selected-content").css("opacity", 0)
            }
            else {
                $(".total-selected-content").removeClass("hidden")
                $(".total-selected-content").animate({
                    marginBottom: 10,
                    opacity: 1
                }, 100, "linear",  function() {
                });
            }
          
            let txt = rows_selected.length > 1 ? `${rows_selected.length} elements selected` : `${rows_selected.length} element selected`
            $(".total-selected").text(txt)
          
            return rows_selected
        }

        function ShowOldBooking(id) {

            $("#modal-old_booking").modal("show")

            JsLoadingOverlay.show({'spinnerIcon': 'ball-circus', 'containerID': 'modal-old_booking-content'});

            fetch(`/mccadmin/bookings/old-booking/${id}`, {
                method: 'GET',
                mode: 'cors',
                cache: 'default',
            }).then(function(response) {
                if (response.ok) {
                    response.json().then(function(data) {
                        
                        $("#old_booking-email").val(data.email)
                        $("#old_booking-phone").val(data.phone)
                        $("#old_booking-confirm_number").val(data.confirm_number)
                        $("#old_booking-service").val(data.service)
                        $("#old_booking-service_option").val(data.service_option)
                        $("#old_booking-city").val(data.city)
                        $("#old_booking-date").val(data.date)
                        $("#old_booking-number_people").val(data.number_people)
                        $("#old_booking-note_customer").val(data.note_customer)
                        $("#old_booking-admin_note").val(data.admin_note)
                        $("#old_booking-cc_used").text(data.credit_card_used ? 'Yes' : 'No')
                        
                        JsLoadingOverlay.hide()
                    })
                }
                else {
                    response.json().then(function(data) {
                        console.log(data)
                    })
                }
            })
        }

        if($("body").data("action") == "index") {
            filterBooking(table, filter_booking, $("#booking-filter").val())
        }

        getCreators()
    }
})

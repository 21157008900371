document.addEventListener("turbolinks:load", function() {
    if($("body").data("controller") == "contacts") {

        let table_contacts = $('#table-contacts').DataTable({
            "processing": true,
            "language": {
                processing: '<i class="fa fa-spinner fa-spin fa-3x fa-fw"></i><span class="sr-only">Loading...</span> '
            },
            scrollX:        true,
            paging:         true,
            "serverSide": true,
            "ajax": {
                "url": $('#table-contacts').data('source'),
                "data": function(e) {
                    //e.treated = $("#feedback_status_treated").prop("checked")
                }
            },
            "pagingType": "full_numbers",
            "columns": [
                {"data": "full_name"},
                {"data": "message",
                    "render": function(data, display, record) {
                        return `<b>${record.subject}</b><br>${record.message}`
                    }
                },
                {"data": "created_at", 
                    "render": function(data, display, record) {
                        return moment(record.created_at).format("DD-MM-YYYY LT")
                    }
                }
            ],
            createdRow: function (row, data, index) {
                $(row).attr("data-element_id", data.id)
            },
            "fnDrawCallback": function( row, data, index ) {

                //$(newRow).attr("id", `worksite-` + val.id);

                $(`.edit-element`).on("click", function (e) {
                    method = 'edit'
                    let id = $(this).data('element_id')
               
                });
            },
            initComplete: function( settings, json ) {
               
            },
            "bPaginate": true,
            "iDisplayLength": 50,
            "bFilter": true,
            "bInfo": false,
            "bLengthChange": false,
            "searching": false,
            "destroy": true,
            "stateSave": false,
            "scrollCollapse": true,
            "oLanguage": {
                "sLengthMenu": "_MENU_ ",
                "sInfo": "Showing <b>_START_ to _END_</b> of _TOTAL_ entries"
            },
            "bAutoWidth": false,
            'order' : [[0,'desc']],
            'columnDefs': [
                {
                    'targets': 0,
                    'width': 150
                },
                {
                    'targets': 1,
                    'width': 400
                },
                {
                    'targets': 2,
                    'width': 50
                }
            ],
        });

       
    }
})
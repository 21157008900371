document.addEventListener("turbolinks:load", function() {
    if($("body").data("controller") == "lounges" || $("body").data("controller") == "faqs") {

        document.addEventListener("turbolinks:before-cache", function() {
            if (table != null) {
                table.destroy();
            }

            $.fn.dataTable.ext.search.pop();
        })

        table = $('#table-lounges').DataTable({
            'initComplete': function(settings){
                var api = this.api();
       
                api.cells(
                   api.rows(function(idx, data, node){
                      return ($(node).data('related')) ? true : false;
                   }).indexes(),
                   0
                ).checkboxes.disable();
            },
            'columnDefs': [
                {
                   'targets': 0,
                   "orderable": false,
                   'checkboxes': {
                        'selectRow': false,
                        'selectCallback': function(nodes, selected) {
                            refresh_selected_elements()
                        }
                    }
                },
                {
                    "targets": 1,
                    "orderable": true,
                },
                {
                    "targets": 5,
                    "orderable": false,
                    "className": 'dt-body-right'
                }
            ],
            'select': {
                'style': 'multi',
                "selector": 'td:first-child'
            },
            "pageLength": 100,
            "bPaginate": false,
            "bFilter": true,
            "bInfo": false,
            "bLengthChange": false,
            "searching": true,
            "destroy": true,
            "stateSave": false,
            "scrollCollapse": true,
            "oLanguage": {
                "sLengthMenu": "_MENU_ ",
                "sInfo": "Showing <b>_START_ to _END_</b> of _TOTAL_ entries"
            },
            "bAutoWidth": false,
            'order' : [[0,'desc']]
        });

        FilePond.registerPlugin(FilePondPluginImagePreview, FilePondPluginFileValidateSize, FilePondPluginFileValidateType);
    
        const pond_image = FilePond.create(document.querySelector('#lounge-image'), {
            acceptedFileTypes: ['image/*'],
            labelIdle: "Drag & Drop your image or <span class='filepond--label-action'> Browse </span>",
            imagePreviewMinWidth: 800,
            allowProcess: false,
            instantUpload: false,
            allowReorder: true,
            name: 'lounge[image]',
            storeAsFile: true,
            credits: {label: "", url: ""} ,
            maxFileSize: 800000,
        });

        // *** EVENTS

        $('#btn-save-lounge').on("click", function(e) {
            $("#form-lounge").validate()
            $('#form-lounge').on("submit", function(e) {
                e.preventDefault()
            })

            if ($("#form-lounge").valid()) {
                document.getElementById("form-lounge").submit()
            }
        })

        $(".delete-element").on("click", function() {
            element_id = $(this).parent().parent().attr("data-element_id")
            destroy(element_id, null)
        })

        $(".delete-faq").on("click", function() {
            element_id = $(this).parent().parent().attr("data-element_id")

            destroy_faq(element_id)
        })

        $("#delete-selected-elements").on("click", function() {
            elements = rows_selected

            destroy(null, rows_selected.join(","))
        })

        // *** FUNCTIONS
        function destroy(element_id, elements_id) {

            Swal.fire({
                title: 'Are you sure?',
                text: "You won't be able to revert this!",
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Yes, delete it!',
                allowOutsideClick: false,
                allowEscapeKey: false,
                showLoaderOnConfirm: true,
                preConfirm: () => {
                    if (element_id != null) {
                        return fetch(`/mccadmin/lounges/${element_id}`, {
                            method: 'DELETE',
                            mode: 'cors',
                            cache: 'default',
                            headers: {
                                'Accept': 'application/json, text/plain, */*',
                                'Content-Type': 'application/x-www-form-urlencoded'
                            },
                        }).then(function(response) {
                            if (response.ok) {
                                table.row($(`tr[data-element_id='${element_id}']`)).remove().draw();
                            }
                            else {
                                response.json().then(function(data) {
                                    $(`.table tr[data-element_id='${element_id}'] td:nth-child(7) .element-deleting`).addClass("hidden");
                                    $(`.table tr[data-element_id='${element_id}'] td:nth-child(7) .element-actions`).removeClass("hidden");
                                    table.cells(`tr[data-element_id='${element_id}']`, 0).checkboxes.enable()
                                    table.columns().checkboxes.deselectAll()
                                    
                                    Swal.fire({
                                        icon: 'error',
                                        text: data.error,
                                    })
                                })
                            }
                        })
                    }else {
                        $(".total-selected-content").addClass("hidden")

                        let formData = new FormData();
                        formData.append('elements_id', elements_id);

                        fetch(`/mccadmin/lounges/delete_elements`, {
                            method: 'DELETE',
                            mode: 'cors',
                            cache: 'default',
                            body: formData,
                        }).then(function(response) {
                            
                            if (response.ok) {
                                response.json().then(function(data) {
        
                                    if (data.elements == 0) {
                                        Swal.fire({
                                            icon: 'error',
                                            text: data.error,
                                        })
                                    }
                                    else {
                                        elements = data.elements
                                        table.columns().checkboxes.deselectAll()

                                        $.each(elements, function(index, rowId){
                                            table.row($(`tr[data-element_id='${rowId}']`)).remove().draw();
                                        });

                                        $(".total-selected-content").addClass("hidden")
                                    }
                                })
                            }
                        }) 
                    }
                }
            })
        }

        function destroy_faq(element_id) {

            Swal.fire({
                title: 'Are you sure?',
                text: "You won't be able to revert this!",
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Yes, delete it!',
                allowOutsideClick: false,
                allowEscapeKey: false,
                showLoaderOnConfirm: true,
                preConfirm: () => {
                    return fetch(`/mccadmin/faqs/${element_id}`, {
                        method: 'DELETE',
                        mode: 'cors',
                        cache: 'default',
                        headers: {
                            'Accept': 'application/json, text/plain, */*',
                            'Content-Type': 'application/x-www-form-urlencoded'
                        },
                    }).then(function(response) {
                        if (response.ok) {
                            location.reload()
                        }
                    })
                }
            })
        }

        function refresh_selected_elements() {
            rows_selected = table.column(0).checkboxes.selected();

            if (rows_selected.length == 0) {
                $(".total-selected-content").addClass("hidden")
                $(".total-selected-content").css("marginBottom", 0)
                $(".total-selected-content").css("opacity", 0)
            }
            else {
                $(".total-selected-content").removeClass("hidden")
                $(".total-selected-content").animate({
                    marginBottom: 10,
                    opacity: 1
                }, 100, "linear",  function() {
                });
            }
          
            // $.each(rows_selected, function(index, rowId){
            // });
          
            let txt = rows_selected.length > 1 ? `${rows_selected.length} elements selected` : `${rows_selected.length} element selected`
            $(".total-selected").text(txt)
          
            return rows_selected
        }


      
    }
})
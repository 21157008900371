document.addEventListener("turbolinks:load", function() {
    if($("body").data("controller") == "service_options") {
        let selected_element_id = null

        let table_service_options = $('#table-service_options').DataTable({
            "processing": true,
            "language": {
                processing: '<i class="fa fa-spinner fa-spin fa-3x fa-fw"></i><span class="sr-only">Loading...</span> '
            },
            scrollX:        true,
            paging:         true,
            "serverSide": true,
            "ajax": {
                "url": $('#table-service_options').data('source'),
                "data": function(e){
                }
            },
            "pagingType": "full_numbers",
            "columns": [
                {"data": "name"},
                {"data": "hide"},
                {"data": "actions", 
                "render": function(data, display, record) {
                    let del = record.has_bookings == 'false' ? `<a href='javascript:' class='delete-element' data-element_id='${record.id}' title='Delete'><i class='fa-solid fa-trash-can'></i></a>` : ''
                    return `<a href="javascript:" class="edit-element" data-element_id='${record.id}' title="Edit"><i class="fa-solid fa-pen"></i></a>
                            ${del}`
                 }
                }
            ],
            createdRow: function (row, data, index) {
                $(row).attr("data-element_id", data.id)
            },
            "fnDrawCallback": function( row, data, index ) {

                //$(newRow).attr("id", `worksite-` + val.id);

                $(`.edit-element`).on("click", function (e) {
                    method = 'edit'
                    let id = $(this).data('element_id')
               
                    $('#modal-service_options').modal({
                        backdrop: 'static',
                        keyboard: false  
                    })

                    getElement(id)
                });

                $('#table-service_options').on("click", `.delete-element`, function(e) {

                    let id = $(this).data('element_id')

                        destroy(id)
              
                })
            },
            initComplete: function( settings, json ) {
               
            },
            "bPaginate": true,
            "iDisplayLength": 50,
            "bFilter": true,
            "bInfo": false,
            "bLengthChange": false,
            "searching": true,
            "destroy": true,
            "stateSave": false,
            "scrollCollapse": true,
            "oLanguage": {
                "sLengthMenu": "_MENU_ ",
                "sInfo": "Showing <b>_START_ to _END_</b> of _TOTAL_ entries"
            },
            "bAutoWidth": false,
            'order' : [[0,'desc']],
            'columnDefs': [
                {
                    'targets': 0,
                    'width': "50%"
                },
                {
                    'targets': 1,
                    'width': "40%"
                },
            ],
        });
        $("#table-service_options_wrapper > div > .col-sm-6:nth-child(1)").addClass("content-left pt-2")

        $('#btn-new_service_option').click(function () {
            $(".current-function").text("Add new")
            method = 'create'
            $('#modal-service_option').modal('show')
        })
        
        $("#modal-service_option").on("hidden.bs.modal", function() {

            $("input[name='service_option[name]'").val("")
            
            selected_element_id = null
            $(".alert-danger").addClass("hidden")
        })

        function getElement(element_id) {
            JsLoadingOverlay.show({
                "overlayBackgroundColor": "#666666",
                "overlayOpacity": 0.6,
                'spinnerIcon': 'ball-triangle-path',
                "spinnerColor": "#393636",
                "containerID": "modal-category-content"
            });

            fetch(`/mccadmin/service_options/${element_id}`, {
                method: 'GET',
                mode: 'cors',
                cache: 'default',
            }).then(function(response) {
                if (response.ok) {
                    response.json().then(function(data) {
                        method = 'update'
                        $('#modal-service_option').modal('show')

                        selected_element_id = element_id
                         
                        $("input[name='service_option[name]'").val(data.name)
                        $("input[name='service_option[hide]'").prop("checked", data.hide)

                        JsLoadingOverlay.hide();
                    })
                }
            })
        }

        $('#btn-save').on("click", function(e) {
            $("#form-service_option").validate()
            $('#form-service_option').on("submit", function(e) {
                e.preventDefault()
            })

            if ($("#form-service_option").valid()) {
                if (method == 'create')
                    create()
                else if (method == 'update')
                    update()
            }
        })

        function create() {

            let formData = new FormData();
            formData.append('name', $("input[name='service_option[name]']").val());
            formData.append('hide', $("#service_option_hide").prop("checked"));
           
            $("#form-service_option input").attr("disabled", true)
            $(".loading").removeClass("hidden")

            fetch(`/mccadmin/service_options`, {
                method: 'POST',
                mode: 'cors',
                cache: 'default',
                body: formData,
            }).then(function(response) {
                $("#form-service_option input").attr("disabled", false)
                $(".loading").addClass("hidden")
                
                if (response.ok) {
                    response.json().then(function(data) {
                        table_service_options.ajax.reload()

                        $('#modal-service_option').modal('hide')
                    })
                }
                else {
                    response.json().then(function(data) {
                        $(".alert-danger").removeClass("hidden")
                        $(".alert-danger").text(data.error)
                    })
                }
            })
        }

        function update() {
            let formData = new FormData();

            formData.append('name', $("input[name='service_option[name]']").val());
            formData.append('hide', $("#service_option_hide").prop("checked"));
            
            $("#form-service_option input").attr("disabled", true)
            $(".loading").removeClass("hidden")

            fetch(`/mccadmin/service_options/${selected_element_id}`, {
                method: 'PUT',
                mode: 'cors',
                cache: 'default',
                body: formData,
            }).then(function(response) {
                $("#form-service_option input").attr("disabled", false)
                $(".loading").addClass("hidden")
                
                if (response.ok) {
                    response.json().then(function(data) {
                        table_service_options.ajax.reload()

                        $('#modal-service_option').modal('hide')
                    })
                }
                else {
                    response.json().then(function(data) {
                        $(".alert-danger").removeClass("hidden")
                        $(".alert-danger").text(data.error)
                    })
                }
            })
        }

        function destroy(element_id) {

            Swal.fire({
                title: 'Are you sure?',
                text: "You won't be able to revert this!",
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Yes, delete it!',
                allowOutsideClick: false,
                allowEscapeKey: false,
                showLoaderOnConfirm: true,
                preConfirm: () => {

                    if (element_id != null) {
                        return fetch(`/mccadmin/service_options/${element_id}`, {
                            method: 'DELETE',
                            mode: 'cors',
                            cache: 'default',
                            headers: {
                                'Accept': 'application/json, text/plain, */*',
                                'Content-Type': 'application/x-www-form-urlencoded'
                            },
                        }).then(function(response) {
                            if (response.ok) {
                               
                                table_service_options.ajax.reload()

                                toastr.info('Service Option successfully deleted!')
                            }
                        })
                    }
                }
            }).then((result) => {
                if (result.isConfirmed) { 
                }
            })
        }
    }
})